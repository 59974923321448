/* eslint-disable no-console */
import useStats from 'hooks/api/stats/useStats';
import { drawMarker } from 'map/control/marker';
import { PositionNum } from 'map/type/position.type';
import useLanguageStore from 'store/languages';
import useMachineStore from 'store/machine';
import useModalStore from 'store/modal';
import useTenantStore from 'store/tenant';
import { PopupType } from 'types/common/popup.type';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import { logConsole } from 'util/common/console';
import { sortByLangOrder } from 'util/sort/lang-order/sortByLangOrder';

type Props = {
  closeModal: () => void;
};

const useMultiContent = ({ closeModal }: Props) => {
  const tenantStore = useTenantStore();
  const modalStore = useModalStore();
  const langStore = useLanguageStore();
  const machineStore = useMachineStore();

  const { postTenantUsage } = useStats();

  // 팝업 타입 분기
  const handleOpenModalType = (popupType: PopupType, openModal: (type: PopupType) => void) => {
    if (popupType === 'SMALL') {
      openModal('SMALL');
    } else {
      openModal('DEFAULT');
    }
  };

  // content 한 개 일 경우
  const handleSingleContent = (tenant: TenantWithPointId) => {
    logConsole('단일 content !');

    tenantStore.setCurrentTenant(tenant);
  };

  // 다중 contents 일 경우
  const handleMultiContent = (tenants: TenantWithPointId[]) => {
    logConsole('다중 content !');

    // 모든 테넌트 오름차순 정렬
    const sortedTenants: TenantWithPointId[] = tenants.sort((a, b) =>
      sortByLangOrder(a.name[langStore.currentLang], b.name[langStore.currentLang]),
    );

    tenantStore.setCurrentTenant(sortedTenants[0]);
    tenantStore.setCurrentTenantArr(sortedTenants);
    modalStore.setOpenTenantListModal(true);
  };

  // 다중 content, content 처리
  const handlePoiContent = async (
    tenants: TenantWithPointId[],
    openModal: (type: PopupType) => void,
    position: PositionNum,
  ) => {
    if (!machineStore.machine) {
      return;
    }

    // 매장 사용량 수집
    await postTenantUsage(machineStore.machine.point.id, tenants[0].id, 'DETAIL_OF_FLOOR', machineStore.machine.id);

    if (tenants.length > 1) {
      // content 여러개일 경우
      closeModal();
      handleMultiContent(tenants);
    } else {
      // content 한 개일 경우
      handleSingleContent(tenants[0]);
      handleOpenModalType(tenants[0].popupType as PopupType, openModal);
    }

    // 마커
    await drawMarker('default', tenants[0].content.floorId, position);
  };

  return { handlePoiContent };
};
export default useMultiContent;
