import React from 'react';
import { SvgProps } from '../type/SvgProps';

const DabeeoLogoSvg = ({ color }: SvgProps) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='55' height='14' viewBox='0 0 55 14' fill='none'>
      <g opacity='0.8'>
        <path
          d='M7.14614 5.11292C6.39592 4.528 5.45497 4.18468 4.43772 4.18468C1.98998 4.18468 0 6.17467 0 8.62241C0 11.0702 1.98998 13.0601 4.43772 13.0601C6.88547 13.0601 8.87545 11.0702 8.87545 8.62241H8.88181V1.04395H7.15249V5.11292H7.14614ZM4.43772 11.3245C2.94365 11.3245 1.72931 10.1101 1.72931 8.61605C1.72931 7.12197 2.94365 5.90764 4.43772 5.90764C5.9318 5.90764 7.14614 7.12197 7.14614 8.61605C7.14614 10.1101 5.9318 11.3245 4.43772 11.3245Z'
          fill={color}
        />
        <path
          d='M23.7522 4.18322C22.7349 4.18322 21.794 4.5329 21.0438 5.11145V1.04248H19.3145V8.62094H19.3208C19.3208 11.0687 21.3108 13.0587 23.7585 13.0587C26.2063 13.0587 28.1963 11.0687 28.1963 8.62094C28.1899 6.1732 26.1999 4.18322 23.7522 4.18322ZM23.7522 11.323C22.2581 11.323 21.0438 10.1087 21.0438 8.61459C21.0438 7.12051 22.2581 5.90618 23.7522 5.90618C25.2463 5.90618 26.4606 7.12051 26.4606 8.61459C26.4606 10.1087 25.2463 11.323 23.7522 11.323Z'
          fill={color}
        />
        <path
          d='M50.2009 4.17969C47.7532 4.17969 45.7632 6.16967 45.7632 8.61741C45.7632 11.0652 47.7532 13.0551 50.2009 13.0551C52.6486 13.0551 54.6386 11.0652 54.6386 8.61741C54.6386 6.16967 52.6486 4.17969 50.2009 4.17969ZM50.2009 11.3195C48.7068 11.3195 47.4925 10.1051 47.4925 8.61105C47.4925 7.11698 48.7068 5.90264 50.2009 5.90264C51.695 5.90264 52.9093 7.11698 52.9093 8.61105C52.9093 10.1051 51.695 11.3195 50.2009 11.3195Z'
          fill={color}
        />
        <path
          d='M14.095 4.17969C11.6472 4.17969 9.65723 6.16967 9.65723 8.61741C9.65723 11.0652 11.6472 13.0551 14.095 13.0551C15.1122 13.0551 16.0531 12.7118 16.8034 12.1333V12.9471H18.5327V8.61741C18.5327 6.16967 16.5427 4.17969 14.095 4.17969ZM14.095 11.3195C12.6009 11.3195 11.3865 10.1051 11.3865 8.61105C11.3865 7.11698 12.6009 5.90264 14.095 5.90264C15.589 5.90264 16.8034 7.11698 16.8034 8.61105C16.8034 10.1051 15.589 11.3195 14.095 11.3195Z'
          fill={color}
        />
        <path
          d='M40.5492 11.3217C39.0551 11.3217 37.8408 10.1074 37.8408 8.61328C37.8408 7.11921 39.0551 5.90487 40.5492 5.90487V4.18192C40.4602 4.18192 40.3648 4.18827 40.2758 4.18827C39.8562 4.18827 39.5192 3.85131 39.5192 3.4317C39.5192 3.01209 39.8562 2.67513 40.2758 2.67513C40.6954 2.67513 41.0324 3.01209 41.0324 3.4317H42.7617C42.7617 2.05842 41.6427 0.939453 40.2695 0.939453C38.8962 0.939453 37.7772 2.05842 37.7772 3.4317C37.7772 3.95304 37.9425 4.44259 38.2159 4.84313C36.9507 5.62513 36.0988 7.02384 36.0988 8.61964C36.0988 10.1137 34.8844 11.3281 33.3903 11.3281C31.8963 11.3281 30.6819 10.1137 30.6819 8.61964C30.6819 7.12557 31.8963 5.91123 33.3903 5.91123V4.18192C33.3013 4.18192 33.206 4.18827 33.117 4.18827C32.6973 4.18827 32.3604 3.85131 32.3604 3.4317C32.3604 3.01209 32.6973 2.67513 33.117 2.67513C33.5366 2.67513 33.8735 3.01209 33.8735 3.4317H35.6028C35.6028 2.05842 34.4839 0.939453 33.1106 0.939453C31.7373 0.939453 30.6438 2.05842 30.6438 3.4317C30.6438 3.95304 30.8091 4.44259 31.0825 4.84313C29.8173 5.62513 28.9653 7.02384 28.9653 8.61964C28.9653 11.0674 30.9553 13.0574 33.4031 13.0574C34.8653 13.0574 36.1687 12.3453 36.9761 11.2518C37.7836 12.3453 39.0869 13.0574 40.5492 13.0574C42.9969 13.0574 44.9869 11.0674 44.9869 8.61964H43.2576C43.2576 10.1074 42.0433 11.3217 40.5492 11.3217Z'
          fill={color}
        />
      </g>
    </svg>
  );
};
export default DabeeoLogoSvg;
