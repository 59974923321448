import { SvgProps } from '../../type/SvgProps';

const ArrowLeftSvg = ({ color }: SvgProps) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'>
      <path d='M23 11L14 20L23 29' stroke={color} strokeWidth='2' />
    </svg>
  );
};
export default ArrowLeftSvg;
