import { CategoryFeature } from 'types/category/category.type';
import { LangCode } from 'types/language/language.type';
import { Tenant } from 'types/tenant/tenant.type';

export const getTenantSearchCategoryName = (tenant: Tenant | null, lang: LangCode): string => {
  if (!tenant) {
    return '-';
  }

  const categoryName =
    tenant.categoryGroups.find(
      category => category.feature === CategoryFeature.mapFacility || category.feature === CategoryFeature.tenantSearch,
    )?.name[lang] ?? '-';

  return categoryName;
};
