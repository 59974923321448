import ScreensaverContainer from 'components/screensaver/ScreensaverContainer';
import styles from './Pages.module.scss';

const MainPage = () => {
  return (
    <div className={styles.pages}>
      <ScreensaverContainer />
    </div>
  );
};
export default MainPage;
