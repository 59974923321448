import { extractParams } from './extract/extractParams';

// TODO: useCustomNavigate 만들어서 path 만 넣으면 joinParam 된 채로 이동하도록 리팩터링
export const joinParams = (queryParams: string): string => {
  const pointId: string | undefined = extractParams(queryParams, 'pointId');
  const machineId: string | undefined = extractParams(queryParams, 'machineId');

  if (!machineId) {
    return `pointId=${pointId}`;
  }

  return `pointId=${pointId}&machineId=${machineId}`;
};
