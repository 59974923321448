import CustomBoardContainer from 'components/custom-board/CustomBoardContainer';
import styles from 'pages/Pages.module.scss';

const CustomBoardPage = () => {
  return (
    <div className={`${styles.customMenu} ${styles.open}`}>
      <CustomBoardContainer />
    </div>
  );
};

export default CustomBoardPage;
