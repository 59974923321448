// 자음만 반환
export const extractConsonants = (str: string) => {
  const consonants = new Set([
    'ㄱ',
    'ㄴ',
    'ㄷ',
    'ㄹ',
    'ㅁ',
    'ㅂ',
    'ㅅ',
    'ㅇ',
    'ㅈ',
    'ㅊ',
    'ㅋ',
    'ㅌ',
    'ㅍ',
    'ㅎ',
    'ㄲ',
    'ㄸ',
    'ㅃ',
    'ㅆ',
    'ㅉ',
  ]);

  const consonantArr = Array.from(str)
    .filter(char => consonants.has(char))
    .join('');

  return consonantArr;
};
