import { MainMenu } from 'types/common/menu.type';
import { create } from 'zustand';

type MenuStoreProps = {
  mainMenus: MainMenu[] | [];
  fixedMenus: MainMenu[] | [];
  setMenus: (menus: MainMenu[]) => void;
};

const useMenuStore = create<MenuStoreProps>((set, get) => ({
  mainMenus: [],
  fixedMenus: [],

  setMenus(menus: MainMenu[]) {
    const lowerTypeMenus: MainMenu[] = menus.map(menu => {
      return {
        ...menu,
        type: menu.type.toLowerCase(),
      };
    });

    // 모바일에 하단 고정으로 넣을 메뉴 (층별 안내, 매장 관리)
    const fixedMenusList: MainMenu[] = lowerTypeMenus.filter(menu => menu.type === 'floor' || menu.type === 'tenant');

    set({ mainMenus: lowerTypeMenus, fixedMenus: fixedMenusList });
  },
}));

export default useMenuStore;
