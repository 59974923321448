import { CategoryGroupWithTenants, TenantCategoryGroupWithPointId } from 'types/category/category.type';
import { LangCode } from 'types/language/language.type';
import { convertMultiLang } from 'util/multi-lang/convertMultiLang';

export const convertCategoryListToMap = (
  categoryGroups: TenantCategoryGroupWithPointId[] | undefined,
  mainLang: LangCode,
) => {
  const categoryGroupMap = new Map<string, CategoryGroupWithTenants>();

  categoryGroups?.forEach(tenant => {
    categoryGroupMap.set(tenant.pointId, {
      pointId: tenant.pointId,
      categoryGroups: tenant.categoryGroups.map(categoryGroup => {
        return {
          ...categoryGroup,
          name: convertMultiLang(categoryGroup.name, mainLang),
        };
      }),

      // 전체
      tenants: [],
    });
  });

  return categoryGroupMap;
};
