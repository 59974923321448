import { LangCode } from 'types/language/language.type';

export function getBrowserLanguage(): LangCode | null {
  const browserLanguage = navigator.language.toLowerCase();

  // 간체
  if (browserLanguage.search('cn') >= 0) {
    return LangCode.cn;
  }

  // 번체
  if (browserLanguage.search('tw') >= 0) {
    return LangCode.tw;
  }

  // 중국어 간체, 번체 제외한 중국어들은 중국어 간체로 치환
  if (browserLanguage.search('zh') >= 0) {
    return LangCode.cn;
  }

  const code = browserLanguage.substring(0, 2);

  // 한국어, 영어, 일본어
  if (code === LangCode.ko || code === LangCode.en || code === LangCode.ja) {
    return code as LangCode;
  }

  // 5가지 언어가 아닐 경우
  return null;
}
