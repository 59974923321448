/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-console */
import DisabledScreen from 'components/common/screen/disable/DisabledScreen';
import useStats from 'hooks/api/stats/useStats';
import { clearIndoorTracking } from 'map/control/common/clear';
import { enableMapRotate } from 'map/control/tracking';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useMapActionStore from 'store/action';
import useColorSetStore from 'store/colorSet';
import useLoadingStore from 'store/loading';
import useMachineStore from 'store/machine';
import useNaviStore from 'store/navi';
import useTenantStore from 'store/tenant';
import useTransportStore from 'store/transport';
import { PathType } from 'types/common/path.type';
import { errorConsole } from 'util/common/console';
import styles from '../MapActionButton.module.scss';

type Props = {
  buttonText: string;
  closePopup: () => void;
};

const MapNavigationButton = (props: Props) => {
  // store
  const transportStore = useTransportStore();
  const mapActionStore = useMapActionStore();
  const tenantStore = useTenantStore();
  const themeStore = useColorSetStore();
  const naviStore = useNaviStore();
  const machineStore = useMachineStore();
  const loadingStore = useLoadingStore();

  // hook
  const navigate = useNavigate();
  const { search: queryParams, pathname } = useLocation();
  const { postTenantUsage } = useStats();

  /**
   * 모의보행을 시작한다
   */
  const onClickStartNavigate = async () => {
    if (!tenantStore.currentTenant || !machineStore.machine) {
      return;
    }

    naviStore.setNaviType('ON_ACTIVE');

    /**
     * indoor tracking 서비스를 종료한다
     */
    await clearIndoorTracking();

    /**
     * 길찾기 시에는 지도 회전을 막는다
     */
    enableMapRotate(false);

    try {
      /**
       * 매장 사용량을 수집한다 (통계)
       */
      await postTenantUsage(
        machineStore.machine.point.id,
        tenantStore.currentTenant?.id || '',
        'NAVIGATION',
        machineStore.machine.id,
      );
    } catch (error) {
      errorConsole('매장 사용량 통계 에러입니다.', error);
    }

    try {
      loadingStore.setActionLoading(true);

      moveToFloorPage();

      mapActionStore.setMapAction('navigation', true);

      loadingStore.setActionLoading(false);
    } catch (error) {
      loadingStore.setActionLoading(false);
      errorConsole('모의보행을 실패했습니다.', error);
    }
  };

  /**
   * 층별안내로 이동한다
   */
  function moveToFloorPage() {
    if (pathname !== PathType.floor) {
      navigate(`${PathType.floor}${queryParams}`);
    }
  }

  useEffect(() => {
    return () => {
      naviStore.setNaviType('INIT');
    };
  }, []);

  return (
    <>
      <button
        style={{
          background: `${
            naviStore.naviType === 'INIT' ? themeStore.main : naviStore.naviType === 'ON_ACTIVE' ? '#F2F5FF' : '#272A36'
          }`,
        }}
        type='button'
        disabled={transportStore.isLoadingTransport || naviStore.naviType === 'ON_ACTIVE'}
        onClick={onClickStartNavigate}
        className={styles.wrapper}
      >
        {/* 시작 아이콘 */}
        {naviStore.naviType !== 'ON_ACTIVE' && (
          <div className={styles.icon}>
            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
              <path d='M9 18L9 6L17.6603 12L11.1051 16.5415' stroke='white' strokeWidth='1.2' />
            </svg>
          </div>
        )}

        {/* 길찾기 시작 / 모의보행 중 / 다시보기 */}
        <div
          className={styles.button_text}
          style={{
            color: `${naviStore.naviType === 'ON_ACTIVE' ? '#7B8CC8' : '#fff'}`,
          }}
        >
          {naviStore.naviType === 'INIT'
            ? props.buttonText
            : naviStore.naviType === 'ON_ACTIVE'
            ? '모의보행 중...'
            : '다시보기'}
        </div>
      </button>

      {transportStore.isLoadingTransport && <DisabledScreen />}
    </>
  );
};
export default MapNavigationButton;
