import { disassembleHangul } from '@toss/hangul';
import _ from 'lodash';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import { extractConsonants } from 'util/hangul/extractConsonants';
import { sortByLocaleCompare } from 'util/sort/locale-compare/sortByLocaleCompare';
import { create } from 'zustand';
import { LangCode } from '../types/language/language.type';

type TenantStoreType = {
  // 모든 point 들의 전체 tenants list
  // 다중 contents 에서 사용
  // 지도의 테넌트와 비교할 때 사용한다.
  rawTenants: TenantWithPointId[];
  setRawTenants: (rawTenants: TenantWithPointId[]) => void;

  // poiId 별 tenants map (다중 poi 에서 사용)
  poiTenantsMap: Map<string, TenantWithPointId[]> | null;
  setPoiTenantsMap: (poiTenantsMap: Map<string, TenantWithPointId[]>) => void;

  // ?? 매장 검색용 전체 tenants 목록
  // 매장검색 카테고리에 들어있는 tenant list
  searchTenants: TenantWithPointId[];
  setSearchTenants: (originTenants: TenantWithPointId[], lang: LangCode) => void;

  // 층별안내용 전체 tenants
  mapFacilityTenants: TenantWithPointId[];
  setMapFacilityTenants: (tenants: TenantWithPointId[]) => void;

  // 팝업에 보여줄 tenant
  currentTenant: TenantWithPointId | null;
  setCurrentTenant: (tenants: TenantWithPointId) => void;
  resetCurrentTenant: () => void;

  // 팝업에 보여줄 다중 contents list
  currentTenantArr: TenantWithPointId[] | [];
  setCurrentTenantArr: (tenants: TenantWithPointId[]) => void;
  resetCurrentTenantArr: () => void;

  // 검색 후 보여줄 tenant list
  filteredTenants: TenantWithPointId[];
  setFilteredTenants: (tenants: TenantWithPointId[], lang: LangCode, searchWord?: string) => void;
};

const useTenantStore = create<TenantStoreType>(set => ({
  // 층별안내에서 사용할 테넌트 목록
  rawTenants: [],
  setRawTenants(rawTenants: TenantWithPointId[]) {
    set(() => ({
      rawTenants,
    }));
  },

  searchTenants: [],
  setSearchTenants(tenants: TenantWithPointId[], lang: LangCode) {
    // const onlyUsedTenants = originTenants.filter(tenant => tenant.used);
    const sortedTenants = tenants.sort((a: TenantWithPointId, b: TenantWithPointId) =>
      sortByLocaleCompare(a.name[lang], b.name[lang]),
    );

    set(() => ({
      searchTenants: _.cloneDeep(sortedTenants),
    }));
  },

  mapFacilityTenants: [],
  setMapFacilityTenants(tenants: TenantWithPointId[]) {
    set(() => ({ mapFacilityTenants: _.cloneDeep(tenants) }));
  },

  currentTenant: null,
  setCurrentTenant(tenant: TenantWithPointId) {
    set(() => ({
      currentTenant: tenant,
    }));
  },
  resetCurrentTenant() {
    set(() => ({ currentTenant: null }));
  },

  currentTenantArr: [],
  setCurrentTenantArr(tenants: TenantWithPointId[]) {
    set(() => ({
      currentTenantArr: _.cloneDeep(tenants),
    }));
  },
  resetCurrentTenantArr() {
    set(() => ({ currentTenantArr: [] }));
  },

  filteredTenants: [],
  setFilteredTenants(tenants: TenantWithPointId[], lang: LangCode, searchWord?: string) {
    const sortedTenants = tenants.sort((a: TenantWithPointId, b: TenantWithPointId) =>
      sortByLocaleCompare(a.name[lang], b.name[lang]),
    );
    set(() => ({ filteredTenants: sortedTenants }));

    // 검색어가 없을 경우
    if (!searchWord) {
      const sortAscendingOrderTenants = tenants.sort((a: TenantWithPointId, b: TenantWithPointId) =>
        sortByLocaleCompare(a.name[lang].toUpperCase() ?? '', b.name[lang].toUpperCase() ?? ''),
      );

      return set(() => ({ filteredTenants: sortAscendingOrderTenants }));
    }

    // 검색어가 있을 경우
    const includeSearchWordTenants = tenants.filter(tenant =>
      tenant.searchKeyword.toUpperCase().includes(searchWord.toUpperCase()),
    );

    // 한국어
    if (lang === LangCode.ko) {
      includeSearchWordTenants.sort((a: TenantWithPointId, b: TenantWithPointId): number => {
        // 이름을 해체해서 자음만 모은 다음에 검색어로 시작하는지 확인
        // 혹은 입력값으로 시작하는지 확인
        const startsWithChosungA =
          extractConsonants(disassembleHangul(a.name[lang] ?? '')).startsWith(searchWord) ||
          a.name[lang].startsWith(searchWord);
        const startsWithChosungB =
          extractConsonants(disassembleHangul(b.name[lang] ?? '')).startsWith(searchWord) ||
          b.name[lang].startsWith(searchWord);

        // 검색어가 포함된 테넌트들을 오름차순 정렬
        if (startsWithChosungA && startsWithChosungB) {
          return sortByLocaleCompare(a.name[lang].toUpperCase(), b.name[lang].toUpperCase());
        }

        if (startsWithChosungA) return -1;
        if (startsWithChosungB) return 1;

        // 예외
        return sortByLocaleCompare(a.name[lang].toUpperCase(), b.name[lang].toUpperCase());
      });

      set(() => ({ filteredTenants: includeSearchWordTenants }));
      return;
    }

    includeSearchWordTenants.sort((a: TenantWithPointId, b: TenantWithPointId): number => {
      const startsWithSearchWordA = a.name[lang].toUpperCase().startsWith(searchWord.toUpperCase());
      const startsWithSearchWordB = b.name[lang].toUpperCase().startsWith(searchWord.toUpperCase());

      // 검색어가 포함된 테넌트들을 오름차순 정렬
      if (startsWithSearchWordA && startsWithSearchWordB) {
        return sortByLocaleCompare(a.name[lang].toUpperCase(), b.name[lang].toUpperCase());
      }

      if (startsWithSearchWordA) return -1;
      if (startsWithSearchWordB) return 1;

      return sortByLocaleCompare(a.name[lang].toUpperCase(), b.name[lang].toUpperCase());
    });

    set(() => ({ filteredTenants: includeSearchWordTenants }));
  },

  poiTenantsMap: null,
  setPoiTenantsMap(poiTenantsMap: Map<string, TenantWithPointId[]>) {
    set(() => ({
      poiTenantsMap,
    }));
  },
}));

export default useTenantStore;
