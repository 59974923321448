import LangSvg from 'components/common/icon/lang/LangSvg';
import { Fragment } from 'react';
import useColorSetStore from 'store/colorSet';
import useLanguageStore from 'store/languages';
import { LangBtnType, LangCode } from 'types/language/language.type';
import { changeLangCodeForMap } from 'util/multi-lang/convertLangCodeForMap';
import styles from './MultiLangBtn.module.scss';

const MultiLangBtn = () => {
  const { languages, currentLang, setCurrentLang } = useLanguageStore();
  const colorSetStore = useColorSetStore();

  const handleClickLang = (lang: LangCode) => {
    setCurrentLang(lang);
    changeLangCodeForMap(lang);
  };

  return (
    <div className={styles.multi_lang_wrapper}>
      {/* 아이콘 */}
      <div className={styles.globe_icon}>
        <LangSvg color={colorSetStore.tabButton.inactive} />
      </div>

      {/* 언어 */}
      <span className={styles.languages}>
        {languages.map((lang, index) => {
          const isActive = lang.code === currentLang;
          const color = isActive ? colorSetStore.tabButton.active : colorSetStore.tabButton.inactive;

          return (
            <Fragment key={lang.code}>
              {index !== 0 && <div className={styles.divider} />}
              <span
                onClick={handleClickLang.bind(this, lang.code)}
                className={`${styles.multi_lang} ${isActive && styles.active}`}
                style={{ color }}
              >
                {LangBtnType[lang.code]}
              </span>
            </Fragment>
          );
        })}
      </span>
    </div>
  );
};
export default MultiLangBtn;
