import ArrowLeftSvg from 'components/common/icon/arrow/left/ArrowLeftSvg';
import { useLocation, useNavigate } from 'react-router-dom';
import useColorSetStore from 'store/colorSet';
import { PathType } from 'types/common/path.type';
import { joinParams } from 'util/params/join/joinParams';
import styles from './CustomBoardHeader.module.scss';

type Props = {
  title: string;
};

const CustomBoardHeader = ({ title }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const colorSetStore = useColorSetStore();

  return (
    <div
      className={styles.header}
      style={{
        background: colorSetStore.header.bg,
        color: colorSetStore.header.text,
      }}
    >
      <div className={styles.arrow} onClick={() => navigate(`${PathType.more}?${joinParams(location.search)}`)}>
        <ArrowLeftSvg color={colorSetStore.header.text} />
      </div>
      {title}
    </div>
  );
};

export default CustomBoardHeader;
