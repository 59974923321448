import { TenantWithPointId } from 'types/tenant/tenant.type';
import MapDraw from '../MapDraw';
import { focusToPoi } from './camera';
import { clearMap } from './common/clear';
import { drawMarkers } from './marker';
import { findPoi } from './poi';

// 편의시설 마커
export const drawFacilityMarkers = async (tenants: TenantWithPointId[], currentFloorId: string) => {
  clearMap();

  // 현재층인 테넌트들만 필터링
  const currentFloorTenants: TenantWithPointId[] = tenants.filter(
    (tenant: TenantWithPointId) => tenant.content.floorId === currentFloorId,
  );

  // 현재 층의 마커가 없다면 return
  if (!currentFloorTenants || currentFloorTenants.length < 1) {
    return;
  }

  // TODO: marker type
  const currentFloorMarkers: any = tenants.map((tenant: TenantWithPointId) => {
    // object id가 있다면
    if (tenant.content.objectId) {
      const object = MapDraw.mapData.dataObject.find({ id: tenant.content.objectId });
      if (object) {
        return {
          x: Number(object.position.x),
          y: Number(object.position.y),
          iconOption: {
            positionZ: 50,
            iconUrl: '/assets/icon/arrive.png',
            width: 14,
            height: 20,
            anchor: {
              x: 0.5,
              y: 0.5,
            },
          },
          floorId: tenant.content.floorId,
          // poiId: tenant.content.poiId,
        };
      }
      return;
    }

    // poi Id 로 poi position find
    const poi = findPoi({
      id: tenant.content.poiId,
    });

    let position;

    if (poi) {
      if (!Array.isArray(poi)) {
        position = poi.position;
      } else {
        position = poi[0].position;
      }
    }

    return {
      x: Number(position?.x),
      y: Number(position?.y),
      iconOption: {
        positionZ: 50,
        iconUrl: '/assets/icon/arrive.png',
        width: 50,
        height: 70,
        anchor: {
          x: 0.5,
          y: 0.5,
        },
      },
      floorId: tenant.content.floorId,
      // poiId: tenant.content.poiId,
    };
  });

  focusToPoi(currentFloorMarkers);

  await drawMarkers(currentFloorMarkers);

  return currentFloorMarkers;
};
