import { useAxios } from 'api/axios';
import { EndPoint } from 'data/endpoint';
import { LOCAL_STORAGE_ORIGIN_LOCATION_KEY } from 'data/localStorageKey';
import MapDraw from 'map/MapDraw';
import { useLocation } from 'react-router-dom';
import useColorSetStore from 'store/colorSet';
import useFloorStore from 'store/floor';
import useMachineStore from 'store/machine';
import usePointStore from 'store/point';
import { ApiResponse } from 'types/api/response';
import { LangCode } from 'types/language/language.type';
import { Machine } from 'types/machine/machine.type';
import { StorageLocation } from 'types/origin/originLocation.type';
import { changeMapDrawValue } from 'util/map-draw/changeMapDrawValue';
import { convertMultiLang } from 'util/multi-lang/convertMultiLang';
import { extractParams } from 'util/params/join/extract/extractParams';

const useMachine = () => {
  const machineStore = useMachineStore();
  const floorStore = useFloorStore();
  const pointStore = usePointStore();
  const colorSetStore = useColorSetStore();

  const { api } = useAxios();
  const location = useLocation();

  const pointIdParam: string | undefined = extractParams(location.search, 'pointId');
  const machineIdParam: string | undefined = extractParams(location.search, 'machineId');

  // machine 조회
  const getMachine = async (pointId: string, machineId?: string) => {
    const response = await api.get<ApiResponse<Machine>>(EndPoint.machines, {
      params: {
        pointId,
        machineId,
      },
    });

    if (response.data) {
      return response.data;
    }
  };

  // machineId 로 pointId 조회
  const getPointIdByMachineId = async (machineId: string) => {
    const response = await api.get<ApiResponse<Machine>>(`${EndPoint.machines}/${machineId}`);

    if (response.data) {
      return response.data.point.id;
    }
  };

  // machine 데이터 다국어 변환
  const convertMachineData = (machineData: Machine, mainLang: LangCode): Machine => {
    const machine = { ...machineData };

    machine.floor.name = convertMultiLang(machine.floor.name, mainLang);
    machine.floor.description = convertMultiLang(machine.floor.description, mainLang);

    return machine;
  };

  // 기기 관련 정보 설정
  const setGlobalStateFromMachine = (machine: Machine) => {
    machineStore.setMachine(machine);
    colorSetStore.setColorSet(machine.theme.colorSet);
    pointStore.setPoint(machine.point);
    pointStore.setSelectedPoint(machine.point);
  };

  // 현재위치(길찾기 시작지) 설정
  const setOriginLocation = async (machine: Machine) => {
    const storageOriginLocation = localStorage.getItem(LOCAL_STORAGE_ORIGIN_LOCATION_KEY);

    /**
     * * 1. qr url에 machineId 있을 경우 무조건 machine 값 우선 적용한다.
     * * 출발지 : machine
     */
    if (machineIdParam) {
      localStorage.removeItem(LOCAL_STORAGE_ORIGIN_LOCATION_KEY);

      const kioskOrigin: StorageLocation = {
        id: machine.id,
        position: {
          x: Number(machine.coordinate.x),
          y: Number(machine.coordinate.y),
        },
        name: {
          ko: '키오스크',
          en: 'Kiosk',
          ja: '売店',
          cn: '亭子',
          tw: '亭子',
        },
        floorId: machine.floor.id,
        objectId: '',
        pointId: machine.point.id,
        buildingId: machine.point.buildingId,
      };
      localStorage.setItem(LOCAL_STORAGE_ORIGIN_LOCATION_KEY, JSON.stringify(kioskOrigin));

      // 층
      floorStore.setCurrentFloorId(machine.floor.id);

      // MapDraw 값 변경한다
      changeMapDrawValue(kioskOrigin);

      // 지도 설정은 machine 값 사용
      MapDraw.rotation = machine.mapPosition.rotation;
      MapDraw.zoom = Number(machine.mapPosition.zoom);

      return;
    }

    /**
     * * 2. qr url에 machineId 없고 현재위치 설정값이 있을 경우 저장된 현재위치 값 사용한다.
     * * 출발지 : localStorage 값
     * 만약 storage 에 저장된 출발지의 point 가 현재 point 가 아닐 경우 비워주기
     */
    if (storageOriginLocation) {
      const originLocation: StorageLocation = storageOriginLocation ? JSON.parse(storageOriginLocation || '') : '';

      // * 2-1 machineId 없고 다른 빌딩일 경우 localStorage 비우고 machine(모바일) 값으로 출발지 설정한다.
      if (pointIdParam !== originLocation.pointId) {
        localStorage.removeItem(LOCAL_STORAGE_ORIGIN_LOCATION_KEY);

        const origin: StorageLocation = {
          id: machine.id,
          position: {
            x: Number(machine.coordinate.x),
            y: Number(machine.coordinate.y),
          },
          name: {
            ko: '',
            en: '',
            ja: '',
            cn: '',
            tw: '',
          },
          floorId: machine.floor.id,
          objectId: '',
          pointId: machine.point.id,
          buildingId: machine.point.buildingId,
        };

        localStorage.setItem(LOCAL_STORAGE_ORIGIN_LOCATION_KEY, JSON.stringify(origin));

        // 출발지 설정
        floorStore.setCurrentFloorId(machine.floor.id);

        MapDraw.machineCoordinate = {
          x: Number(machine.coordinate.x),
          y: Number(machine.coordinate.y),
        };
        MapDraw.machineFloorId = machine.floor.id;
        MapDraw.pointId = machine.point.id;
        MapDraw.buildingId = machine.point.buildingId;

        // 지도 설정은 machine 값 사용
        MapDraw.rotation = machine.mapPosition.rotation;
        MapDraw.zoom = Number(machine.mapPosition.zoom);

        return;
      }

      // * 2-2 같은 빌딩일 경우 localStorage 값으로 지도 설정한다.
      localStorage.removeItem(LOCAL_STORAGE_ORIGIN_LOCATION_KEY);

      const origin: StorageLocation = {
        id: machine.id,
        position: {
          x: Number(machine.coordinate.x),
          y: Number(machine.coordinate.y),
        },
        name: {
          ko: '',
          en: '',
          ja: '',
          cn: '',
          tw: '',
        },
        floorId: machine.floor.id,
        objectId: '',
        pointId: machine.point.id,
        buildingId: machine.point.buildingId,
      };

      localStorage.setItem(LOCAL_STORAGE_ORIGIN_LOCATION_KEY, JSON.stringify(origin));

      // 출발지 설정
      floorStore.setCurrentFloorId(originLocation.floorId);
      MapDraw.machineCoordinate = originLocation.position;
      MapDraw.machineFloorId = originLocation.floorId;
      MapDraw.pointId = originLocation.pointId;
      MapDraw.buildingId = originLocation.buildingId;

      // 지도 설정은 machine 값 사용
      MapDraw.rotation = machine.mapPosition.rotation;
      MapDraw.zoom = Number(machine.mapPosition.zoom);

      return;
    }

    /**
     * * 3. qr url에 machineId 없고 현재위치 설정값도 없을 경우 mobile 설정값(machine) 을 사용한다.
     * (서버에서 machine / mobile 값으로 내려줌)
     * * 출발지 : mobile 설정값 (machine 값)
     */
    const mobileOrigin: StorageLocation = {
      id: machine.id,
      position: {
        x: Number(machine.coordinate.x),
        y: Number(machine.coordinate.y),
      },
      name: {
        ko: '',
        en: '',
        ja: '',
        cn: '',
        tw: '',
      },
      floorId: machine.floor.id,
      objectId: '',
      pointId: machine.point.id,
      buildingId: machine.point.buildingId,
    };

    localStorage.removeItem(LOCAL_STORAGE_ORIGIN_LOCATION_KEY);
    localStorage.setItem(LOCAL_STORAGE_ORIGIN_LOCATION_KEY, JSON.stringify(mobileOrigin));

    // 출발지 설정
    floorStore.setCurrentFloorId(machine.floor.id);

    MapDraw.machineCoordinate = {
      x: Number(machine.coordinate.x),
      y: Number(machine.coordinate.y),
    };
    MapDraw.machineFloorId = machine.floor.id;
    MapDraw.pointId = machine.point.id;
    MapDraw.buildingId = machine.point.buildingId;

    // 지도 설정은 machine 값 사용
    MapDraw.rotation = machine.mapPosition.rotation;
    MapDraw.zoom = Number(machine.mapPosition.zoom);
  };

  const handleMachineData = async (machineData: Machine, mainLang: LangCode) => {
    const machine: Machine = convertMachineData(machineData, mainLang);

    setGlobalStateFromMachine(machine);

    await setOriginLocation(machine);
  };

  return { getMachine, handleMachineData, getPointIdByMachineId };
};
export default useMachine;
