import InfoGroup from 'components/common/info/group/basic/InfoGroup';
import useLanguageStore from 'store/languages';
import useTenantStore from 'store/tenant';
import styles from '../SimpleTenantPopup.module.scss';

const SimpleTenantPopupBody = () => {
  const tenantStore = useTenantStore();
  const langStore = useLanguageStore();

  return (
    <div className={styles.popup_contents}>
      <div className={styles.popup_body}>
        <div className={styles.title}>{tenantStore.currentTenant?.name[langStore.currentLang] ?? '-'}</div>

        <InfoGroup />
      </div>
    </div>
  );
};
export default SimpleTenantPopupBody;
