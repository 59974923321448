import MapDraw from 'map/MapDraw';
import { CameraControlOption, ControlRangeOption, TouchOption } from 'map/type/option.type';
import { PositionNum, PositionStr } from 'map/type/position.type';
import { Marker } from 'types/map/marker.type';
import { validMap } from './common/valid';

// 카메라 값 설정
export const changeCamera = (controlOption: CameraControlOption) => {
  validMap();

  MapDraw.map.control.set(controlOption);
};

// 카메라 포커스
export const moveCamera = (position: PositionStr | PositionNum) => {
  validMap();

  MapDraw.map.control.moveTo({ position: { x: Number(position?.x), y: Number(position?.y) }, transition: true });
};

// 카메라 리셋
export const resetCamera = () => {
  validMap();

  MapDraw.map.control.reset({ transition: true });
};

// 카메라 zoom 변경
export const changeCameraZoom = (zoom: number) => {
  validMap();

  MapDraw.map.control.changeZoom({ zoom, transition: true });
};

// 카메라 min, max zoom 설정
export const changeCameraMinMaxZoom = (min: number, max: number) => {
  MapDraw.map.control.setOption({
    controlRangeOption: {
      zoom: {
        min,
        max,
      },
    },
  });
};

// 카메라 컨트롤 범위 옵션
export const controlCameraRange = (controlRangeOption: ControlRangeOption, touchOption: TouchOption) => {
  validMap();

  MapDraw.map.control.setOption({
    // 컨트롤 범위 옵션
    controlRangeOption,

    // 터치 컨트롤 옵션
    touchOption,
  });
};

// poi 로 카메라 포커스
// 현재층의 노출 poi 만 넣어야 함
export const focusToPoi = (markers: Marker[]) => {
  validMap();

  MapDraw.map.control.focusTo({
    focus: {
      type: markers.length <= 1 ? 'POI' : 'POI_ALL',
      ids: markers.map(marker => marker.poiId),
    },
    transition: true,
    padding: {
      bottom: 10,
      left: 10,
      right: 10,
      top: 10,
    },
  });
};

// OBJECT_ALL - 전체 오브젝트로 focus합니다.
export const focusToAllObjects = () => {
  validMap();

  MapDraw.map.control.focusTo({
    focus: {
      type: 'OBJECT_ALL',
    },
    transition: true,
    padding: {
      bottom: 10,
      left: 10,
      right: 10,
      top: 10,
    },
  });
};
