import AllViewContainer from 'components/menu-list/AllViewContainer';
import styles from './Pages.module.scss';

const AllViewPage = () => {
  return (
    <div className={styles.pages}>
      <AllViewContainer />
    </div>
  );
};
export default AllViewPage;
