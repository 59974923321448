import dayjs from 'dayjs';

class DateTime {
  convertDate(date: string) {
    try {
      const currentYear = dayjs().get('year').toString();
      if (date.includes('.')) {
        const parsingDate = date.replace('.', '-');
        const dateWithYear = currentYear.concat('-', parsingDate);

        const currentDate = dayjs(dateWithYear).format('MM/DD(ddd)');
        return currentDate;
      }
      return '';
    } catch (e) {
      return 'invalid date';
    }
  }
}

export default DateTime;
