import { Floor } from 'types/floor/floor.type';
import { create } from 'zustand';

type FloorStore = {
  // 모든 point 의 floors
  pointFloorsMap: Map<string, Floor[]> | null;
  setPointFloorsMap: (floorsMap: Map<string, Floor[]>) => void;

  // buildingId 별 floor Map
  buildingFloorsMap: Map<string, Floor[]> | null;
  setBuildingFloorsMap: (floorsMap: Map<string, Floor[]>) => void;

  // 현재 point 의 floors
  floors: Floor[];
  setFloors: (floors: Floor[]) => void;

  currentFloorId: string;
  setCurrentFloorId: (floors: string) => void;

  // 층 이름
  floorName: string;
  setFloorName: (floorName: string) => void;
};

const useFloorStore = create<FloorStore>((set, get) => ({
  pointFloorsMap: null,
  setPointFloorsMap(floorsMap: Map<string, Floor[]>) {
    set(() => ({
      pointFloorsMap: floorsMap,
    }));
  },

  buildingFloorsMap: null,
  setBuildingFloorsMap(buildingFloorsMap: Map<string, Floor[]>) {
    set(() => ({
      buildingFloorsMap,
    }));
  },

  floors: [],
  setFloors(floors: Floor[]) {
    set(() => ({
      floors,
    }));
  },

  currentFloorId: '',
  setCurrentFloorId(floor: string) {
    set(() => ({
      currentFloorId: floor,
    }));
  },

  floorName: '',
  setFloorName(floorName: string) {
    set(() => ({
      floorName,
    }));
  },
}));

export default useFloorStore;
