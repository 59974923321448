import { useTranslation } from 'react-i18next';
import styles from './DisplayBadge.module.scss';

type Props = {
  displayStatus: string;
};

const DisplayBadge = ({ displayStatus }: Props) => {
  const { t } = useTranslation();

  return <div className={`${styles.container} ${styles[displayStatus]}`}>{t(`badge.${displayStatus}`)}</div>;
};

export default DisplayBadge;
