import { CategoryFeature, CategoryGroup, CategoryGroupWithTenants } from 'types/category/category.type';
import { create } from 'zustand';

type CategoryStoreType = {
  // 모든 points 의 categories
  tenantSearchCategoriesMap: Map<string, CategoryGroupWithTenants> | null;
  mapFacilityCategoriesMap: Map<string, CategoryGroupWithTenants> | null;

  setCategoriesMap: (featureCode: CategoryFeature, categorizedTenants: Map<string, CategoryGroupWithTenants>) => void;

  // 내 point 의 category
  tenantSearchCategories: CategoryGroupWithTenants | null;
  mapFacilityCategories: CategoryGroupWithTenants | null;
  setCategoryList: (featureCode: CategoryFeature, categorizedTenants: CategoryGroupWithTenants) => void;

  // filter
  categoryFilter: CategoryGroup[];
  setCategoryFilter: (categoryFilter: CategoryGroup[]) => void;
};

const useCategoryGroupStore = create<CategoryStoreType>((set, get) => ({
  tenantSearchCategoriesMap: null,
  mapFacilityCategoriesMap: null,

  setCategoriesMap(featureCode: CategoryFeature, categoriesMap: Map<string, CategoryGroupWithTenants>) {
    if (featureCode === CategoryFeature.tenantSearch) {
      return set(() => ({ tenantSearchCategoriesMap: categoriesMap }));
    }

    if (featureCode === CategoryFeature.mapFacility) {
      return set(() => ({ mapFacilityCategoriesMap: categoriesMap }));
    }
  },

  tenantSearchCategories: null,
  mapFacilityCategories: null,

  setCategoryList(featureCode: CategoryFeature, categories: CategoryGroupWithTenants) {
    if (featureCode === CategoryFeature.tenantSearch) {
      return set(() => ({ tenantSearchCategories: categories }));
    }

    if (featureCode === CategoryFeature.mapFacility) {
      return set(() => ({ mapFacilityCategories: categories }));
    }
  },

  categoryFilter: [],
  setCategoryFilter(categoryFilter: CategoryGroup[]) {
    set(() => ({
      categoryFilter,
    }));
  },
}));

export default useCategoryGroupStore;
