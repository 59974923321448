import { LOCAL_STORAGE_PARKING_LOCATION_KEY } from 'data/localStorageKey';
import useMapRoute from 'hooks/map/event/useMapRoute';
import { moveCamera } from 'map/control/camera';
import { drawMarker } from 'map/control/marker';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useLoadingStore from 'store/loading';
import useModalStore from 'store/modal';
import useTenantStore from 'store/tenant';
import { PathQueryParam, TypeQueryParam } from 'types/common/queryParam.type';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import { extractParams } from 'util/params/join/extract/extractParams';
import { findTenant } from 'util/tenant/findTenant';

// URL type 별 action
const useUrlType = () => {
  // hook
  const location = useLocation();
  const { showTenantLocation, changeOriginLocation, changeMapFloor } = useMapRoute();

  // store
  const tenantStore = useTenantStore();
  const modalStore = useModalStore();
  const loadingStore = useLoadingStore();

  // variables
  const typeParam: TypeQueryParam | undefined = extractParams(location.search, 'type');
  // 도착지
  const destTenantIdParam: string | undefined = extractParams(location.search, 'destTenantId');
  // 출발지
  const originTenantIdParam: string | undefined = extractParams(location.search, 'originTenantId');

  // TODO: 기존 URL 정리 후 제거
  const pathParam: PathQueryParam | undefined = extractParams(location.search, 'path');
  const tenantIdParam: string | undefined = extractParams(location.search, 'tenantId');

  // 주차위치
  const storageParkingLocation = localStorage.getItem(LOCAL_STORAGE_PARKING_LOCATION_KEY);
  const myParkingLocation: TenantWithPointId = storageParkingLocation ? JSON.parse(storageParkingLocation || '') : '';

  /**
   * URL type 에 따라 다른 action 실행
   */
  const triggerActionByType = async () => {
    /**
     * URL type 이 location 인 경우 해당 tenant 위치를 확인한다 (마커 + 카메라 이동)
     * 현재 위치는 변경되지 않는다
     */
    if (typeParam === 'location') {
      if (!destTenantIdParam) {
        return;
      }

      const result = await showTenantLocation(destTenantIdParam, tenantStore.rawTenants);
      await drawMarker('default', result?.floorId || '', result?.position || { x: 0, y: 0 });
      moveCamera(result?.position || { x: 0, y: 0 });

      return;
    }

    /**
     * URL type 이 parkingDetail 일 경우 내 주차위치 확인 팝업을 노출한다
     */
    if (typeParam === 'parkingDetail') {
      if (!destTenantIdParam) {
        return;
      }

      const parkingLocation = findTenant(tenantStore.rawTenants, destTenantIdParam);
      if (parkingLocation) {
        modalStore.setOpenMyParkingModal(true);

        localStorage.setItem(LOCAL_STORAGE_PARKING_LOCATION_KEY, JSON.stringify(parkingLocation));
        const result = await showTenantLocation(parkingLocation.id, tenantStore.rawTenants);
        await drawMarker('parking', result?.floorId || '', result?.position || { x: 0, y: 0 });
        moveCamera(result?.position || { x: 0, y: 0 });
        return;
      }
    }

    /**
     * URL type 이 parkingPositioning 일 경우
     * 기존 등록되어 있던 localStorage 비워주고,
     * 내 주차위치 지정해주는 팝업을 보여준다.
     *
     * 기존에 주차위치가 등록되어 있었다면 해당 위치에 주차 마커만 생성한다.
     */
    if (typeParam === 'parkingPositioning') {
      if (!originTenantIdParam) {
        return;
      }

      /**
       * 주차 위치가 있든 없든 무조건 URL의 tenant를 현재위치로 변경한다
       */
      await changeOriginLocation(originTenantIdParam);

      /**
       * 기존에 주차위치가 등록되어 있었는지 확인한다
       */
      if (!myParkingLocation || (myParkingLocation && originTenantIdParam !== myParkingLocation.id)) {
        localStorage.removeItem(LOCAL_STORAGE_PARKING_LOCATION_KEY);

        modalStore.setOpenParkingModal(true);
      }

      const result = await showTenantLocation(originTenantIdParam, tenantStore.rawTenants);
      await drawMarker('parking', result?.floorId || '', result?.position || { x: 0, y: 0 });
      moveCamera(result?.position || { x: 0, y: 0 });
      return;
    }

    /**
     * QR type 이 parkingNavi 인 경우 내 주차위치까지 길찾기 팝업을 연다
     */
    if (typeParam === 'parkingNavi') {
      if (!originTenantIdParam) {
        return;
      }

      /**
       * 저장된 주차 위치가 있을 경우 현재위치를 출발지로 지정하고, 저장된 주차위치를 도착지로 지정한다 (팝업에서)
       */
      if (myParkingLocation) {
        modalStore.setOpenParkingNaviModal(true);
      } else {
        /**
         * 저장된 주차 위치가 없을 경우 URL의 tenant를 현재위치로 변경한다
         */
        const originTenant: TenantWithPointId | undefined = findTenant(tenantStore.rawTenants, originTenantIdParam);

        if (originTenant) {
          // 지도 빌딩, 층 변경
          await changeMapFloor(originTenant);
          // 해당 tenant로 현재위치 설정
          await changeOriginLocation(originTenantIdParam);
        }
      }
    }
  };

  // URL 에 tenantId 있을 경우
  useEffect(() => {
    if (!loadingStore.isLoadingMap) {
      triggerActionByType();
    }
  }, [loadingStore.isLoadingMap, typeParam, destTenantIdParam, originTenantIdParam, tenantStore.rawTenants]);

  // TODO: 기존 URL 정리 후 제거
  const handleQrPath = async () => {
    if (!tenantIdParam) {
      return;
    }

    /**
     * QR type 이 tenant 인 경우 해당 tenant 위치를 확인한다 (마커 + 카메라 이동)
     * 현재 위치는 변경되지 않는다
     */
    if (pathParam === 'tenant') {
      const result = await showTenantLocation(tenantIdParam, tenantStore.rawTenants);
      await drawMarker('default', result?.floorId || '', result?.position || { x: 0, y: 0 });
      moveCamera(result?.position || { x: 0, y: 0 });

      return;
    }

    /**
     * QR type 이 myParking 일 경우 내 주차위치 확인 팝업을 노출한다
     */
    if (pathParam === 'myParking') {
      const parkingLocation = findTenant(tenantStore.rawTenants, tenantIdParam);
      if (parkingLocation) {
        modalStore.setOpenMyParkingModal(true);

        localStorage.setItem(LOCAL_STORAGE_PARKING_LOCATION_KEY, JSON.stringify(parkingLocation));
        const result = await showTenantLocation(parkingLocation.id, tenantStore.rawTenants);
        await drawMarker('parking', result?.floorId || '', result?.position || { x: 0, y: 0 });
        moveCamera(result?.position || { x: 0, y: 0 });
        return;
      }
    }

    /**
     * QR type 이 parking 일 경우
     * 기존 등록되어 있던 localStorage 비워주고,
     * 내 주차위치 지정해주는 팝업을 보여준다.
     *
     * 기존에 주차위치가 등록되어 있었다면 해당 위치에 주차 마커만 생성한다.
     */
    if (pathParam === 'parking') {
      /**
       * 주차 위치가 있든 없든 무조건 URL의 tenant를 현재위치로 변경한다
       */
      await changeOriginLocation(tenantIdParam);

      /**
       * 기존에 주차위치가 등록되어 있었는지 확인한다
       */
      if (!myParkingLocation || (myParkingLocation && tenantIdParam !== myParkingLocation.id)) {
        localStorage.removeItem(LOCAL_STORAGE_PARKING_LOCATION_KEY);

        modalStore.setOpenParkingModal(true);
      }

      const result = await showTenantLocation(tenantIdParam, tenantStore.rawTenants);
      await drawMarker('parking', result?.floorId || '', result?.position || { x: 0, y: 0 });
      moveCamera(result?.position || { x: 0, y: 0 });
      // return;
    }

    /**
     * QR type 이 parkingNavi 인 경우 내 주차위치까지 길찾기 팝업을 연다
     */
    if (pathParam === 'parkingNavi') {
      /**
       * 저장된 주차 위치가 있을 경우 현재위치를 출발지로 지정하고, 저장된 주차위치를 도착지로 지정한다 (팝업에서)
       */
      if (myParkingLocation) {
        modalStore.setOpenParkingNaviModal(true);
      } else {
        /**
         * 저장된 주차 위치가 없을 경우 URL의 tenant를 현재위치로 변경한다
         */
        const originTenant: TenantWithPointId | undefined = findTenant(tenantStore.rawTenants, tenantIdParam);

        if (originTenant) {
          // 지도 빌딩, 층 변경
          await changeMapFloor(originTenant);
          // 해당 tenant로 현재위치 설정
          await changeOriginLocation(tenantIdParam);
        }
      }
    }
  };

  // TODO: 기존 URL 정리 후 제거
  // param 에 tenantId 있을 경우
  useEffect(() => {
    if (!loadingStore.isLoadingMap) {
      handleQrPath();
    }
  }, [loadingStore.isLoadingMap, pathParam, tenantIdParam, tenantStore.rawTenants]);

  return { triggerActionByType, handleQrPath };
};
export default useUrlType;
