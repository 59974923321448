import MapDraw from 'map/MapDraw';
import { LangCode } from 'types/language/language.type';

// 지도 언어코드로 변경
export const changeLangCodeForMap = (lang: string) => {
  let langCodeForMap = lang;

  // TODO: 중국어 번체는 추가 예정
  if (lang === LangCode.cn || lang === LangCode.tw) {
    langCodeForMap = 'zh-CN';
  }
  MapDraw.map?.context.changeLanguage(langCodeForMap);
};
