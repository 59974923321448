import useFloor from 'hooks/api/floor/useFloor';
import useColorSetStore from 'store/colorSet';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import styles from './FloorName.module.scss';

type Props = {
  tenant: TenantWithPointId;
};

const FloorName = ({ tenant }: Props) => {
  const colorSetStore = useColorSetStore();

  const { findFloorName } = useFloor();

  return (
    <div className={styles.floor} style={{ color: colorSetStore.text.title }}>
      {findFloorName(tenant?.content.floorId ?? '', tenant?.pointId ?? '') || '-'}
    </div>
  );
};
export default FloorName;
