import { BASIC_COLOR_SET } from 'data/color';
import { ColorSet } from 'types/machine/theme.type';
import { create } from 'zustand';

type Props = {
  setColorSet: (colorSet: ColorSet) => void;

  // 공통
  main: string;
  sub: string;
  navi: string;

  // 공통 텍스트
  text: {
    title: string;
    category: string;
    item: string;
  };

  // 헤더, 검색 필터 등
  header: {
    bg: string;
    text: string;
  };

  gnb: {
    bg: {
      active: string;
      inactive: string;
    };
    text: {
      active: string;
    };
  };

  footer: {
    bg: string;
    text: string;
  };

  button: {
    border: string;
    text: string;
  };

  tabButton: {
    active: string;
    inactive: string;
  };

  floorList: {
    bg: string;
    text: {
      active: string;
    };
  };

  // 목록형 메뉴 내부
  list: {
    bg: string;
    item: {
      border: string;
    };
  };

  input: {
    bg: string;
    active: string;
    inactive: string;
  };

  here: {
    bg: string;
  };
};

const useColorSetStore = create<Props>((set, get) => ({
  setColorSet: (colorSet: ColorSet) => {
    set({
      main: colorSet.colors.find(color => color.type === 'MAIN')?.color ?? BASIC_COLOR_SET.MAIN,
      sub: colorSet.colors.find(color => color.type === 'SUB')?.color ?? BASIC_COLOR_SET.SUB,
      navi: colorSet.colors.find(color => color.type === 'NAVI')?.color ?? BASIC_COLOR_SET.NAVI,

      text: {
        title: colorSet.colors.find(color => color.type === 'TEXT_TITLE')?.color ?? BASIC_COLOR_SET.TEXT_TITLE,
        category: colorSet.colors.find(color => color.type === 'TEXT_CATEGORY')?.color ?? BASIC_COLOR_SET.TEXT_CATEGORY,
        item: colorSet.colors.find(color => color.type === 'TEXT_ITEM')?.color ?? BASIC_COLOR_SET.TEXT_ITEM,
      },

      header: {
        bg: colorSet.colors.find(color => color.type === 'HEADER_BG')?.color ?? BASIC_COLOR_SET.HEADER_BG,
        text: colorSet.colors.find(color => color.type === 'HEADER_TEXT')?.color ?? BASIC_COLOR_SET.HEADER_TEXT,
      },

      gnb: {
        bg: {
          active: colorSet.colors.find(color => color.type === 'GNB_BG_ACTIVE')?.color ?? BASIC_COLOR_SET.GNB_BG_ACTIVE,
          inactive: colorSet.colors.find(color => color.type === 'GNB_BG')?.color ?? BASIC_COLOR_SET.GNB_BG,
        },
        text: {
          active:
            colorSet.colors.find(color => color.type === 'GNB_TEXT_ACTIVE')?.color ?? BASIC_COLOR_SET.GNB_TEXT_ACTIVE,
        },
      },

      footer: {
        bg: colorSet.colors.find(color => color.type === 'FOOTER_BG')?.color ?? BASIC_COLOR_SET.FOOTER_BG,
        text: colorSet.colors.find(color => color.type === 'FOOTER_TEXT')?.color ?? BASIC_COLOR_SET.FOOTER_TEXT,
      },

      button: {
        border: colorSet.colors.find(color => color.type === 'BUTTON_BORDER')?.color ?? BASIC_COLOR_SET.BUTTON_BORDER,
        text: colorSet.colors.find(color => color.type === 'BUTTON_TEXT')?.color ?? BASIC_COLOR_SET.BUTTON_TEXT,
      },

      tabButton: {
        active:
          colorSet.colors.find(color => color.type === 'TAB_BUTTON_ACTIVE')?.color ?? BASIC_COLOR_SET.TAB_BUTTON_ACTIVE,
        inactive:
          colorSet.colors.find(color => color.type === 'TAB_BUTTON_INACTIVE')?.color ??
          BASIC_COLOR_SET.TAB_BUTTON_INACTIVE,
      },

      floorList: {
        bg: colorSet.colors.find(color => color.type === 'FLOOR_LIST_BG')?.color ?? BASIC_COLOR_SET.FLOOR_LIST_BG,
        text: {
          active:
            colorSet.colors.find(color => color.type === 'FLOOR_TEXT_ACTIVE')?.color ??
            BASIC_COLOR_SET.FLOOR_TEXT_ACTIVE,
        },
      },

      list: {
        bg: colorSet.colors.find(color => color.type === 'LIST_BG')?.color ?? BASIC_COLOR_SET.LIST_BG,
        item: {
          border:
            colorSet.colors.find(color => color.type === 'LIST_ITEM_BORDER')?.color ?? BASIC_COLOR_SET.LIST_ITEM_BORDER,
        },
      },

      input: {
        bg: colorSet.colors.find(color => color.type === 'INPUT_BG')?.color ?? BASIC_COLOR_SET.INPUT_BG,
        active: colorSet.colors.find(color => color.type === 'INPUT_ACTIVE')?.color ?? BASIC_COLOR_SET.INPUT_ACTIVE,
        inactive:
          colorSet.colors.find(color => color.type === 'INPUT_INACTIVE')?.color ?? BASIC_COLOR_SET.INPUT_INACTIVE,
      },

      here: {
        bg: colorSet.colors.find(color => color.type === 'MOBILE_HERE_BG')?.color ?? BASIC_COLOR_SET.MOBILE_HERE_BG,
      },
    });
  },

  main: '',
  sub: '',
  navi: '',

  text: {
    title: '',
    category: '',
    item: '',
  },

  header: {
    bg: '',
    text: '',
  },

  gnb: {
    bg: {
      active: '',
      inactive: '',
    },
    text: {
      active: '',
    },
  },

  footer: {
    bg: '',
    text: '',
  },

  button: {
    border: '',
    text: '',
  },

  tabButton: {
    active: '',
    inactive: '',
  },

  floorList: {
    bg: '',
    text: {
      active: '',
    },
  },

  list: {
    bg: '',
    item: {
      border: '',
    },
  },

  input: {
    bg: '',
    active: '',
    inactive: '',
  },

  here: {
    bg: '',
  },
}));

export default useColorSetStore;
