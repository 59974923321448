import { QueryParam, QueryParamKey } from 'types/common/queryParam.type';

/**
 *
 * @param searchString location.search
 * @param key 추출할 QueryParam Type
 * @returns {QueryParam[K]}
 * TODO: searchString window.location.search 로 변경
 */
export const extractParams = <K extends QueryParamKey>(searchString: string, key: K): QueryParam[K] | undefined => {
  const queryParams: URLSearchParams = new URLSearchParams(searchString);
  const value = queryParams.get(key);
  if (value) {
    return value as QueryParam[K];
  }

  return '';
};
