import React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import styles from './LayeredModalLayout.module.scss';

type Props = {
  onClosePopup: () => void;
  children: React.ReactElement;
  dim?: boolean;
};

const LayeredModalLayout = (props: Props) => {
  return (
    <div className={`${styles.background} ${props.dim && styles.dim}`}>
      <OutsideClickHandler useCapture={false} onOutsideClick={props.onClosePopup} display='contents'>
        {props.children}
      </OutsideClickHandler>
    </div>
  );
};
export default LayeredModalLayout;
