import styles from './DetailTenantPopup.module.scss';
import DetailTenantPopupBody from './body/DetailTenantPopupBody';
import DetailTenantPopupButtons from './buttons/DetailPopupButtons';
import DetailTenantPopupHeader from './header/DetailTenantPopupHeader';

type DetailTenantPopupProps = {
  isOpenPopup?: boolean;
  closeDetailPopup: () => void;
};

const DetailTenantPopup = ({ closeDetailPopup, isOpenPopup }: DetailTenantPopupProps) => {
  return (
    <div className={styles.background}>
      <div className={`${styles.wrapper} ${isOpenPopup ? styles.open : styles.close}`}>
        <div className={styles.popup_wrapper}>
          <DetailTenantPopupHeader closeDetailPopup={closeDetailPopup} />
          <DetailTenantPopupBody />
          <DetailTenantPopupButtons closeDetailPopup={closeDetailPopup} />
        </div>
      </div>
    </div>
  );
};

export default DetailTenantPopup;
