import { ICONS } from 'constants/icons';
import useClipBoard from 'hooks/share/useClipBoard';
import useKakaoSdk from 'hooks/share/useKakaoSdk';
import useShareModalStore from 'store/shareModal';
import LayeredModalLayout from '../layout/layered-modal/LayeredModalLayout';
import styles from './SharePopup.module.scss';

type Props = {
  onClosePopup: () => void;
};

const SharePopup = (props: Props) => {
  // hooks
  const { copyUrlOnClipboard } = useClipBoard();
  useKakaoSdk();

  // store
  const shareModalStore = useShareModalStore();

  // 링크 복사
  const onCopyLink = async () => {
    // 공유하기 팝업을 닫는다
    props.onClosePopup();

    await copyUrlOnClipboard(shareModalStore.shareUrl);
  };

  return (
    <LayeredModalLayout {...props} dim>
      <div className={styles.popup}>
        <div className={styles.title_wrapper}>
          <div className={styles.title}>공유 방식을 선택해주세요</div>

          {/* 닫기 버튼 */}
          <div onClick={props.onClosePopup} className={styles.close_btn}>
            <img src={ICONS.CLOSE_BTN} alt='닫기 버튼' />
          </div>
        </div>

        <div className={styles.share_wrapper}>
          {/* 링크 복사 */}
          <button type='button' onClick={onCopyLink} className={styles.button}>
            <div className={styles.btn_icon}>
              <img src={ICONS.COPY_LINK} alt='링크 복사' />
            </div>

            <span className={styles.share_title}>링크 복사</span>
          </button>

          {/* 카카오톡 */}
          <button type='button' id='kakaotalk-sharing-btn' className={styles.button}>
            <div className={styles.btn_icon}>
              <img src={ICONS.KAKAO_SHARE} alt='카카오톡' />
            </div>

            <span className={styles.share_title}>카카오톡</span>
          </button>

          {/* QR 다운로드 */}
          {/* <div onClick={onClickImage} className={styles.button}>
            <div className={styles.btn_icon}>
              <img src={ICONS.QR_DOWNLOAD} alt='QR 다운로드' />
            </div>
            <span className={styles.share_title}>QR 다운로드</span>
          </div> */}
        </div>
      </div>
    </LayeredModalLayout>
  );
};
export default SharePopup;
