import { ME_DEFAULT } from 'data/image';
import { LOCAL_STORAGE_ORIGIN_LOCATION_KEY } from 'data/localStorageKey';
import { addBuilding } from 'map/control/building';
import { clearMyLocation } from 'map/control/common/clear';
import { validMap } from 'map/control/common/valid';
import { changeFloor } from 'map/control/floor';
import { showMyLocationNoAni } from 'map/control/myLocation';
import { startRouteSimulation } from 'map/control/route';
import MapDraw from 'map/MapDraw';
import { PositionNum } from 'map/type/position.type';
import useColorSetStore from 'store/colorSet';
import useFloorStore from 'store/floor';
import useLoadingStore from 'store/loading';
import useMachineStore from 'store/machine';
import useNaviStore from 'store/navi';
import useTenantStore from 'store/tenant';
import useTransportStore from 'store/transport';
import { StorageLocation } from 'types/origin/originLocation.type';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import { changeMapDrawValue } from 'util/map-draw/changeMapDrawValue';
import { returnNaviOption } from 'util/option/returnNaviOption';
import { returnObjectPosition } from 'util/position/returnCurrentPosition';
import { findTenant } from 'util/tenant/findTenant';

const useMapRoute = () => {
  // store
  const tenantStore = useTenantStore();
  const machineStore = useMachineStore();
  const loadingStore = useLoadingStore();
  const floorStore = useFloorStore();
  const naviStore = useNaviStore();
  const transportStore = useTransportStore();
  const themeStore = useColorSetStore();

  /**
   * tenant 에 맞는 빌딩, 층으로 지도를 변경한다
   * @param tenant
   */
  const changeMapFloor = async (tenant: TenantWithPointId) => {
    const position: PositionNum | undefined = returnObjectPosition(tenant.content.objectId);

    if (position) {
      const machinePointId = machineStore.machine?.point.id || '';
      const tenantFloorId = tenant.content.floorId;
      const tenantBuildingId = tenant.content.buildingId;

      // 지도 변경
      loadingStore.setFloorChanging(true);

      // 현재 층 상태값 변경
      floorStore.setCurrentFloorId(tenantFloorId);

      if (tenant.pointId === machinePointId) {
        await changeFloor(tenantFloorId);
      } else {
        await addBuilding(tenantBuildingId, tenantFloorId);
      }

      loadingStore.setFloorChanging(false);
    }
  };

  // tenant 정보를 가지고 QR 로 진입했을 경우 tenantId 로 해당 tenant 로 지도 액션(마커 그리기/카메라 이동/위치 확인)을 한다.
  const showTenantLocation = async (tenantId: string, tenantList: TenantWithPointId[]) => {
    if (!tenantId) return;

    const tenant = findTenant(tenantList, tenantId);

    if (tenant) {
      const { buildingId, floorId } = tenant.content;

      // * 도착지 설정
      tenantStore.setCurrentTenant(tenant);

      const position: PositionNum | undefined = returnObjectPosition(tenant.content.objectId);

      if (position) {
        const machinePointId = machineStore.machine?.point.id || '';

        // 지도 변경
        loadingStore.setFloorChanging(true);

        // 현재 층 상태값 변경
        floorStore.setCurrentFloorId(floorId);

        if (tenant.pointId === machinePointId) {
          await changeFloor(floorId);
        } else {
          await addBuilding(buildingId, floorId);
        }
        loadingStore.setFloorChanging(false);

        return { position, floorId };
      }
    }
  };

  /**
   * tenant를 현재위치(출발지)로 변경한다.
   *
   * 1. tenant 찾는다.
   * 2. 현재위치 이름을 변경한다.
   * 3. localStorage에 현재위치 저장한다.
   * 4. MapDraw 값 변경한다.
   * 5. GPS 그려준다.
   */
  const changeOriginLocation = async (originTenantId: string) => {
    const tenant = findTenant(tenantStore.rawTenants, originTenantId);

    if (tenant) {
      // object의 position 찾는다
      const position = returnObjectPosition(tenant.content.objectId);

      const myLocation: StorageLocation = {
        id: tenant.id,
        position,
        name: tenant.name,
        floorId: tenant.content.floorId,
        objectId: tenant.content.objectId,
        pointId: tenant.pointId,
        buildingId: tenant.content.buildingId,
      };

      // localStorage 에 현재위치를 저장한다
      localStorage.setItem(LOCAL_STORAGE_ORIGIN_LOCATION_KEY, JSON.stringify(myLocation));

      // MapDraw 값 변경한다
      changeMapDrawValue(myLocation);

      // gps 다시 그려준다
      clearMyLocation();
      await showMyLocationNoAni(ME_DEFAULT, position);
    }
  };

  /**
   * 모의주행 경로를 그린다
   */
  async function drawRoute() {
    validMap();

    await MapDraw.map.routeSimulation.set(
      naviStore.naviRoutes?.[transportStore.transport],
      returnNaviOption(themeStore.navi, naviStore.naviDestIconUrl, naviStore.naviDestIconSize),
    );
  }

  async function startRoute() {
    await startRouteSimulation(Number(machineStore.machine?.mapPosition.zoom || 21));
  }

  return { showTenantLocation, changeOriginLocation, changeMapFloor, drawRoute, startRoute };
};
export default useMapRoute;
