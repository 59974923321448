import { useAxios } from 'api/axios';
import { EndPoint } from 'data/endpoint';
import useFloorStore from 'store/floor';
import useLanguageStore from 'store/languages';
import usePointStore from 'store/point';
import { ApiResponse } from 'types/api/response';
import { Floor, PointFloor } from 'types/floor/floor.type';
import { LangCode, MultiLang } from 'types/language/language.type';
import { Point } from 'types/point/point.type';
import { convertMultiLang } from 'util/multi-lang/convertMultiLang';

const useFloor = () => {
  const floorStore = useFloorStore();
  const pointStore = usePointStore();
  const langStore = useLanguageStore();

  const { api } = useAxios();

  // 층 이름
  const findFloorName = (floorId: string, pointId: string): string => {
    if (floorStore.pointFloorsMap) {
      const findFloors: Floor[] | undefined = floorStore.pointFloorsMap.get(pointId);

      const findFloor: Floor | undefined = findFloors?.find(floor => floor.id === floorId);
      const floorName = findFloor?.name[langStore.currentLang] || '';

      // return floorName === 'outdoor' ? '' : floorName;
      return floorName;
    }

    return '';
  };

  // 층 이름
  const findFloorNameByBuildingId = (floorId: string, buildingId: string) => {
    if (floorStore.buildingFloorsMap) {
      const findFloors: Floor[] | undefined = floorStore.buildingFloorsMap.get(buildingId);

      const findFloor: Floor | undefined = findFloors?.find(floor => floor.id === floorId);

      return findFloor?.name[langStore.currentLang] || '';
    }
    return '';
  };

  // 층 설명
  const findFloorDesc = (floorId: string, pointId: string): MultiLang | undefined => {
    if (pointStore.point && floorStore.pointFloorsMap) {
      const findFloors: Floor[] | undefined = floorStore.pointFloorsMap.get(pointId);

      if (findFloors) {
        const findFloor: Floor | undefined = findFloors.find(floor => floor.id === floorId);

        if (findFloor) {
          return findFloor.description;
        }
      }
    }
  };

  // floor fetch
  const getFloors = async (mainLang: LangCode, workspaceId: string, pointId: string, pointsMap: Map<string, Point>) => {
    const response = await api.get<ApiResponse<PointFloor[]>>(EndPoint.floors, {
      params: {
        workspaceId,
      },
    });
    if (response.data) {
      const pointFloorMap = new Map<string, Floor[]>();
      const buildingFloorMap = new Map<string, Floor[]>();

      pointsMap?.forEach(point => {
        response.data.forEach(pointFloor => {
          pointFloor.floors.map(floor => {
            return {
              ...floor,
              name: convertMultiLang(floor.name, mainLang),
              description: convertMultiLang(floor.description, mainLang),
            };
          });

          // point별 floors
          pointFloorMap.set(pointFloor.pointId, pointFloor.floors);

          // building별 floors
          if (point.id === pointFloor.pointId) {
            buildingFloorMap.set(point.buildingId, pointFloor.floors);
          }
        });
      });

      floorStore.setPointFloorsMap(pointFloorMap);
      floorStore.setBuildingFloorsMap(buildingFloorMap);

      const findFloors: Floor[] | undefined = pointFloorMap.get(pointId);

      if (findFloors) {
        floorStore.setFloors(findFloors);
      }
    }
  };

  return { getFloors, findFloorName, findFloorDesc, findFloorNameByBuildingId };
};
export default useFloor;
