/* eslint-disable no-param-reassign */
import { RefObject, useEffect, useRef } from 'react';
import useColorSetStore from 'store/colorSet';
import useLanguageStore from 'store/languages';
import useLoadingStore from 'store/loading';
import { Floor } from 'types/floor/floor.type';
import styles from '../FloorList.module.scss';

type FloorProps = {
  isActive: boolean;
  floor: Floor;
  currentFloorId: string;
  scrollRef: RefObject<HTMLDivElement>;
  clickFloorBtn: boolean;
  handleClickFloor: (id: string) => void;
};

const FloorItem = ({ isActive, floor, currentFloorId, scrollRef, clickFloorBtn, handleClickFloor }: FloorProps) => {
  const loadingStore = useLoadingStore();
  const langStore = useLanguageStore();
  const colorSetStore = useColorSetStore();

  const floorRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (currentFloorId === floor.id) {
      if (floorRef.current && scrollRef.current) {
        if (clickFloorBtn) return;
        const container = scrollRef.current;
        const target = floorRef.current;
        const containerRect = container.getBoundingClientRect();
        const targetRect = target.getBoundingClientRect();
        // 스크롤 이동
        const scrollToLeft = targetRect.left - containerRect.left + container.scrollLeft;
        // 실제 스크롤 이동
        container.scrollLeft = scrollToLeft;
      }
    }
  }, [currentFloorId]);

  return (
    <button
      style={{ color: isActive ? colorSetStore.tabButton.active : colorSetStore.tabButton.inactive }}
      disabled={loadingStore.isLoadingMap}
      className={`${styles.floor} ${isActive && styles.active}`}
      onClick={handleClickFloor.bind(this, floor.id)}
      type='button'
      key={floor.id}
      ref={floorRef}
    >
      {floor.name[langStore.currentLang]}
      {isActive && <div className={styles.active_bar} style={{ background: colorSetStore.tabButton.active }} />}
    </button>
  );
};
export default FloorItem;
