import React from 'react';
import { SvgProps } from '../type/SvgProps';

const CheckSvg = ({ color }: SvgProps) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='25' height='24' viewBox='0 0 25 24' fill='none'>
      <path
        d='M11.6096 5.83439C8.20448 6.32614 5.84276 9.48515 6.33451 12.8902C6.82626 16.2953 9.98526 18.657 13.3903 18.1653C16.7954 17.6735 19.1571 14.5145 18.6654 11.1094C18.6654 11.1094 19.7234 9.87875 19.8754 9.72601C20.018 9.97941 20.15 10.895 20.15 10.895C20.7601 15.12 17.8297 19.0397 13.6047 19.6499C9.37974 20.26 5.46007 17.3296 4.84991 13.1046C4.23975 8.87963 7.17015 4.95996 11.3951 4.3498C13.432 4.05564 15.3995 4.58517 16.9572 5.685L16.092 6.91035C14.8352 6.02299 13.2518 5.59723 11.6096 5.83439Z'
        fill={color}
      />
      <path d='M9.5498 11.5L12.5498 15L19.5 6.5' stroke={color} />
    </svg>
  );
};
export default CheckSvg;
