import SearchSvg from 'components/common/icon/search/SearchSvg';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useColorSetStore from 'store/colorSet';
import useLanguageStore from 'store/languages';
import styles from './SearchField.module.scss';

type Props = {
  handleTenantsByKeyword: (searchWord: string) => void;
};

const SearchField = ({ handleTenantsByKeyword }: Props) => {
  const [keyword, setKeyword] = useState<string>('');

  const langStore = useLanguageStore();
  const colorSetStore = useColorSetStore();

  const { t } = useTranslation();

  const handleKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  };

  useEffect(() => {
    handleTenantsByKeyword(keyword);
  }, [keyword, langStore.currentLang]);

  useEffect(() => {
    return () => {
      handleTenantsByKeyword('');
      setKeyword('');
    };
  }, []);

  const border = keyword ? `1px solid ${colorSetStore.input.active}` : `1px solid ${colorSetStore.input.inactive}`;
  const color = keyword ? colorSetStore.input.active : colorSetStore.input.inactive;

  return (
    <div
      className={styles.field_wrapper}
      style={{
        border,
      }}
    >
      <SearchSvg color={color} />

      <input
        onChange={handleKeyword}
        value={keyword || ''}
        className={styles.search_input}
        style={{
          background: colorSetStore.input.bg,
          color,
        }}
        type='text'
        placeholder={t(`tenant.placeholder`)}
      />
    </div>
  );
};
export default SearchField;
