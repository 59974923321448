import React from 'react';
import DABEEO from 'assets/floor/map-logo.svg';
import styles from './DabeeoLogo.module.scss';

const DabeeoLogo = () => {
  return (
    <div className={styles.logo_badge}>
      <img src={DABEEO} alt='logo-dabeeo' />
    </div>
  );
};
export default DabeeoLogo;
