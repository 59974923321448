import { create } from 'zustand';

type ShowStore = {
  showNavi: boolean;
  setShowNavi: (showNavi: boolean) => void;

  isDiffObjectId: boolean;
  setDiffObjectId: (isDiffObjectId: boolean) => void;

  isOpenTenantList: boolean;
  setOpenTenantList: (isOpenTenantList: boolean) => void;
};

const useShowStore = create<ShowStore>((set, get) => ({
  showNavi: true,

  setShowNavi(showNavi: boolean) {
    set(() => ({
      showNavi,
    }));
  },

  isDiffObjectId: true,

  setDiffObjectId(isDiffObjectId: boolean) {
    set(() => ({
      isDiffObjectId,
    }));
  },

  isOpenTenantList: false,
  setOpenTenantList(isOpenTenantList: boolean) {
    set(() => ({
      isOpenTenantList,
    }));
  },
}));

export default useShowStore;
