import MapDraw from 'map/MapDraw';
import { DataFloor } from 'map/type/dataFloor.type';
import { validMap, validMapData } from './common/valid';

// 층 변경
export const changeFloor = async (floorId: string) => {
  // validMap();
  // clearMap();

  if (MapDraw.map) {
    await MapDraw.map.context.changeFloor(floorId);
  }
};

// 현재 층 정보
export const getCurrentFloor = (): DataFloor => {
  validMap();

  const currentFloor: DataFloor = MapDraw.map.context.getCurrentFloor();
  return currentFloor;
};

// 불러온 적 없는 층의 object 검색하기 전 getFloorData(floorId) 를 통해 해당 층의 정보를 불러와야 한다
export const getFloorDataBeforeGetObject = async (mapData: any) => {
  validMapData();

  await Promise.all(mapData.dataFloor.getFloors().map((floor: any) => mapData.getFloorData(floor.id)));
};
