import { LOCAL_STORAGE_MOTION_PERMISSION_KEY } from 'data/localStorageKey';
import { DeviceMotionEventIOS } from 'types/common/permission.type';
import { errorConsole } from 'util/common/console';

/* eslint-disable prefer-destructuring */
export const requestPermission = (DeviceMotionEvent as unknown as DeviceMotionEventIOS).requestPermission;
export const iOSOver13 = typeof requestPermission === 'function';

// Request permission for iOS 13+ devices
export async function requestDeviceMotionPermission(): Promise<'granted' | 'denied'> {
  if (DeviceMotionEvent) {
    if (iOSOver13) {
      const permission = await (DeviceMotionEvent as unknown as DeviceMotionEventIOS).requestPermission();
      if (permission === 'granted') {
        localStorage.setItem(LOCAL_STORAGE_MOTION_PERMISSION_KEY, 'granted');
      } else if (permission === 'denied') {
        localStorage.setItem(LOCAL_STORAGE_MOTION_PERMISSION_KEY, 'denied');
        errorConsole('Indoor Tracking 서비스 사용을 위해서는 디바이스 모션 접근을 허용해야 합니다.');
      }
      return permission;
    }
  }

  // iOS 13+ 가 아닐 경우는 항상 허용한다
  localStorage.setItem(LOCAL_STORAGE_MOTION_PERMISSION_KEY, 'granted');
  return 'granted';
}
