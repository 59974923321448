import { PositionNum, PositionStr } from 'map/type/position.type';
import { moveCamera } from './camera';
import { drawMarker } from './marker';

// 위치확인
export const showLocation = async (floorId: string, position: PositionStr | PositionNum) => {
  await drawMarker('default', floorId, position);

  moveCamera(position);
};
