import CALENDAR from 'assets/icon/calendar.svg';
import { t } from 'i18next';
import { CustomBoardContent } from 'types/custom-board/customboard.type';
import styles from './DisplayDate.module.scss';

type Props = {
  board: CustomBoardContent;
};

const DisplayDate = ({ board }: Props) => {
  return (
    <div className={styles.bottom}>
      <div className={styles.calendar}>
        <img src={CALENDAR} alt='' />
      </div>
      <div className={styles.date}>
        {/* 상시, 기간일 경우 처리 */}
        {board.displayType === 'ALWAYS_ON' && t('customBoard.always')}
        {board.displayType === 'DURATION' && `${board.startDate} ~ ${board.endDate}`}
      </div>
    </div>
  );
};
export default DisplayDate;
