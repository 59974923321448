import { useTranslation } from 'react-i18next';
import useColorSetStore from 'store/colorSet';
import useMachineStore from 'store/machine';
import DateTime from 'util/date-time/dateTime';
import styles from './ClosedDays.module.scss';

const ClosedDays = () => {
  const machineStore = useMachineStore();
  const colorSetStore = useColorSetStore();

  // todo: 휴무일 변환 조건 추가
  const getClosedDays = () => {
    const result = machineStore.machine?.point.closedDays
      .sort((a, b) => {
        return new Date(a).getTime() - new Date(b).getTime();
      })
      .map(day => new DateTime().convertDate(day));

    return result || [];
  };

  const { t } = useTranslation();

  return (
    <div
      className={styles.closed_days}
      style={{
        background: colorSetStore.footer.bg,
      }}
    >
      <div
        className={styles.closed_days_title}
        style={{
          color: colorSetStore.footer.text,
        }}
      >
        {t(`info.holiday`)}
      </div>

      <div
        className={styles.closed_days_content}
        style={{
          color: colorSetStore.footer.text,
        }}
      >
        {getClosedDays() && getClosedDays().length > 0 ? (
          getClosedDays().map((date, index) => {
            if (index % 2 === 0 && index !== getClosedDays().length - 1) {
              return <div className={styles.closed_day} key={date}>{`${date},`}</div>;
            }
            return (
              <div className={styles.closed_day} key={date}>
                {date}
              </div>
            );
          })
        ) : (
          <div className={styles.closed_day}>{t(`info.noHoliday`)}</div>
        )}
      </div>
    </div>
  );
};
export default ClosedDays;
