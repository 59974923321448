import { MultiLang } from 'types/language/language.type';
import { BuildingType } from 'types/point/point.type';
import { create } from 'zustand';

type BuildingStore = {
  buildingName: MultiLang;
  setBuildingName: (buildingName: MultiLang) => void;
  resetBuildingName: () => void;

  buildingType: BuildingType;
  setBuildingType: (buildingType: BuildingType) => void;
};

// 길찾기 시 빌딩 이름 보여줄 때 사용
const useBuildingStore = create<BuildingStore>((set, get) => ({
  buildingName: { ko: '', en: '', ja: '', cn: '', tw: '' },

  setBuildingName(buildingName: MultiLang) {
    set({
      buildingName,
    });
  },

  resetBuildingName() {
    set({
      buildingName: { ko: '', en: '', ja: '', cn: '', tw: '' },
    });
  },

  buildingType: 'INDOOR',
  setBuildingType(buildingType: BuildingType) {
    set({
      buildingType,
    });
  },
}));

export default useBuildingStore;
