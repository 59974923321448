import DEFAULT_HORI from 'assets/default/default_horizontal.svg';
import styles from './InitialLoadingPage.module.scss';

const InitialLoadingPage = () => {
  return (
    <div className={styles.container}>
      <img src={DEFAULT_HORI} alt='default' />
      <div className={styles.loading}>
        <div className={styles.dots_loading}>
          <div className={styles.dots} />
        </div>
      </div>
    </div>
  );
};

export default InitialLoadingPage;
