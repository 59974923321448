import { COLORS } from 'data/color';
import useLanguageStore from 'store/languages';
import useLoadingStore from 'store/loading';
import useColorSetStore from 'store/colorSet';
import { CategoryGroup } from 'types/category/category.type';
import styles from '../FacilityBar.module.scss';

type FacilityItemProps = {
  categoryGroup: CategoryGroup;
  isActive: boolean;
  handleClickItem: (facility: CategoryGroup) => void;
};

const FacilityItem = ({ categoryGroup, isActive, handleClickItem }: FacilityItemProps) => {
  const loadingStore = useLoadingStore();
  const langStore = useLanguageStore();
  const colorSetStore = useColorSetStore();

  const border = isActive ? `1px solid ${colorSetStore.sub}` : `1px solid ${colorSetStore.button.border}`;

  return (
    <button
      disabled={loadingStore.isLoadingMap}
      type='button'
      onClick={handleClickItem.bind(this, categoryGroup)}
      className={styles.facility}
    >
      <div
        style={{
          background: isActive ? colorSetStore.sub : 'transparent',
          border,
          color: isActive ? COLORS.WHITE : colorSetStore.button.text,
        }}
        className={`${styles.name} ${isActive && styles.active}`}
      >
        {categoryGroup.name[langStore.currentLang]}
      </div>
    </button>
  );
};
export default FacilityItem;
