import { useEffect } from 'react';
import useScreensaverStore from 'store/screensaver';
import { Screensaver } from 'types/screensaver/screensaver.type';
import { errorConsole, logConsole } from 'util/common/console';

const REFETCH_INTERVAL = 6 * 60 * 60 * 1000; // 6시간

const useFetchScreensaver = () => {
  const screensaverStore = useScreensaverStore();

  /**
   * screensaver api에서 내려받은 콘텐츠를 fetch하고 성공한 콘텐츠들과 실패한 콘텐츠를 분리합니다.
   */
  const fetchUrl = async (url: string, screensaver: Screensaver) => {
    try {
      const response = await fetch(url);
      if (response.ok) {
        const blob = await response.blob();
        if (blob) {
          return {
            fileUri: response.url,
            fileType: screensaver.fileType,
            id: screensaver.id,
            sortOrder: screensaver.sortOrder,
          };
        }
      } else {
        screensaverStore.setFailedMedia(screensaver);
      }
    } catch (error) {
      errorConsole(`fetch screensaver error:  ${error}`);
      screensaverStore.setFailedMedia(screensaver);
    }
  };

  const setSucceededMedia = async (screensavers: Screensaver[]) => {
    try {
      // ! 병렬 처리
      const fetchPromises = screensavers.map(url => fetchUrl(url.fileUri, url));
      const result = await Promise.all(fetchPromises);
      if (result) {
        const screensaverLoaded = result.filter(screensaver => screensaver !== undefined);
        screensaverStore.setSucceededMedia(screensaverLoaded as Screensaver[]);
        screensaverStore.setScreensaverStatus(true);
      }

      // ! 직렬 처리
      // screensavers.forEach((screensaver, index) =>
      //   fetch(screensaver.fileUri).then(response => {
      //     if (response) {
      //       // 실패하면?
      //       if (response.status !== 200) {
      //         return screensaverStore.setFailedMedia(screensaver);
      //       }
      //       response.blob().then(() => {
      //         const urls = {
      //           fileUri: response.url,
      //           fileType: screensaver.fileType,
      //           id: screensaver.id,
      //           sortOrder: index,
      //         };
      //         screensaverStore.setSucceededMedia(urls);
      //       });
      //     }
      //   }),
      // );
    } catch (error) {
      errorConsole(`screensaver fetch error: ${error}`);
    }
  };

  /**
   * fetch에 실패한 콘텐츠가 있을 경우 다시 refetch를 시도해서 성공하면 성공 리스트로 넘어갑니다.
   */
  const refetchContent = async () => {
    try {
      if (screensaverStore.failedMedia.length === 0) return;
      screensaverStore.failedMedia.forEach(screensaver => {
        fetch(screensaver.fileUri)
          .then(response => {
            if (response.ok) {
              const file: Screensaver = {
                id: screensaver.id,
                fileUri: screensaver.fileUri,
                fileType: screensaver.fileType,
                sortOrder: screensaver.sortOrder,
              };
              screensaverStore.setOneSucceedMedia(file);
              screensaverStore.deleteFailedMedia(screensaver.fileUri);

              logConsole(`재로드 성공`);
            }
          })
          .catch(error => {
            if (error instanceof TypeError && error.message === 'Failed to fetch') {
              errorConsole(`네트워크 오류 발생 : 동영상을 가져올 수 없습니다.: ${error}`);
            } else {
              errorConsole(`동영상 로드 중 오류 발생: ${error}`);
            }
          });
      });
    } catch (error) {
      errorConsole(`refetch screensaver error: ${error}`);
    }
  };

  useEffect(() => {
    // 일정 시간 간격으로 refetch를 시도합니다.
    const intervalId = setInterval(() => {
      refetchContent();
    }, REFETCH_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, [screensaverStore.failedMedia]);

  return {
    setSucceededMedia,
  };
};

export default useFetchScreensaver;
