import { create } from 'zustand';

type CommonStore = {
  appUuid: string;
  setAppUuid: (appUuid: string) => void;

  /**
   * 모션 접근 허용 여부를 보냈는지 여부 (중복 처리 때문)
   */
  sendGranted: boolean;
  setSendGranted: (sendGranted: boolean) => void;
};
const useCommonStore = create<CommonStore>(set => ({
  appUuid: '',
  setAppUuid(appUuid: string) {
    set(() => ({ appUuid }));
  },

  sendGranted: false,
  setSendGranted(sendGranted: boolean) {
    set(() => ({ sendGranted }));
  },
}));

export default useCommonStore;
