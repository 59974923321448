/* eslint-disable no-console */
import MapDraw from 'map/MapDraw';
import { changeCameraMinMaxZoom, changeCameraZoom, resetCamera } from 'map/control/camera';
import { clearMap, clearMyLocation } from 'map/control/common/clear';
import { DataObject } from 'map/type/dataObject.type';
import { useEffect } from 'react';
import useMapActionStore from 'store/action';
import useCategoryGroupStore from 'store/categoryGroup';
import useFacilityStore from 'store/facility';
import useFloorStore from 'store/floor';
import useLanguageStore from 'store/languages';
import useLoadingStore from 'store/loading';
import useModalStore from 'store/modal';
import useNaviStore from 'store/navi';
import usePointStore from 'store/point';
import usePopupStore from 'store/popup';
import useTenantStore from 'store/tenant';
import useTrackingStore from 'store/tracking';
import { CategoryFeature, CategoryGroupWithTenants } from 'types/category/category.type';
import { PopupType } from 'types/common/popup.type';
import { Floor } from 'types/floor/floor.type';
import { Point } from 'types/point/point.type';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import { logConsole } from 'util/common/console';
import { drawMyLocationByTrackingType } from 'util/common/mapCommon';
import useMultiContent from '../multi/useMultiContent';
import useMultiPoi from '../multi/useMultiPoi';
import useMapDirection from '../trigger/useMapDirection';

type Props = {
  openModal: (type: PopupType) => void;
  closeModal: () => void;
};

// object-click event 처리
const useMapClick = ({ openModal, closeModal }: Props) => {
  const tenantStore = useTenantStore();
  const mapActionStore = useMapActionStore();
  const facilityStore = useFacilityStore();
  const pointStore = usePointStore();
  const popupStore = usePopupStore();
  const categoryStore = useCategoryGroupStore();
  const floorStore = useFloorStore();
  const modalStore = useModalStore();
  const langStore = useLanguageStore();
  const naviStore = useNaviStore();
  const loadingStore = useLoadingStore();
  const trackingStore = useTrackingStore();

  const { handleMultiPoi } = useMultiPoi();
  const { handlePoiContent } = useMultiContent({ closeModal });

  const { switchActionByTrackingType } = useMapDirection();

  // 층, 층 목록, 층 이름 변경, 현재위치 아이콘
  const changeFloorRelatedStates = async (pointId: string) => {
    const findFloors: Floor[] | undefined = floorStore.pointFloorsMap?.get(pointId);

    if (findFloors) {
      floorStore.setFloors(findFloors);

      const findMainFloor = findFloors.find(floor => floor.main);

      if (findMainFloor) {
        // 층
        floorStore.setCurrentFloorId(findMainFloor.id);
        // 층 이름
        floorStore.setFloorName(findMainFloor.name[langStore.currentLang]);

        // 현재위치 아이콘
        if (findMainFloor.id === MapDraw.machineFloorId) {
          await switchActionByTrackingType(trackingStore.trackingType);
        }
      }
    }
  };

  // 카테고리
  const changeCategory = (pointId: string) => {
    if (categoryStore.mapFacilityCategoriesMap) {
      const findCategories: CategoryGroupWithTenants | undefined = categoryStore.mapFacilityCategoriesMap.get(pointId);

      if (findCategories) {
        categoryStore.setCategoryList(CategoryFeature.mapFacility, findCategories);
      }
    }
  };

  // 포인트
  const changePointAndCategory = (pointId: string) => {
    const findPoint = pointStore.pointsMap?.get(pointId);

    if (findPoint) {
      pointStore.setSelectedPoint(findPoint);
      changeCategory(findPoint.id);
    }
  };

  // zoom, 카메라
  const changePointCamera = (pointId: string) => {
    const findPoint: Point | undefined = pointStore.pointsMap?.get(pointId);

    if (findPoint) {
      if (trackingStore.trackingType === 'OFF') {
        resetCamera();
      }

      // 지도 zoom 값은 포인트 default 값으로 처리
      // 내 포인트일 경우 기기 zoom 값 적용한다
      if (findPoint.id === MapDraw.pointId) {
        changeCameraZoom(MapDraw.zoom);
      } else {
        // 다른 포인트일 경우 포인트 default zoom 값 적용한다.
        changeCameraZoom(Number(findPoint.mapZoom));
      }

      changeCameraMinMaxZoom(Number(findPoint.mapMinZoom), Number(findPoint.mapMaxZoom));
    }
  };

  // outdoor map click 시 building mask 를 제거하고 원하는 building 을 그리고 싶다.
  const handleClickOutdoorBuilding = async (objectClickEvent: any) => {
    const findObject: DataObject | undefined = objectClickEvent.detail.find(
      (object: DataObject) => object.refBuildingId,
    );

    if (findObject) {
      const indoorBuildingId: string | null = findObject.refBuildingId;

      let outdoorBuildingId;

      // 우리가 가지고 있는 빌딩들의 outdoor 를 찾아서 만약 있다면 outdoorBuildingId 를 저장
      pointStore.pointsMap?.forEach(value => {
        if (value.buildingType === 'OUTDOOR') {
          outdoorBuildingId = value.buildingId;
        }
      });

      if (indoorBuildingId) {
        // 클릭한 빌딩이 outdoor 가 아니라면 outdoor 빌딩을 지우고 클릭한 빌딩을 그려라
        if (outdoorBuildingId !== indoorBuildingId) {
          // 지도 기능
          MapDraw.map.context.removeBuilding(outdoorBuildingId);
          MapDraw.map.context.addBuilding(indoorBuildingId);

          // 상태 관리
          const findPoint = pointStore.buildingPointsMap?.get(indoorBuildingId);

          if (findPoint) {
            // 층, 층 목록, 층 이름 변경
            await changeFloorRelatedStates(findPoint.id);

            // 포인트, 카테고리
            changePointAndCategory(findPoint.id);

            // 카메라 기능
            changePointCamera(findPoint.id);
          }
        }
      }
    }
  };

  // 상태 초기화
  const resetState = () => {
    mapActionStore.resetMapActions();
    facilityStore.resetCurrentFacilityId();
    tenantStore.resetCurrentTenant();
    facilityStore.clearFacilityTransition();
    popupStore.setOpenToastPopup(false);
  };

  // 지도 클릭 시
  // TODO: object click 시 일어나는 일들 정리해서 함수 분리
  const handleClickObject = (objectClickEvent: any) => {
    // Poi 가 들어있는 object 를 모두 찾는다
    const findPoiArrObjects: DataObject[] = objectClickEvent.detail.filter(
      (object: DataObject) => object.poiDataArr.length > 0,
    );

    if (findPoiArrObjects.length < 1) {
      return;
    }

    /**
     * 다중 content 인 경우
     */
    // poiData 가 한 개인 첫번째 object 를 찾는다
    const findObject: DataObject | undefined = findPoiArrObjects.find(object => object.poiDataArr.length <= 1);

    if (findObject) {
      // clearMap();
      resetState();

      // content 갯수 check
      const findTenants: TenantWithPointId[] | undefined = tenantStore.poiTenantsMap?.get(findObject.poiDataArr[0].id);

      if (findTenants) {
        handlePoiContent(findTenants, openModal, findObject.position);

        return;
      }

      // tenant를 찾지 못했다면 모달을 닫는다
      // closeModal();
      return;
    }

    /**
     * 다중 poi 인 경우
     */
    /**
     * 2024 0215
     * ! 우선은 다중 poi 고려하지 않기로 함
     * 추후 다중 poi 로직 들어가게 되면 이곳에 작성
     * poi 한개일 경우를 위에서 먼저 처리하고 있기 때문에 위치 변경 시 버그남
     */
    // poiData 가 여러개일 경우
    const findMultiPoiObjects: DataObject[] = findPoiArrObjects.filter(object => object.poiDataArr.length > 1);

    if (findMultiPoiObjects) {
      logConsole(`다중 poi 임 !`, findMultiPoiObjects);

      handleMultiPoi(objectClickEvent.detail[0].position, findPoiArrObjects, openModal);
    }
  };

  /**
   * @param objectClickEvent dabeeoMaps 의 object-click event
   */
  const onClickMapObject = async (objectClickEvent: any) => {
    clearMyLocation();
    clearMap();

    await drawMyLocationByTrackingType(trackingStore.trackingType, MapDraw.machineCoordinate);

    naviStore.setNaviType('INIT');

    modalStore.closeAllModal();

    handleClickObject(objectClickEvent);

    await handleClickOutdoorBuilding(objectClickEvent);
  };

  useEffect(() => {
    if (!loadingStore.isLoadingMap) {
      if (MapDraw.mapContainer) {
        MapDraw.mapContainer.addEventListener('object-click', onClickMapObject);
      }
    }

    return () => {
      if (MapDraw.mapContainer) {
        MapDraw.mapContainer.removeEventListener('object-click', onClickMapObject);
      }
    };
  }, [loadingStore.isLoadingMap, MapDraw.mapContainer, trackingStore.trackingType]);

  return {
    onClickMapObject,
  };
};

export default useMapClick;
