/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
import DownSvg from 'components/common/icon/arrow/down/DownSvg';
import UpSvg from 'components/common/icon/arrow/up/UpSvg';
import { t } from 'i18next';
import useCategoryGroupStore from 'store/categoryGroup';
import useColorSetStore from 'store/colorSet';
import useFacilityStore from 'store/facility';
import usePopupStore from 'store/popup';
import { TransitionStatus } from 'types/common/modal.type';
import FacilityBar from '../facility-bar/FacilityBar';
import styles from './FacilityIsland.module.scss';

type Props = {
  isOpenNoFacilityToast: boolean;
  removeNoFacilityToast: () => void;
  openNoFacilityToast: () => void;
};

const FacilityIsland = ({ isOpenNoFacilityToast, removeNoFacilityToast, openNoFacilityToast }: Props) => {
  const categoryGroupStore = useCategoryGroupStore();
  const facilityStore = useFacilityStore();
  const popupStore = usePopupStore();
  const colorSetStore = useColorSetStore();

  const clickIsland = () => {
    facilityStore.toggleFacilityTransition();
    popupStore.setOpenToastPopup(false);
  };

  const isOpen = facilityStore.facilityTransition === TransitionStatus.OPEN;
  const isClose = facilityStore.facilityTransition === TransitionStatus.CLOSE;

  const color = isOpen
    ? colorSetStore.tabButton.active
    : isClose
    ? colorSetStore.tabButton.inactive
    : colorSetStore.tabButton.inactive;

  return (
    <>
      {/* 현재 point 의 편의시설이 있을 경우에만 노출 */}
      {categoryGroupStore.mapFacilityCategories &&
        categoryGroupStore.mapFacilityCategories.categoryGroups.length > 0 && (
          <>
            <div
              onClick={clickIsland}
              style={{ background: colorSetStore.header.bg }}
              className={`${styles.facility_island} ${isOpen && styles.open} ${isClose && styles.close}`}
            >
              <span className={styles.facility_island_title} style={{ color }}>
                {t(`floor.mainFacility`)}
              </span>

              {isOpen ? (
                <DownSvg color={colorSetStore.tabButton.active} />
              ) : (
                <UpSvg color={colorSetStore.tabButton.inactive} />
              )}
            </div>

            {isOpen && (
              <FacilityBar
                isOpenNoFacilityToast={isOpenNoFacilityToast}
                removeNoFacilityToast={removeNoFacilityToast}
                openNoFacilityToast={openNoFacilityToast}
              />
            )}
          </>
        )}
    </>
  );
};
export default FacilityIsland;
