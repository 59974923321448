import { COLDBOX_POC_WORKSPACE_ID, PROD_POC_WORKSPACE_ID, PROD_SALES_WORKSPACE_ID } from 'data/commons';

/**
 * poc 기능을 사용할 수 있는 워크스페이스인지 여부 검사
 */
export const validPocWorkspace = (machineWorkspaceId: string): boolean => {
  if (
    machineWorkspaceId === COLDBOX_POC_WORKSPACE_ID ||
    machineWorkspaceId === PROD_POC_WORKSPACE_ID ||
    machineWorkspaceId === PROD_SALES_WORKSPACE_ID
  ) {
    return true;
  }

  return false;
};
