import { IconSize } from 'map/type/iconSize.type';

export const returnNaviOption = (lineColor: string, iconUrl: string, iconSize: IconSize) => {
  return {
    // 출발지 아이콘 및 주행선
    origin: {
      markerOptions: {
        iconUrl: '/assets/icon/start.svg',
        width: 20,
        height: 20,
        positionZ: 10,
        visibleIcon: true,
      },
    },

    // 도착지 아이콘 및 주행선
    destination: {
      showTag: false,
      markerOptions: {
        iconUrl,
        width: iconSize.width,
        height: iconSize.height,
        positionZ: 10,
        visibleIcon: true,
      },
      // lineOptions: {
      //   lineColor: '#ffbb00',
      //   solidLineEnabled: true,
      //   solidLineWidth: 20,
      // },
    },

    // 기본 주행선 옵션
    defaultLineOption: {
      lineColor,
      solidLineEnabled: true,
      solidLineWidth: 13,
      solidLineCap: 'round',
      solidLineJoin: 'round',
    },
    lineDivide: false, // 네비게이션 선 분할여부 결정 (false 인 경우, defaultLineOption 만 사용)
    lineZ: 10,
  };
};
