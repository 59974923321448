import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import 'dayjs/locale/ko';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/zh-tw';
import i18n from 'i18n';
import { LangCode, Language } from 'types/language/language.type';
import { create } from 'zustand';

type LanguageStoreProps = {
  currentLang: LangCode;
  setCurrentLang: (lang: LangCode) => void;

  languages: Language[];
  setLanguages: (langs: Language[]) => void;
};

const useLanguageStore = create<LanguageStoreProps>(set => ({
  currentLang: LangCode.ko,

  setCurrentLang(currentLang: LangCode) {
    i18n.changeLanguage(currentLang ?? LangCode.ko);
    set({ currentLang });

    // dayjs 언어 변경
    let langCodeForDayjs = currentLang as string;

    if (currentLang === LangCode.cn) {
      langCodeForDayjs = 'zh-cn';
    }

    if (currentLang === LangCode.tw) {
      langCodeForDayjs = 'zh-tw';
    }
    dayjs.locale(langCodeForDayjs);
  },

  languages: [],

  setLanguages(languages: Language[]) {
    set({ languages });
  },
}));

export default useLanguageStore;
