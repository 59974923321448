import { PositionNum } from 'map/type/position.type';

export type IndoorTrackingOption = {
  position: {
    x: number;
    y: number;
  };
  // ! 임의의 값 설정
  height: number; // 키(신장)
  focus: boolean; // 내위치 마커가 항상 지도 중심에 올 지 여부 설정
  heading: boolean; // 내위치 마커의 방향이 항상 전방을 가리키도록 할 지 여부 설정
  loggingOption: {
    // ! tracking 결과 text 파일 다운로드 막으려면 옵션 둘 다 끄기
    enableLocal: boolean;
    enableNetwork: boolean;
  };
};

export const returnIndoorTrackingOption = (originPosition: PositionNum, heading: boolean): IndoorTrackingOption => {
  return {
    position: {
      x: originPosition.x,
      y: originPosition.y,
    },
    height: 160,
    focus: true, // 내위치 마커가 지도 중심에 오지 않는다
    heading,
    loggingOption: {
      enableLocal: false,
      enableNetwork: false,
    },
  };
};
