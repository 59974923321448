import React from 'react';
import styles from './DisabledScreen.module.scss';

const DisabledScreen = () => {
  return (
    <button type='button' className={styles.disabled_screen} disabled>
      {}
    </button>
  );
};
export default DisabledScreen;
