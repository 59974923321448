/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState } from 'react';
import { TransitionStatus } from 'types/common/modal.type';

// 열리고 닫히는 애니메이션을 위해 <처음 진입 상태, 열리는 상태, 닫히는 상태> 를 분리해서 관리하기 위한 훅
const useTransition = () => {
  const [transition, setTransition] = useState<TransitionStatus>(TransitionStatus.BASE);

  const removeTransition = () => {
    setTransition(TransitionStatus.BASE);
  };

  const openTransition = () => {
    setTransition(TransitionStatus.OPEN);
  };

  const closeTransition = () => {
    setTransition(TransitionStatus.CLOSE);
  };

  return {
    transition,
    removeTransition,
    openTransition,
    closeTransition,
  };
};
export default useTransition;
