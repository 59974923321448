import { ICONS } from 'constants/icons';
import { t } from 'i18next';
import styles from './CloseIsland.module.scss';

type Props = {
  clickIsland: () => void;
  isOpen: boolean;
};

const CloseIsland = ({ clickIsland, isOpen }: Props) => {
  return (
    <div onClick={clickIsland} className={`${styles.select_island} ${isOpen && styles.open}`}>
      <span className={`${styles.island_title} ${isOpen && styles.open}`}>{t('button.close')}</span>
      <img src={ICONS.ARROW_DOWN_BLACK} alt='arrow down' />
    </div>
  );
};
export default CloseIsland;
