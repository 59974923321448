import MapDraw from '../MapDraw';
import { validMap } from './common/valid';

export const hideBuilding = (buildingId: string) => {
  validMap();

  MapDraw.map.context.removeBuilding(buildingId);
};

export const addBuilding = async (buildingId: string, floorId: string) => {
  validMap();

  await MapDraw.map.context?.addBuilding(buildingId, floorId);
};
