/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-use-before-define */
import useFloor from 'hooks/api/floor/useFloor';
import { addBuilding } from 'map/control/building';
import { resetCamera } from 'map/control/camera';
import { clearMap } from 'map/control/common/clear';
import useBuildingStore from 'store/building';
import useCategoryGroupStore from 'store/categoryGroup';
import useFacilityStore from 'store/facility';
import useFloorStore from 'store/floor';
import useLoadingStore from 'store/loading';
import usePointStore from 'store/point';
import useTrackingStore from 'store/tracking';
import { CategoryFeature, CategoryGroupWithTenants } from 'types/category/category.type';
import { Floor } from 'types/floor/floor.type';
import { Point } from 'types/point/point.type';

const useMapFloor = () => {
  const pointStore = usePointStore();
  const floorStore = useFloorStore();
  const loadingStore = useLoadingStore();
  const categoryStore = useCategoryGroupStore();
  const facilityStore = useFacilityStore();
  const buildingStore = useBuildingStore();
  const trackingStore = useTrackingStore();

  const { findFloorName } = useFloor();

  /**
   * 1. 포인트, 층, 카테고리 관련 전역 상태 변경
   * 2. 다른 포인트로 이동 (빌딩 추가, 메인층으로 층 이동)
   *
   * @param pointId
   * @param floorId
   */
  const changeMapFloor = async (pointId: string, floorId: string) => {
    loadingStore.setFloorChanging(true);

    clearMap();

    if (trackingStore.trackingType === 'OFF') {
      resetCamera();
    }

    const point = changePoint(pointId);

    if (point) {
      facilityStore.resetCurrentFacilityId();

      changeCategory(point.id);

      // 층
      floorStore.setCurrentFloorId(floorId);
      // 층 이름
      changeFloorName(floorId);
      // 층 목록
      changeFloors(pointId);

      await addBuilding(point.buildingId, floorId);
    }

    loadingStore.setFloorChanging(false);
  };

  /**
   * 포인트, 층, 카테고리 관련 전역 상태 변경
   *
   * @param pointId
   * @param floorId
   */
  const changeMapStates = (pointId: string, floorId: string) => {
    clearMap();

    loadingStore.setFloorChanging(true);

    const point = changePoint(pointId);

    if (point) {
      facilityStore.resetCurrentFacilityId();

      changeCategory(point.id);

      // 층
      floorStore.setCurrentFloorId(floorId);
      // 층 이름
      changeFloorName(floorId);
      // 층 목록
      changeFloors(pointId);
    }

    loadingStore.setFloorChanging(false);
  };

  /**
   * 포인트를 변경한다
   * @param pointId 변경한 포인트
   * @returns {Point | undefined} 찾은 포인트 반환
   */
  function changePoint(pointId: string): Point | undefined {
    const point: Point | undefined = pointStore.pointsMap?.get(pointId);

    if (point) {
      pointStore.setSelectedPoint(point);
      buildingStore.setBuildingName(point.name);

      return point;
    }
  }

  /**
   * 층 이름을 변경한다
   * @param floorId 변경한 층
   */
  function changeFloorName(floorId: string) {
    const floorName = findFloorName(floorId, pointStore.selectedPoint?.id || '');
    floorStore.setFloorName(floorName);
  }

  /**
   * 층 목록 변경한다
   * @param pointId
   */
  const changeFloors = (pointId: string) => {
    const findFloors: Floor[] | undefined = floorStore.pointFloorsMap?.get(pointId);

    if (findFloors) {
      floorStore.setFloors(findFloors);
    }
  };

  /**
   * point 의 category 로 변경한다
   * @param pointId 변경한 포인트
   */
  function changeCategory(pointId: string) {
    if (categoryStore.mapFacilityCategoriesMap) {
      const findCategories: CategoryGroupWithTenants | undefined = categoryStore.mapFacilityCategoriesMap.get(pointId);

      if (findCategories) {
        categoryStore.setCategoryList(CategoryFeature.mapFacility, findCategories);
      }
    }
  }

  return { changeMapFloor, changeMapStates };
};
export default useMapFloor;
