import { clearAllMarkers, clearNavigation } from 'map/control/common/clear';
import { DataObject, PoiData } from 'map/type/dataObject.type';
import { PositionStr } from 'map/type/position.type';
import useMapActionStore from 'store/action';
import useFacilityStore from 'store/facility';
import useLanguageStore from 'store/languages';
import useModalStore from 'store/modal';
import useTenantStore from 'store/tenant';
import { PopupType } from 'types/common/popup.type';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import { errorConsole, warnConsole } from 'util/common/console';
import { sortByLangOrder } from 'util/sort/lang-order/sortByLangOrder';

const useMultiPoi = () => {
  const tenantStore = useTenantStore();
  const mapActionStore = useMapActionStore();
  const facilityStore = useFacilityStore();
  const langStore = useLanguageStore();
  const modalStore = useModalStore();

  const handleMultiPoi = async (
    objectPosition: PositionStr,
    findMultiPoiObjects: DataObject[],
    openModal: (modalType: PopupType) => void,
  ) => {
    if (findMultiPoiObjects.length > 1) {
      warnConsole('다중 Poi Objects 가 여러개입니다.');
      return;
    }

    const poiData: PoiData[] = findMultiPoiObjects[0].poiDataArr;
    const poiDataId: string = poiData[0].id;
    const findTenant: TenantWithPointId | undefined = tenantStore.rawTenants.find(
      item => item.content.poiId === poiDataId,
    );

    if (!findTenant) {
      errorConsole('해당 poi를 가진 tenant를 찾을 수 없습니다.');
      return;
    }

    if (findTenant) {
      clearAllMarkers();
      clearNavigation();
      mapActionStore.resetMapActions();
      facilityStore.resetCurrentFacilityId();
      tenantStore.resetCurrentTenant();

      const excludeFirstPoiArr: PoiData[] = poiData.slice(1);

      // 첫번째 테넌트 제외한 배열
      const excludeFirstTenantArr: TenantWithPointId[] = tenantStore.rawTenants
        .filter(rawTenant => excludeFirstPoiArr.some((poi: PoiData) => poi.id === rawTenant.content.poiId))
        .sort((a, b) => sortByLangOrder(a.name[langStore.currentLang], b.name[langStore.currentLang]));

      // 테넌트 설정
      tenantStore.setCurrentTenant(findTenant);
      tenantStore.setCurrentTenantArr([findTenant, ...excludeFirstTenantArr]);

      // 테넌트 목록 팝업 열기
      modalStore.setOpenTenantListModal(true);

      // TODO: 다중 poi 일 경우 camera focus 할 position 변경해야 함
      // detail 의 position 하면 안됌
      // object 의 position 으로 할 경우 object find 해야 하고
      // poi 로 할 경우 poi id 로 poi position 찾아야 함
      // moveCamera(objectPosition);
    }
  };

  return { handleMultiPoi };
};
export default useMultiPoi;
