import DisplayDate from 'components/custom-board/common/date/DisplayDate';
import CustomBoardPopup from 'components/custom-board/popup/CustomBoardPopup';
import { useState } from 'react';
import { Transition } from 'react-transition-group';
import useLanguageStore from 'store/languages';
import { CustomBoardContent } from 'types/custom-board/customboard.type';
import DisplayBadge from '../../common/badge/DisplayBadge';
import styles from './CustomBoardItem.module.scss';

type Props = {
  customBoard: CustomBoardContent;
  closeGoButton: () => void;
};

const CustomBoardItem = ({ customBoard, closeGoButton }: Props) => {
  const [openCustomBoardPopup, setOpenCustomBoardPopup] = useState(false);

  const { currentLang } = useLanguageStore();

  const onClickCustomBoardItem = () => {
    closeGoButton();
    setOpenCustomBoardPopup(true);
  };

  return (
    <>
      <div className={styles.container} onClick={onClickCustomBoardItem}>
        <div className={styles.thumb}>
          <img src={customBoard.mainImageUri} alt='main-logo' />
        </div>
        <div className={styles.details}>
          <div className={styles.top}>
            <DisplayBadge displayStatus={customBoard.displayStatus} />
            <div className={styles.title}>{customBoard.title[currentLang] || '-'}</div>
          </div>

          <DisplayDate board={customBoard} />
        </div>
      </div>

      <Transition unmountOnExit in={openCustomBoardPopup} timeout={500}>
        <CustomBoardPopup
          customBoard={customBoard}
          closePopup={() => setOpenCustomBoardPopup(false)}
          openPopup={openCustomBoardPopup}
        />
      </Transition>
    </>
  );
};

export default CustomBoardItem;
