import React from 'react';
import styles from './LoadingSpinner.module.scss';

type LoadingSpinnerProps = {
  text?: string;
};

const LoadingSpinner = ({ text }: LoadingSpinnerProps) => {
  return (
    <div className={styles.wrapper}>
      <span className={styles.loader} />

      {text && <div className={styles.text}>{text}</div>}
    </div>
  );
};
export default LoadingSpinner;
