/* eslint-disable react/destructuring-assignment */
import DotSpan from 'components/common/info/dot/DotSpan';
import FloorName from 'components/common/info/floor/FloorName';
import PointName from 'components/common/info/point/PointName';
import useLanguageStore from 'store/languages';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import styles from './ParkingLocationInfo.module.scss';

type Props = {
  parkingLocation: TenantWithPointId;
};

const ParkingLocationInfo = (props: Props) => {
  const langStore = useLanguageStore();

  return (
    <div className={styles.parking_location_info}>
      <div className={styles.tenant_name}>{props.parkingLocation.name[langStore.currentLang]}</div>
      <div className={styles.info_wrapper}>
        <FloorName tenant={props.parkingLocation} />
        <DotSpan />
        <PointName tenant={props.parkingLocation} />
      </div>
    </div>
  );
};
export default ParkingLocationInfo;
