import { RefObject, useEffect, useState } from 'react';

// top button을 여러 페이지에서 사용하고 있으므로, 관리하기 용이하도록 hook으로 처리
type Props = {
  listRef: RefObject<HTMLDivElement>;
};

const useGoTopButton = ({ listRef }: Props) => {
  const [showGoButton, setShowGoButton] = useState(false);

  const handleGoToTopButton = () => {
    if (listRef.current?.scrollTop !== 0) {
      return setShowGoButton(true);
    }

    setShowGoButton(false);
  };

  const hideGoToTopButton = () => {
    setShowGoButton(false);
  };

  useEffect(() => {
    if (listRef.current) {
      listRef.current.addEventListener('scroll', handleGoToTopButton);
    }

    return () => {
      listRef.current?.removeEventListener('scroll', handleGoToTopButton);
    };
  }, []);

  return {
    showGoButton,
    hideGoToTopButton,
  };
};

export default useGoTopButton;
