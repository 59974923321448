import usePointStore from 'store/point';
import useTenantStore from 'store/tenant';
import CategoryName from '../../category/basic/CategoryName';
import Divider from '../../divider/Divider';
import DotSpan from '../../dot/DotSpan';
import FloorName from '../../floor/FloorName';
import PointName from '../../point/PointName';
import styles from './InfoGroup.module.scss';

// floor, point, category name
// TODO: refactor
const InfoGroup = () => {
  const tenantStore = useTenantStore();
  const pointStore = usePointStore();

  if (!tenantStore.currentTenant) {
    return <> </>;
  }

  return (
    <div className={styles.sub_titles}>
      <FloorName tenant={tenantStore.currentTenant} />

      {pointStore.points.length > 1 && (
        <>
          <DotSpan />
          <PointName tenant={tenantStore.currentTenant} />
        </>
      )}

      <Divider />

      <CategoryName tenant={tenantStore.currentTenant} />
    </div>
  );
};
export default InfoGroup;
