import { useAxios } from 'api/axios';
import { EndPoint } from 'data/endpoint';
import useBuildingStore from 'store/building';
import usePointStore from 'store/point';
import { ApiResponse } from 'types/api/response';
import { LangCode } from 'types/language/language.type';
import { Point } from 'types/point/point.type';
import { convertMultiLang } from 'util/multi-lang/convertMultiLang';
import useFloor from '../floor/useFloor';

const usePoint = () => {
  const { api } = useAxios();
  const { getFloors } = useFloor();

  const pointStore = usePointStore();
  const buildingStore = useBuildingStore();

  // point 이름
  const findPointName = (tenantPointId: string) => {
    if (pointStore.pointsMap) {
      const currentPoint: Point | undefined = pointStore.pointsMap.get(tenantPointId);

      if (currentPoint) {
        return currentPoint.name;
      }
    }
  };

  // point 이름
  const setPointNameByBuildingId = (buildingId: string) => {
    if (pointStore.buildingPointsMap) {
      const currentPoint: Point | undefined = pointStore.buildingPointsMap.get(buildingId);

      if (currentPoint) {
        buildingStore.setBuildingName(currentPoint.name);
        return currentPoint.name;
      }
    }
  };

  // 현재 point 정보 세팅
  const setPointAndSelectedPoint = (point: Point): void => {
    pointStore.setPoint(point);
    pointStore.setSelectedPoint(point);
  };

  // points fetch
  const getPoints = async (mainLang: LangCode, workspaceId: string, pointId: string) => {
    const points = await api.get<ApiResponse<Point[]>>(EndPoint.points, {
      params: {
        workspaceId,
      },
    });

    if (points) {
      pointStore.setPoints(points.data);

      const pointsMap = new Map<string, Point>();
      const buildingPointsMap = new Map<string, Point>();

      points.data.forEach(point => {
        pointsMap.set(point.id, {
          ...point,
          name: convertMultiLang(point.name, mainLang),
        });

        buildingPointsMap.set(point.buildingId, {
          ...point,
          name: convertMultiLang(point.name, mainLang),
        });
      });

      pointStore.setPointsMap(pointsMap);
      pointStore.setBuildingPointsMap(buildingPointsMap);

      await getFloors(mainLang, workspaceId, pointId, pointsMap);
    }
  };

  return {
    getPoints,
    findPointName,
    setPointAndSelectedPoint,
    setPointNameByBuildingId,
  };
};
export default usePoint;
