import LocationBar from 'components/floor/location-bar/LocationBar';
import styles from 'pages/Pages.module.scss';
import TenantList from './body/list/TenantList';
import TenantHeader from './header/TenantHeader';

const TenantContainer = () => {
  return (
    <div className={styles.pages}>
      <LocationBar changePosition={false} />
      <TenantHeader />
      <TenantList />
    </div>
  );
};
export default TenantContainer;
