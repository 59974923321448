import useStats from 'hooks/api/stats/useStats';
import { requestDeviceMotionPermission } from 'util/permission/requestDeviceMotionPermission';
import useMapDirection from './useMapDirection';

const useMapMotionPermission = () => {
  const { onDirectionTracking, offDirectionTracking } = useMapDirection();
  const { postDeviceMotionAttached } = useStats();

  const onRequestMotionPermission = async () => {
    const permission = await requestDeviceMotionPermission();

    if (permission === 'denied') {
      await offDirectionTracking();
      await postDeviceMotionAttached(false);
      return;
    }

    await onDirectionTracking();
    await postDeviceMotionAttached(true);
  };

  return { onRequestMotionPermission };
};
export default useMapMotionPermission;
