import useColorSetStore from 'store/colorSet';
import useLanguageStore from 'store/languages';
import useTenantStore from 'store/tenant';
import styles from './TenantHeader.module.scss';
import SearchField from './search-field/SearchField';

const TenantHeader = () => {
  const tenantStore = useTenantStore();
  const langStore = useLanguageStore();
  const colorSetStore = useColorSetStore();

  const handleTenantsByKeyword = (searchWord: string) => {
    if (!tenantStore.searchTenants) return;

    tenantStore.setFilteredTenants(tenantStore.searchTenants, langStore.currentLang, searchWord);
  };

  return (
    <div
      className={styles.tenant_header}
      style={{
        background: colorSetStore.header.bg,
      }}
    >
      <SearchField handleTenantsByKeyword={handleTenantsByKeyword} />
    </div>
  );
};
export default TenantHeader;
