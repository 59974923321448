import { findObject } from 'map/control/object';
import { PositionNum } from 'map/type/position.type';

// objectId 로 object 를 찾아서 object position return
export const returnObjectPosition = (objectId: string): PositionNum => {
  if (objectId) {
    const object = findObject(objectId);

    let position;

    if (object) {
      if (!Array.isArray(object)) {
        position = object.position;
      } else {
        position = object[0].position;
      }

      return position;
    }

    return { x: 0, y: 0 };
  }

  return { x: 0, y: 0 };
};
