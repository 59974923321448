import { useState, useEffect } from 'react';
import { fetchAndColorizeSvgData } from 'util/common/common';

type Props = {
  iconUri: string;
  iconColor: string;
};

const MenuIcon = ({ iconUri, iconColor }: Props) => {
  const [svgDataUri, setSvgDataUri] = useState<string | null>(null);
  useEffect(() => {
    fetchAndColorizeSvgData(iconUri, iconColor).then(svgUri => setSvgDataUri(svgUri));
  }, [iconUri, iconColor]);

  return svgDataUri ? <img src={svgDataUri} alt='' /> : null;
};
export default MenuIcon;
