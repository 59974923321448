import MapDraw from 'map/MapDraw';
import { StorageLocation } from 'types/origin/originLocation.type';

// 기기 상태값 변경
export const changeMapDrawValue = (originLocation: StorageLocation) => {
  // 기기 위치
  MapDraw.machineCoordinate = {
    x: Number(originLocation.position.x),
    y: Number(originLocation.position.y),
  };

  // 기기 층
  MapDraw.machineFloorId = originLocation.floorId;

  // 포인트
  MapDraw.pointId = originLocation.pointId;

  // 빌딩
  MapDraw.buildingId = originLocation.buildingId;
};
