import { useAxios } from 'api/axios';
import { EndPoint } from 'data/endpoint';
import { useEffect, useState } from 'react';
import useLoadingStore from 'store/loading';
import { ApiResponse } from 'types/api/response';
import { Screensaver } from 'types/screensaver/screensaver.type';
import { logConsole } from 'util/common/console';
import useFetchScreensaver from './useFetchScreensaver';

const useScreensaver = () => {
  const [screensaverList, setScreensaverList] = useState<Screensaver[]>([]);

  const loadingStore = useLoadingStore();

  const { api } = useAxios();
  const { setSucceededMedia } = useFetchScreensaver();

  useEffect(() => {
    if (loadingStore.hasMapData) {
      logConsole(`> 스크린 세이버 blob 체크 시작`);

      setSucceededMedia(screensaverList);
    }
  }, [loadingStore.hasMapData]);

  // screensaver 조회
  const getScreensavers = async (pointId: string) => {
    const response = await api.get<ApiResponse<Screensaver[]>>(EndPoint.screensavers, {
      params: {
        pointId,
      },
    });

    if (response.data) {
      const clientResponse = response.data.map((screensaver: Screensaver, index: number) => {
        return {
          id: screensaver.id,
          fileUri: screensaver.fileUri,
          fileType: screensaver.fileType,
          sortOrder: index,
        };
      });

      setScreensaverList(clientResponse);
    }
  };
  return { getScreensavers };
};
export default useScreensaver;
