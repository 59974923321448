import useColorSetStore from 'store/colorSet';
import useMenuStore from 'store/menu';
import styles from './MenuBar.module.scss';
import AllButton from './all/AllButton';
import HomeButton from './main/HomeButton';
import MenuButtons from './menus/MenuButtons';

const MenuBar = () => {
  const colorSetStore = useColorSetStore();
  const menuStore = useMenuStore();

  return (
    <div className={styles.gnb} style={{ borderTop: `1px solid ${colorSetStore.text.title}` }}>
      <HomeButton menu={menuStore.mainMenus.filter(menu => menu.type === 'home')[0]} />
      <MenuButtons />
      <AllButton />
    </div>
  );
};
export default MenuBar;
