import { useRef } from 'react';
import usePopupStore from 'store/popup';
import useTimerStore from 'store/timer';
import { errorConsole } from 'util/common/console';

const useClipBoard = () => {
  // ref
  const shareToastTimerRef = useRef(null);

  // store
  const popupStore = usePopupStore();
  const timerStore = useTimerStore();

  // 클립보드 복사
  const copyUrlOnClipboard = async (url: string) => {
    try {
      await navigator.clipboard.writeText(url);
      // TODO: 다국어 추가
      // toast open
      popupStore.setToastPopupMessage('주소가 클립보드에 복사되었습니다.');
      popupStore.setOpenToastPopup(true);
      // toast 용 타이머 ref 설정
      timerStore.setToastPopupTimerRef(shareToastTimerRef);
    } catch (error) {
      popupStore.setOpenToastPopup(true);
      popupStore.setToastPopupMessage('브라우저가 이 기능을 지원하지 않습니다.');

      errorConsole('클립보드 복사 실패:', error);
    }
  };
  return { copyUrlOnClipboard };
};
export default useClipBoard;
