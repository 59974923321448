import { create } from 'zustand';

type BookmarkStoreProps = {
  bookmarkRefresh: boolean;
  setBookmarkRefresh: (bookmarkRefresh: boolean) => void;
};
const useBookmarkStore = create<BookmarkStoreProps>(set => ({
  bookmarkRefresh: false,
  setBookmarkRefresh(bookmarkRefresh: boolean) {
    set(() => ({ bookmarkRefresh }));
  },
}));

export default useBookmarkStore;
