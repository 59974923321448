import ButtonLoader from 'components/common/loader/button/ButtonLoader';
import SettingCurrentLocationButton, {
  LocationButton,
} from 'components/floor/location-bar/modal/button/SettingCurrentLocationButton';
import MapTrackingButton from 'components/popup/tenant/button/map-action/MapTrackingButton';
import { LOCAL_STORAGE_ORIGIN_LOCATION_KEY } from 'data/localStorageKey';
import useCheckValidRoute from 'hooks/map/check/useCheckValidRoute';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useShowStore from 'store/show';
import useTenantStore from 'store/tenant';
import useValidStore from 'store/valid';
import { PathType } from 'types/common/path.type';
import { StorageLocation } from 'types/origin/originLocation.type';
import MapLocationButton from '../../button/map-action/MapLocationButton';
import styles from './DetailPopupButtons.module.scss';

type DetailTenantPopupButtonsProps = {
  closeDetailPopup: () => void;
};

const DetailTenantPopupButtons = ({ closeDetailPopup }: DetailTenantPopupButtonsProps) => {
  // store
  const showStore = useShowStore();
  const tenantStore = useTenantStore();
  const validStore = useValidStore();

  // var
  const storageOriginLocation = localStorage.getItem(LOCAL_STORAGE_ORIGIN_LOCATION_KEY);
  const originLocation: StorageLocation = storageOriginLocation ? JSON.parse(storageOriginLocation) : '';
  const isCurrentLocation = originLocation?.id === tenantStore.currentTenant?.id;

  // hook
  const { checkValidRoute, routeLoading } = useCheckValidRoute();
  const location = useLocation();

  // 길안내 버튼 분기처리 (디테일 팝업 클릭한 경우)
  const handleShowNavi = async () => {
    if (tenantStore.currentTenant) {
      // 길안내 가능한지 체크
      checkValidRoute(tenantStore.currentTenant);

      // 현재위치 설정 안되어있다면 버튼 노출
      if (!localStorage.getItem(LOCAL_STORAGE_ORIGIN_LOCATION_KEY)) {
        showStore.setShowNavi(true);
        return;
      }

      // 현재위치라면 버튼 비노출
      if (isCurrentLocation) {
        return showStore.setShowNavi(false);
      }

      if (storageOriginLocation) {
        // 현재위치 아니고 object id 다르다면 버튼 노출
        if (originLocation.objectId !== tenantStore.currentTenant.content.objectId) {
          return showStore.setShowNavi(true);
        }
      }

      // 현재위치 아니고 object id 같다면 버튼 비노출
      return showStore.setShowNavi(false);
    }
  };

  useEffect(() => {
    handleShowNavi();
  }, [isCurrentLocation]);

  return (
    <div className={styles.wrapper}>
      {routeLoading ? (
        <ButtonLoader />
      ) : (
        <>
          {
            // ! 시연용 워크스페이스 분기처리
            !validStore.isPocWorkspace && !isCurrentLocation && (
              <SettingCurrentLocationButton
                buttonType={LocationButton.POPUP_SMALL}
                closeModal={() => {}}
                clickedTenant={tenantStore.currentTenant}
              />
            )
          }

          {/* 위치확인 버튼 - 층별안내 아닐 경우 노출 */}
          {location.pathname !== PathType.floor && <MapLocationButton closePopup={closeDetailPopup} />}

          {/* 길찾기 버튼 (Indoor Tracking) */}
          {showStore.showNavi && <MapTrackingButton closePopup={closeDetailPopup} />}
        </>
      )}
    </div>
  );
};
export default DetailTenantPopupButtons;
