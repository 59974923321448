import { create } from 'zustand';

type Store = {
  /**
   * poc 기능을 사용할 수 있는 워크스페이스인지 여부
   * true 라면 주차, 공유하기, 즐겨찾기 기능을 사용할 수 있다
   */
  isPocWorkspace: boolean;
  setIsPocWorkspace: (isPocWorkspace: boolean) => void;
};

const useValidStore = create<Store>((set, get) => ({
  /** poc 기능을 사용할 수 있는 워크스페이스인지 여부 */
  isPocWorkspace: false,
  setIsPocWorkspace(isPocWorkspace: boolean) {
    set(() => ({
      isPocWorkspace,
    }));
  },
}));

export default useValidStore;
