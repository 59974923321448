import CloseSvg from 'components/common/icon/close/CloseSvg';
import { useTranslation } from 'react-i18next';
import useColorSetStore from 'store/colorSet';
import styles from './SettingCurrentLocationModalHeader.module.scss';

type Props = {
  closeModal: () => void;
};

const SettingLocationModalHeader = ({ closeModal }: Props) => {
  const colorSetStore = useColorSetStore();

  const { t } = useTranslation();

  return (
    <div
      className={styles.location_header}
      style={{
        background: colorSetStore.header.bg,
      }}
    >
      <div
        className={styles.title}
        style={{
          color: colorSetStore.header.text,
        }}
      >
        {t(`popup.location.title`)}
      </div>

      <div onClick={closeModal}>
        <CloseSvg color={colorSetStore.header.text} />
      </div>
    </div>
  );
};
export default SettingLocationModalHeader;
