import ALERT from 'assets/icon/alert.svg';
import { useTranslation } from 'react-i18next';
import useColorSetStore from 'store/colorSet';
import styles from './NoTenant.module.scss';

const NoTenant = () => {
  const colorSetStore = useColorSetStore();

  const { t } = useTranslation();

  return (
    <div
      className={styles.container}
      style={{
        background: colorSetStore.list.bg,
      }}
    >
      <div className={styles.contents}>
        <div className={styles.alert_icon}>
          <img src={ALERT} alt='alert-icon' />
        </div>

        <span className={styles.text}>{t(`tenant.noTenant`)}</span>
      </div>
    </div>
  );
};
export default NoTenant;
