import React from 'react';
import LOADING from 'assets/loading/action-loading.gif';
import styles from './MapLoader.module.scss';

const MapLoader = ({ message }: { message: string }) => {
  return (
    <button type='button' disabled className={styles.action_loading}>
      <div className={styles.gif_wrapper}>
        <img src={LOADING} alt='loading' />
      </div>

      <span className={styles.loading_text}>{message}</span>
    </button>
  );
};
export default MapLoader;
