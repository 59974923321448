/* eslint-disable no-nested-ternary */
import DEFAULT_SCREEN from 'assets/default/default_horizontal.svg';
import MOBILE_LOADING from 'assets/default/mobile_loading.jpg';
import LoadingSpinner from 'components/common/loader/spinner/LoadingSpinner';
import { useRef, useState } from 'react';
import useScreensaverStore from 'store/screensaver';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Screensaver } from 'types/screensaver/screensaver.type';
import styles from './ScreensaverContainer.module.scss';
import ScreensaverImage from './image/ScreensaverImage';
import ScreensaverVideo from './video/ScreensaverVideo';

const ScreensaverContainer = () => {
  const [swiperState, setSwiperState] = useState<SwiperCore>();

  const screensaverStore = useScreensaverStore();

  const timer = useRef<NodeJS.Timeout | null>(null);

  const goNextSlide = () => swiperState?.slideNext(1500);

  const goNextSlideAfterSeconds = () => {
    timer.current = setTimeout(() => {
      goNextSlide();
    }, 8000);
  };

  const isDefault = screensaverStore.succeededMedia.length === 0;
  const isLoading = !screensaverStore.screensaverStatus;

  return (
    <section className={styles.screensaver}>
      {isLoading ? (
        <div>
          <LoadingSpinner />
          <img className={styles.cover} src={MOBILE_LOADING} alt='loading' />
        </div>
      ) : isDefault ? (
        <img className={styles.cover} src={DEFAULT_SCREEN} alt='default' />
      ) : (
        <Swiper
          style={{ height: '100%' }}
          loop
          modules={[Autoplay, Navigation]}
          onInit={swiper => setSwiperState(swiper)}
          onSlideChange={() => {
            if (timer.current) {
              clearTimeout(timer.current);
            }
          }}
        >
          {screensaverStore.succeededMedia
            .sort((a: Screensaver, b: Screensaver) => a.sortOrder - b.sortOrder)
            .map(({ fileUri, id, fileType }: Screensaver) => {
              return (
                <SwiperSlide key={id}>
                  {({ isActive }) =>
                    fileType === 'IMAGE' ? (
                      <ScreensaverImage
                        key={id}
                        imageUrl={fileUri}
                        isActive={isActive}
                        goNextSlide={goNextSlideAfterSeconds}
                      />
                    ) : (
                      <ScreensaverVideo key={id} videoUrl={fileUri} isActive={isActive} goNextSlide={goNextSlide} />
                    )
                  }
                </SwiperSlide>
              );
            })}
        </Swiper>
      )}
    </section>
  );
};
export default ScreensaverContainer;
