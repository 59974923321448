import DisplayBadge from 'components/custom-board/common/badge/DisplayBadge';
import DisplayDate from 'components/custom-board/common/date/DisplayDate';
import { DEFAULT_IMAGE } from 'data/defaultImages';
import useColorSetStore from 'store/colorSet';
import useLanguageStore from 'store/languages';
import { CustomBoardContent } from 'types/custom-board/customboard.type';
import styles from './CardCustomBoardItem.module.scss';

type Props = {
  board: CustomBoardContent;
};

const CardCustomBoardItem = ({ board }: Props) => {
  const languageStore = useLanguageStore();
  const colorSetStore = useColorSetStore();

  return (
    <div className={styles.board_wrapper}>
      <div className={styles.title_wrapper}>
        <div className={styles.title_and_badge}>
          {/* 배지 */}
          <DisplayBadge displayStatus={board.displayStatus} />

          {/* 타이틀 */}
          <div
            className={styles.title}
            style={{
              color: colorSetStore.sub,
            }}
          >
            {board.title[languageStore.currentLang] || '-'}
          </div>
        </div>

        {/* 노출 기간 */}
        <DisplayDate board={board} />
      </div>

      {/* 이미지 */}
      <div className={styles.board_image} key={board.id}>
        <img
          src={board.contentImageUri || DEFAULT_IMAGE}
          alt={board.title.ko || ''}
          onError={e => {
            const element = e.target;
            if (element instanceof HTMLImageElement) {
              element.src = DEFAULT_IMAGE;
            }
          }}
        />
      </div>
    </div>
  );
};
export default CardCustomBoardItem;
