import CloseIsland from 'components/common/button/island/CloseIsland';
import { LOCAL_STORAGE_ORIGIN_LOCATION_KEY } from 'data/localStorageKey';
import useStats from 'hooks/api/stats/useStats';
import { Fragment, useEffect, useState } from 'react';
import useMachineStore from 'store/machine';
import useShowStore from 'store/show';
import useTenantStore from 'store/tenant';
import { PopupType } from 'types/common/popup.type';
import { StorageLocation } from 'types/origin/originLocation.type';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import styles from './PopupTenantList.module.scss';
import CurrentTenantItem from './item/CurrentTenantItem';

type Props = {
  closeTenantList: () => void;
  openModal: (currentModalType: PopupType) => void;
};
// 층별안내의 다중 컨텐츠 목록
const PopupTenantList = ({ closeTenantList, openModal }: Props) => {
  const machineStore = useMachineStore();
  const tenantStore = useTenantStore();
  const showStore = useShowStore();

  const [isShowDim, setShowDim] = useState(false);

  const { postTenantUsage } = useStats();

  // 심플 팝업의 테넌트 리스트에서 테넌트 클릭했을 경우
  const compareObjectId = (currentObjectId: string) => {
    const storageOriginLocation = localStorage.getItem(LOCAL_STORAGE_ORIGIN_LOCATION_KEY);

    // 설정된 현재위치 없을 경우
    if (!localStorage.getItem(LOCAL_STORAGE_ORIGIN_LOCATION_KEY)) {
      showStore.setDiffObjectId(true);
      return;
    }

    if (storageOriginLocation) {
      const originLocation: StorageLocation = JSON.parse(storageOriginLocation);

      // 출발지 objectId 랑 현재 objectId 같을 경우
      if (originLocation.objectId === currentObjectId) {
        showStore.setDiffObjectId(false);
      } else {
        // 다를 경우
        showStore.setDiffObjectId(true);
      }
    }
  };

  const onClickItem = async (tenant: TenantWithPointId) => {
    if (!machineStore.machine) {
      return;
    }

    // 매장 사용량 수집
    await postTenantUsage(machineStore.machine.point.id, tenant.id, 'DETAIL_OF_FLOOR', machineStore.machine.id); // 층별안내

    tenantStore.setCurrentTenant(tenant);
    compareObjectId(tenant.content.objectId);
    openModal(tenant.popupType);
    closeTenantList();
  };

  const handleShowDim = (isShow: boolean) => {
    setShowDim(isShow);
  };

  useEffect(() => {
    return () => {
      showStore.setOpenTenantList(false);
    };
  }, []);

  return (
    <div
      className={styles.background}
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        if (e.target !== e.currentTarget) return;
        closeTenantList();
      }}
    >
      {/* <OutsideClickHandler onOutsideClick={closeTenantList}> */}
      <div className={styles.wrapper}>
        {/* 전체보기 on */}
        <CloseIsland clickIsland={closeTenantList} isOpen />

        {/* 테넌트 리스트 */}
        <div className={styles.list}>
          {tenantStore.currentTenantArr.map((tenant, index) => {
            return (
              <Fragment key={tenant.id}>
                <CurrentTenantItem
                  onClick={onClickItem}
                  tenant={tenant}
                  isActive={tenant.id === tenantStore.currentTenant?.id}
                  isLast={index === tenantStore.currentTenantArr.length - 1}
                  handleShowDim={handleShowDim}
                />
              </Fragment>
            );
          })}

          {/* {isShowDim && <div className={styles.dim} />} */}
        </div>
      </div>
      {/* </OutsideClickHandler> */}
    </div>
  );
};
export default PopupTenantList;
