/* eslint-disable no-console */
import { LOCAL_STORAGE_BOOKMARK_KEY, LOCAL_STORAGE_PARKING_LOCATION_KEY } from 'data/localStorageKey';
import useCategoryGroup from 'hooks/api/category-group/useCategoryGroup';
import useLanguage from 'hooks/api/language/useLanguage';
import useMainMenu from 'hooks/api/menu/main/useMainMenu';
import useScreensaver from 'hooks/api/screensaver/useScreensaver';
import MapDraw from 'map/MapDraw';
import { getFloorDataBeforeGetObject } from 'map/control/floor';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useCommonStore from 'store/common';
import useLanguageStore from 'store/languages';
import useLoadingStore from 'store/loading';
import useMapStore from 'store/map';
import useValidStore from 'store/valid';
import { LangCode, Language } from 'types/language/language.type';
import { Machine } from 'types/machine/machine.type';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import { errorConsole, logConsole } from 'util/common/console';
import { getTimeStamp } from 'util/date-time/timeStamp';
import { getBrowserLanguage } from 'util/multi-lang/getBrowserLanguage';
import { extractParams } from 'util/params/join/extract/extractParams';
import { validPocWorkspace } from 'util/poc/validPocWorkspace';
import useMachine from '../machine/useMachine';
import usePoint from '../point/usePoint';
import useStats from '../stats/useStats';

const useFetchApis = () => {
  // hook
  const { getMachine, handleMachineData } = useMachine();
  const { getLanguages } = useLanguage();
  const { getScreensavers } = useScreensaver();
  const { getCategoryGroupsWithTenant } = useCategoryGroup();
  const { getMainMenu } = useMainMenu();
  const { getPoints } = usePoint();
  const { postUsages } = useStats();
  const location = useLocation();

  const TIME_STAMP_FORMAT = 'mm:ss:SSS';

  // store
  const loadingStore = useLoadingStore();
  const languageStore = useLanguageStore();
  const mapStore = useMapStore();
  const validStore = useValidStore();
  const commonStore = useCommonStore();

  // var
  const pointIdParam: string | undefined = extractParams(location.search, 'pointId');
  const machineIdParam: string | undefined = extractParams(location.search, 'machineId');
  const storageParkingLocation = localStorage.getItem(LOCAL_STORAGE_PARKING_LOCATION_KEY);
  const storageBookmarks = localStorage.getItem(LOCAL_STORAGE_BOOKMARK_KEY);

  /**
   * 저장된 localStorage 의 workspace와 machine의 workspace 가 다를 경우 저장된 localStorage를 모두 clear 한다
   => clear 되는 값은 현재위치, 주차위치, 즐겨찾기 값임 (2024/06/28 기준)
   
   * TODO: 전시 솔루션 들어가면 워크스페이스가 달라도 주차위치는 유지해야 할 수도 있기 때문에 이 부분 수정해야 함
   * @param machineWorkspaceId 기기의 workspace 아이디
   */
  const removeAllStorageItems = async (machineWorkspaceId: string, comparePointId: string) => {
    const machine = await getMachine(comparePointId);

    if (machine) {
      const storageWorkspaceId = machine.workspace.id;

      if (machineWorkspaceId !== storageWorkspaceId) {
        localStorage.clear();
      }
    }
  };

  // 브라우저 언어를 현재 언어로 설정
  const setBrowserLangToCurrentLang = (mainLang: LangCode, langs: Language[]) => {
    const browserLang = getBrowserLanguage();

    // 브라우저 언어가 5가지 언어가 아닐 경우 메인 언어 반환
    if (!browserLang) {
      languageStore.setCurrentLang(mainLang);
      return mainLang;
    }

    // 브라우저 언어가 노출 언어와 하나도 맞지 않으면 메인 언어 반환
    const hasBrowserLangInLangs: boolean = langs.some(lang => lang.code === browserLang);

    if (!hasBrowserLangInLangs) {
      languageStore.setCurrentLang(mainLang);
      return mainLang;
    }

    // default 는 브라우저 언어
    languageStore.setCurrentLang(browserLang);
    return browserLang;
  };

  // TODO: 리팩토링 완료 후 제거
  // const handleQrType = async () => {
  //   // 나머지 QR
  //   if (!queryParamStore.path) {
  //     return;
  //   }

  //   // 테넌트 QR
  //   if (queryParamStore.path === 'tenant') {
  //     // 매장 사용량 수집
  //     await postTenantUsage(queryParamStore.pointId, queryParamStore.tenantId, 'LOCATION', queryParamStore.machineId);

  //     navigate(`${PathType.floor}${queryParams}`);
  //     return;
  //   }

  //   // 주차위치 저장, 주차위치 길찾기, 내 주차위치 확인 QR
  //   if (
  //     queryParamStore.path === 'parking' ||
  //     queryParamStore.path === 'parkingNavi' ||
  //     queryParamStore.path === 'myParking'
  //   ) {
  //     navigate(`${PathType.floor}${queryParams}`);
  //   }
  // };

  /**
   * @param mainLang - 다국어 변환 시 기준이 되는 메인 언어 (어드민에서 설정)
   * @param initLang - 처음에 기본으로 보여줄 언어 (브라우저에서 설정)
   * @param workspaceId
   */
  const fetchAllOnce = async (mainLang: LangCode, initLang: LangCode, workspaceId: string, pointId: string) => {
    const result = await Promise.all([
      getPoints(mainLang, workspaceId, pointId),
      getScreensavers(pointId),
      getCategoryGroupsWithTenant(mainLang, initLang, workspaceId, pointId),
      getMainMenu(mainLang, pointId),
    ]);

    if (result) {
      logConsole(`> promise all fetch end: ${getTimeStamp(TIME_STAMP_FORMAT)}`);
    }
  };

  const fetchAllApis = async () => {
    if (!pointIdParam) {
      return;
    }

    // 1. machine 조회
    const machine: Machine | undefined = await getMachine(pointIdParam, machineIdParam);

    if (machine) {
      // ! 시연용 워크스페이스 분기처리
      if (validPocWorkspace(machine.workspace.id)) {
        // 사용 가능
        validStore.setIsPocWorkspace(true);

        // ! 워크스페이스가 다를 경우 저장된 localStorage 값 모두 비워주기
        const myParkingLocation: TenantWithPointId = storageParkingLocation
          ? JSON.parse(storageParkingLocation || '')
          : '';
        const bookmarks: TenantWithPointId[] = storageBookmarks ? JSON.parse(storageBookmarks || '[]') : [];

        if (myParkingLocation || bookmarks.length > 0) {
          await removeAllStorageItems(machine.workspace.id, myParkingLocation.pointId || bookmarks[0].pointId);
        }
      } else {
        // ! poc 기능을 사용하지 않는다면 주차, 즐겨찾기 localStorage 비워준다
        localStorage.removeItem(LOCAL_STORAGE_PARKING_LOCATION_KEY);
        localStorage.removeItem(LOCAL_STORAGE_BOOKMARK_KEY);
      }

      // 2. language 조회
      const { mainLanguage, langs } = await getLanguages(machine.workspace.id);

      // 처음 보여줄 언어 설정
      const initLang: LangCode = setBrowserLangToCurrentLang(mainLanguage, langs);

      // 3. 서버 api 조회
      await fetchAllOnce(mainLanguage, initLang, machine.workspace.id, machine.point.id);

      // 4. machine 데이터 다국어 변환, 기기 관련 정보 설정, 현재위치 설정
      handleMachineData(machine, mainLanguage);

      logConsole(`> internal server api fetch 완료: ${getTimeStamp(TIME_STAMP_FORMAT)}`);

      // QR로 진입 시 타입에 따라 페이지 이동 처리
      // handleQrType();

      try {
        // 4. dabeeoMaps fetch
        const mapData = await MapDraw.getInstance().getMapData({
          mapId: machine.map.id,
          serverType: 'SERVER_STUDIO4',
          clientId: machine.map.temp1,
          clientSecret: machine.map.temp2,
          version: machine.map.version,
        });

        if (mapData) {
          MapDraw.mapData = mapData;

          /**
           * ! 제거 불가능
           * -> 한번에 모든 층정보 가져오는 방법으로 속도 개선해야 함.
           * await mapData.getAllFloorData(floorIds?:string[])
           * https://api-doc.dabeeomaps.com/classes/DabeeoMapData.DabeeoMapData.html#getAllFloorData
           */
          await getFloorDataBeforeGetObject(mapData);

          loadingStore.setHasMapData(true);
        }
      } catch (error) {
        errorConsole(`dabeeoMaps getMapData error: ${error}`);
      } finally {
        // initial loading 관리
        loadingStore.setInitialLoading(false);
        logConsole(`> 초기 로딩 종료: ${getTimeStamp(TIME_STAMP_FORMAT)}`);
      }
    }
  };

  // map sdk를 불러오는 스크립트가 load된 이후에 api 통신 시작
  useEffect(() => {
    if (mapStore.loadMapScript) {
      if (pointIdParam) {
        fetchAllApis();
      }
    }
  }, [mapStore.loadMapScript, pointIdParam, machineIdParam]);

  useEffect(() => {
    logConsole(`> internal server api fetch 시작: ${getTimeStamp(TIME_STAMP_FORMAT)}`);
  }, []);

  return {};
};
export default useFetchApis;
