import { create } from 'zustand';

type ModalStore = {
  // 테넌트 목록 모달
  openTenantListModal: boolean;
  setOpenTenantListModal: (openTenantListModal: boolean) => void;

  // 내 주차 위치 지정 모달
  openParkingModal: boolean;
  setOpenParkingModal: (openParkingModal: boolean) => void;

  // 주차 위치 확인 모달
  openMyParkingModal: boolean;
  setOpenMyParkingModal: (openMyParkingModal: boolean) => void;

  // 주차 위치로 길찾기 모달
  openParkingNaviModal: boolean;
  setOpenParkingNaviModal: (openParkingNaviModal: boolean) => void;

  // 공유하기 모달
  openShareModal: boolean;
  setOpenShareModal: (openShareModal: boolean) => void;

  // 길찾기 시작 모달
  openNaviModal: boolean;
  setOpenNaviModal: (openNaviModal: boolean) => void;

  closeAllModal: () => void;
};

const useModalStore = create<ModalStore>((set, get) => ({
  openTenantListModal: false,
  setOpenTenantListModal(openTenantListModal: boolean) {
    set({
      openTenantListModal,
    });
  },

  openParkingModal: false,
  setOpenParkingModal(openParkingModal: boolean) {
    set({
      openParkingModal,
    });
  },

  openMyParkingModal: false,
  setOpenMyParkingModal(openMyParkingModal: boolean) {
    set({
      openMyParkingModal,
    });
  },

  openParkingNaviModal: false,
  setOpenParkingNaviModal(openParkingNaviModal: boolean) {
    set({
      openParkingNaviModal,
    });
  },

  openShareModal: false,
  setOpenShareModal(openShareModal: boolean) {
    set({
      openShareModal,
    });
  },

  openNaviModal: false,
  setOpenNaviModal(openNaviModal: boolean) {
    set({
      openNaviModal,
    });
  },

  closeAllModal() {
    set({
      openTenantListModal: false,
      openParkingModal: false,
      openMyParkingModal: false,
      openParkingNaviModal: false,
      openShareModal: false,
      openNaviModal: false,
    });
  },
}));

export default useModalStore;
