import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import ko from 'i18n/locales/ko.json';
import en from 'i18n/locales/en.json';
import ja from 'i18n/locales/ja.json';
import cn from 'i18n/locales/cn.json';
import tw from 'i18n/locales/tw.json';
import { LangCode } from 'types/language/language.type';

const resources: Resource = {
  ko,
  en,
  ja,
  cn,
  tw,
};

i18n.use(initReactI18next).init({
  resources,
  lng: LangCode.ko,
  fallbackLng: LangCode.en,

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
