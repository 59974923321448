export enum PathType {
  root = '/',
  main = '/main',
  floor = '/floor',
  tenant = '/tenant',
  more = '/more',
  error = '/error',
  customBoard = '/custom_board',
  bookmark = '/bookmark',
  test = '/test',
}
