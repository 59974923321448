import GoUpButton from 'components/common/button/go-up/GoUpButton';
import useGoTopButton from 'hooks/go-to-top/useGoTopButton';
import { Fragment, useRef } from 'react';
import useCustomBoardStore from 'store/customBoard';
import styles from './CardCustomBoardContainer.module.scss';
import CardCustomBoardItem from './item/CardCustomBoardItem';

const CardCustomBoardContainer = () => {
  const customBoardStore = useCustomBoardStore();

  const scrollRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  const { showGoButton } = useGoTopButton({ listRef });

  return (
    <div ref={listRef} className={styles.container}>
      <div ref={scrollRef} style={{ visibility: 'hidden' }} />

      <div className={styles.board_wrapper}>
        {customBoardStore.currentCustomBoards.map(board => {
          return (
            <Fragment key={board.id}>
              <CardCustomBoardItem board={board} />
            </Fragment>
          );
        })}
      </div>

      <GoUpButton scrollRef={scrollRef} isShow={showGoButton} />
    </div>
  );
};
export default CardCustomBoardContainer;
