import { LOCAL_STORAGE_MOTION_PERMISSION_KEY, LOCAL_STORAGE_ORIGIN_LOCATION_KEY } from 'data/localStorageKey';
import useFloor from 'hooks/api/floor/useFloor';
import useMapDirection from 'hooks/map/trigger/useMapDirection';
import { clearMap, clearMyLocation } from 'map/control/common/clear';
import { useEffect, useMemo, useRef } from 'react';
import useLanguageStore from 'store/languages';
import useModalStore from 'store/modal';
import useNaviStore from 'store/navi';
import usePopupStore from 'store/popup';
import useTenantStore from 'store/tenant';
import useTimerStore from 'store/timer';
import useTrackingStore from 'store/tracking';
import { PermissionType } from 'types/common/permission.type';
import { StorageLocation } from 'types/origin/originLocation.type';
import ModalBottomSheetLayout from '../layout/bottom-sheet/ModalBottomSheetLayout';
import MapNavigationButton from '../tenant/button/map-action/MapNavigationButton';
import styles from './NaviPopup.module.scss';

const NaviPopup = () => {
  const modalStore = useModalStore();
  const tenantStore = useTenantStore();
  const langStore = useLanguageStore();
  const naviStore = useNaviStore();
  const popupStore = usePopupStore();
  const timerStore = useTimerStore();
  const trackingStore = useTrackingStore();

  const { findFloorName } = useFloor();
  const { switchActionByTrackingType } = useMapDirection();

  // var
  // 현재위치
  const storageOriginLocation = localStorage.getItem(LOCAL_STORAGE_ORIGIN_LOCATION_KEY);
  const originLocation: StorageLocation = storageOriginLocation ? JSON.parse(storageOriginLocation) : '';
  // 모션 동의 여부
  const localStorageMotionPermission = localStorage.getItem(LOCAL_STORAGE_MOTION_PERMISSION_KEY);
  const isMotionPermissionGranted =
    localStorageMotionPermission && (localStorageMotionPermission as PermissionType) === 'granted';

  const originFloor = useMemo(() => {
    const floorName = findFloorName(originLocation.floorId, originLocation.pointId);
    return floorName === 'outdoor' ? '' : floorName;
  }, []);

  const destinationFloor = useMemo(() => {
    const floorName = findFloorName(
      tenantStore.currentTenant?.content.floorId || '',
      tenantStore.currentTenant?.pointId || '',
    );
    return floorName === 'outdoor' ? '' : floorName;
  }, []);

  // ref
  const parkingToastTimerRef = useRef<NodeJS.Timer | null>(null);

  const openToastPopup = () => {
    // toast open
    popupStore.setOpenToastPopup(true);
    popupStore.setToastPopupMessage(`경로 진행 방향을 바라봐주세요`);

    // toast 용 타이머 ref 설정
    timerStore.setToastPopupTimerRef(parkingToastTimerRef);
  };

  useEffect(() => {
    if (isMotionPermissionGranted) {
      openToastPopup();
    }
  }, []);

  return (
    <ModalBottomSheetLayout
      isOpenPopup={modalStore.openNaviModal}
      onClosePopup={async () => {
        clearMap();
        clearMyLocation();

        await switchActionByTrackingType(trackingStore.trackingType);

        modalStore.setOpenNaviModal(false);
        naviStore.clearNaviRoutes();
      }}
      dim={false}
    >
      <div className={styles.popup}>
        <div className={styles.popup_inner}>
          {/* 안내 문구 */}
          {/* <div className={`${styles.info}`}>경로가 시작되는 방향을 바라봐주세요</div> */}

          {/* 출/도착지 */}
          <div className={styles.origin_and_destination}>
            {/* 출발 */}
            <div className={styles.place}>
              <div className={styles.title}>출발</div>
              <div className={styles.floor_and_name}>
                {/* 층 */}
                {/* {originFloor && <div className={styles.floor}>{originFloor}</div>} */}
                {/* 이름 */}
                <div className={styles.name}>{originLocation.name[langStore.currentLang]}</div>
              </div>
            </div>

            {/* 화살표 */}
            <div className={styles.arrow}>
              <svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
                <rect x='2' y='2.5' width='19' height='19' rx='9.5' fill='#CCCCCC' />
                <path d='M10.4039 8.88037L14.0577 12.0085L10.4039 14.9233' stroke='white' strokeLinecap='square' />
              </svg>
            </div>

            {/* 도착 */}
            <div className={styles.place}>
              <div className={styles.title}>도착</div>
              <div className={styles.floor_and_name}>
                {/* 층 */}
                {/* {destinationFloor && <div className={styles.floor}>{destinationFloor}</div>} */}

                {/* 이름 */}
                <div className={styles.name}>{tenantStore.currentTenant?.name[langStore.currentLang]}</div>
              </div>
            </div>
          </div>
        </div>
        {/* 길찾기 시작 버튼 (모의보행) */}
        <div className={styles.button_wrapper}>
          <MapNavigationButton
            // TODO: 다국어 적용
            buttonText='길찾기 시작'
            closePopup={() => {
              modalStore.setOpenNaviModal(false);
            }}
          />
        </div>
      </div>
    </ModalBottomSheetLayout>
  );
};
export default NaviPopup;
