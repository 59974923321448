/* eslint-disable react/jsx-no-useless-fragment */
import GO_UP from 'assets/icon/go-up.svg';
import { RefObject } from 'react';
import styles from './GoUpButton.module.scss';

type GoUpButtonProps = {
  scrollRef: RefObject<HTMLDivElement>;
  isShow: boolean;
  overButton?: boolean;
};

const GoUpButton = ({ scrollRef, isShow, overButton }: GoUpButtonProps) => {
  const handleGoUp = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      {isShow && (
        <button
          onClick={handleGoUp}
          className={`${styles.button} ${isShow && styles.show} ${overButton ? styles.over_btn : styles.no_btn}`}
          type='button'
        >
          <img src={GO_UP} alt='위로가기' />
        </button>
      )}
    </>
  );
};
export default GoUpButton;
