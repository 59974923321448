import { useAxios } from 'api/axios';
import { EndPoint } from 'data/endpoint';
import useTenantStore from 'store/tenant';
import { ApiResponse } from 'types/api/response';
import { LangCode } from 'types/language/language.type';
import { CustomFields, Tenant, TenantCategoryGroup, TenantWithPointId, TenantsOfPoint } from 'types/tenant/tenant.type';
import { errorConsole } from 'util/common/console';
import { convertMultiLang } from 'util/multi-lang/convertMultiLang';

const useTenant = () => {
  const tenantStore = useTenantStore();

  const { api } = useAxios();

  // 정제한 tenants 를 poiId 별로 mapping
  const setPoiTenantsMap = (tenantsWithPointId: TenantWithPointId[]) => {
    const pointTenantsMap = new Map<string, TenantWithPointId[]>();

    tenantsWithPointId.forEach(tenant => {
      // poiId 있는지 없는지 check
      const findTenant: TenantWithPointId[] | undefined = pointTenantsMap.get(tenant.content.poiId);

      // 같은 poiId 없을 경우 set
      if (!findTenant) {
        pointTenantsMap.set(tenant.content.poiId, [tenant]);
      } else {
        // 같은 poiId 있을 경우 찾은 value 에 add
        findTenant.push(tenant);
      }
    });

    tenantStore.setPoiTenantsMap(pointTenantsMap);
  };

  // tenant fetch
  const getRawTenants = async (mainLang: LangCode, workspaceId: string) => {
    try {
      const response = await api.get<ApiResponse<TenantsOfPoint[]>>(EndPoint.tenants, {
        params: {
          workspaceId,
        },
      });

      if (response.data) {
        // convert 된 tenant 를 flat 하게 펴서 convertedTenant 목록으로 만든다.
        const convertedTenants: TenantWithPointId[] = [];

        // point 별 tenant list Map
        // const tenantsMap = new Map<string, TenantWithPointId[]>();

        response.data.forEach(tenantsOfPoint => {
          const usedTenants: Tenant[] = tenantsOfPoint.tenants.filter(tenant => tenant.used);

          usedTenants.forEach((rawTenant: Tenant) => {
            const categoryGroups: TenantCategoryGroup[] = rawTenant.categoryGroups.map(group => {
              return {
                ...group,
                name: convertMultiLang(group.name, mainLang),
              };
            });

            const tenantCustomFields: CustomFields[] = rawTenant.tenantCustomFields.map(customField => {
              return {
                ...customField,
                title: convertMultiLang(customField.title, mainLang),
                content: convertMultiLang(customField.content, mainLang),
              };
            });

            const tenant: TenantWithPointId = {
              ...rawTenant,
              name: convertMultiLang(rawTenant.name, mainLang),
              description: convertMultiLang(rawTenant.description, mainLang),
              categoryGroups,
              tenantCustomFields,
              pointId: tenantsOfPoint.pointId,
            };

            convertedTenants.push(tenant);
          });
        });

        tenantStore.setRawTenants(convertedTenants);

        setPoiTenantsMap(convertedTenants);

        return convertedTenants;
      }
    } catch {
      errorConsole('TENANTS를 불러올 수 없습니다.');
    }
  };

  return { getRawTenants };
};
export default useTenant;
