import { useAxios } from 'api/axios';
import { EndPoint } from 'data/endpoint';
import useCustomBoardStore from 'store/customBoard';
import useMenuStore from 'store/menu';
import { ApiResponse } from 'types/api/response';
import { MainMenu } from 'types/common/menu.type';
import { CustomPage } from 'types/custom-board/customboard.type';
import { LangCode } from 'types/language/language.type';
import { convertMultiLang } from 'util/multi-lang/convertMultiLang';

const useMainMenu = () => {
  const menuStore = useMenuStore();
  const customBoardStore = useCustomBoardStore();

  const { api } = useAxios();

  const getMainMenu = async (mainLang: LangCode, pointId: string) => {
    const response = await api.get<ApiResponse<MainMenu[]>>(EndPoint.mainMenuV2, {
      params: {
        pointId,
      },
    });

    if (response.data) {
      const convertedMenus = response.data.map(menu => {
        const mainMenu = { ...menu };
        mainMenu.name = convertMultiLang(menu.name, mainLang);
        return mainMenu;
      });

      const copyConvertedMenus = [...convertedMenus];
      menuStore.setMenus(copyConvertedMenus);

      const customPageMap = new Map<string, CustomPage>();

      convertedMenus.forEach(async menu => {
        if (menu.customPageId) {
          await api
            .get<ApiResponse<CustomPage>>(EndPoint.customPage.replace(':customPageId', menu.customPageId))
            .then(boardResponse => {
              if (menu.customPageId) {
                const convertedCustomBoards = boardResponse.data.contents.map(board => {
                  const customBoard = { ...board };
                  customBoard.title = convertMultiLang(board.title, mainLang);

                  return customBoard;
                });

                customPageMap.set(menu.customPageId, {
                  ...boardResponse.data,
                  contents: convertedCustomBoards,
                });
                customBoardStore.setCustomPages(customPageMap);
              }
            });
        }
      });
    }
  };
  return { getMainMenu };
};
export default useMainMenu;
