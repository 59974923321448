import useColorSetStore from 'store/colorSet';
import useLanguageStore from 'store/languages';
import { CustomFields } from 'types/tenant/tenant.type';
import styles from './DetailTenantCustomField.module.scss';

type Props = {
  customFields: CustomFields[];
};

const DetailTenantCustomFields = ({ customFields }: Props) => {
  const langStore = useLanguageStore();
  const colorSetStore = useColorSetStore();

  return (
    <div className={styles.details_wrapper}>
      {customFields.map((field, index) => (
        <div key={index} className={styles.detail_wrapper}>
          <div
            className={styles.detail_key}
            style={{
              color: colorSetStore.text.item,
            }}
          >
            <span>{field.title[langStore.currentLang]}</span>
          </div>
          <div
            className={styles.detail_value}
            style={{
              color: colorSetStore.text.title,
            }}
          >
            {field.content[langStore.currentLang].includes('http') ? (
              <a target='_blank' href={field.content[langStore.currentLang]} rel='noreferrer'>
                {field.content[langStore.currentLang]}
              </a>
            ) : (
              field.content[langStore.currentLang] || '-'
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
export default DetailTenantCustomFields;
