import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useFloorStore from 'store/floor';
import styles from './NoCurrentFloorFacilityPopup.module.scss';

type NoCurrentFloorFacilityPopupProps = {
  facilityName: string;
  isOpen: boolean;
  clearPopupTimer: () => void;
};

const NoCurrentFloorFacilityPopup = ({
  facilityName: facility,
  isOpen,
  clearPopupTimer,
}: NoCurrentFloorFacilityPopupProps) => {
  const { t } = useTranslation();

  const floorStore = useFloorStore();

  useEffect(() => {
    return () => {
      // 컴포넌트 언마운트 시 타이머 제거
      clearPopupTimer();
    };
  }, []);

  return (
    <div className={`${styles.container} ${isOpen ? styles.open : styles.close}`}>
      <div className={styles.text}>{t(`floor.noFacility`, { floor: floorStore.floorName, facility })}</div>
    </div>
  );
};
export default NoCurrentFloorFacilityPopup;
