import React from 'react';
import styles from './ButtonLoader.module.scss';

const ButtonLoader = () => {
  return (
    <div className={styles.loader_wrapper}>
      <div className={styles.loader} />
    </div>
  );
};
export default ButtonLoader;
