import { useAxios } from 'api/axios';
import { EndPoint } from 'data/endpoint';
import useCommonStore from 'store/common';
import { TenantTrackingType } from 'types/common/statistics.type';
import { errorConsole } from 'util/common/console';

// 통계를 위한 api 호출을 관리하는 hook
const useStats = () => {
  // hook
  const { api } = useAxios();

  // store
  const commonStore = useCommonStore();

  /**
   * 기기 사용량 수집
   * -> 처음 켜질 때, 홈으로 돌아올 때
   */
  const postUsages = async (pointId: string, machineId?: string) => {
    if (!commonStore.appUuid) {
      return;
    }

    try {
      await api.post(EndPoint.usagesV2, {
        uuid: commonStore.appUuid,
        pointId,
        machineId,
      });
    } catch (error) {
      errorConsole(`기기 사용량 수집 에러:`, error);
    }
  };

  /**
   * 매장 사용량 수집
   * -> 길찾기 QR로 진입 시, 매장 상세 진입(층별안내/매장검색), 길찾기, 위치확인
   */
  const postTenantUsage = async (
    pointId: string,
    tenantId: string,
    trackingType: TenantTrackingType,
    machineId?: string,
  ) => {
    try {
      await api.post(EndPoint.tenantsUsage, {
        pointId,
        tenantId,
        trackingType,
        machineId,
      });
    } catch (error) {
      errorConsole(`매장 사용량 수집 에러:`, error);
    }
  };

  /**
   * 카테고리 사용량 수집
   * -> 카테고리 클릭(층별안내)
   */
  const postCategoryUsage = async (categoryGroupId: string, pointId: string, machineId?: string) => {
    try {
      await api.post(EndPoint.categoryGroupsUsage, {
        categoryGroupId,
        pointId,
        machineId,
      });
    } catch (error) {
      errorConsole(`카테고리 사용량 수집 에러:`, error);
    }
  };

  /**
   * 기기 모션 접근 허용 여부
   */
  const postDeviceMotionAttached = async (isGranted: boolean) => {
    /**
     * uuid가 없거나 이미 attached 여부에 대해 보냈다면 두번은 보내지 않는다
     */
    if (!commonStore.appUuid || commonStore.sendGranted) {
      return;
    }

    try {
      await api.post(EndPoint.deviceMotionAttachedV2, {
        uuid: commonStore.appUuid,
        attached: isGranted,
      });

      commonStore.setSendGranted(true);
    } catch (error) {
      errorConsole(`기기 모션 접근 허용 여부 api post 에러:`, error);
    }
  };

  return { postUsages, postTenantUsage, postCategoryUsage, postDeviceMotionAttached };
};
export default useStats;
