import MapDraw from 'map/MapDraw';
import { IndoorTrackingOption } from 'types/map/tracking.type';
import { validMap } from './common/valid';
import { getCurrentFloor } from './floor';

/**
 * 화살표 GPS 를 표출한다
 * @param map
 */
export async function myTrackingLocationSet(iconUrl: string) {
  validMap();

  if (MapDraw.machineFloorId === getCurrentFloor().id) {
    const arrowLocationOption = {
      x: MapDraw.machineCoordinate.x,
      y: MapDraw.machineCoordinate.y,
      iconOption: {
        iconUrl,
        width: 60,
        height: 60,
      },
      animate: {
        color: '#0000ff',
        opacity: 0.2,
        desireScale: 2,
        duration: 1500,
      },
      gpsOption: {
        leftCourseDistance: 3, // 경로이탈 여부 (ON_PATH 와 OUT_OF_PATH) 를 판단하는 기준 거리
        willArriveRadius: 4, // 도착 예정 범위 반경
        arrivedRadius: 1, // 도착 범위 반경
      },
    };
    await MapDraw.map.mylocation.set(arrowLocationOption); // myLocation 생성
  }
}

/**
 * indoor service 를 시작한다
 * @param map
 * @param originPosition
 * @returns
 */
export async function startIndoorTracking(trackingOption: IndoorTrackingOption): Promise<boolean> {
  validMap();

  /**
   * Indoor Tracking 을 시작합니다. option으로 아래와 같이 설정해줍니다.
   * 현재 위치의 좌표와 각도(지도 기준 각도 북:0, 서: 90, 남: 180, 동: 270)
   * position: IPosition; orient: number;
   *
   * https://api-doc.dabeeomaps.com/classes/mapContext_MyLocation.Mylocation.html#indoorStart
   */
  const indoorResult = await MapDraw.map.mylocation.indoorStart(trackingOption); // indoor location service 시작

  return indoorResult;
}

/**
 * GPS Tracking 또는 indoor tracking 이 진행중일 때,
 * tracking-move 및 tracking-complete 이벤트 발생을 활성화한다
 *
 * https://api-doc.dabeeomaps.com/classes/mapContext_MyLocation.Mylocation.html#trackingOn
 */
export async function trackingOn(route: any) {
  await MapDraw.map.mylocation.trackingOn(route);
}

/**
 * 지도 회전을 변경한다
 */
export function enableMapRotate(enableRotate: boolean) {
  MapDraw.map.control.setOption({
    touchOption: {
      enableRotate,
    },
  });
}

/**
 * tracking-move 및 tracking-complete 이벤트 발생을 비활성화합니다.
 *
 * tracking-move 및 tracking-complete 이벤트핸들러를 등록했더라도,
 * map.mylocation.trackingOff() 를 호출하면 더 이상 해당 이벤트가 발생하지 않습니다.
 *
 * https://api-doc.dabeeomaps.com/classes/mapContext_MyLocation.Mylocation.html#trackingOff
 */
export async function offTracking() {
  validMap();

  await MapDraw.map.mylocation?.trackingOff(); // navigation tracking 중단
}

/**
 * Indoor Tracking 을 종료합니다.
 *
 * https://api-doc.dabeeomaps.com/classes/mapContext_MyLocation.Mylocation.html#indoorStop
 */
export async function stopIndoor() {
  validMap();

  await MapDraw.map.mylocation.indoorStop();
}
