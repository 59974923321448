import useIntersectionObserver from 'hooks/observer/useIntersectionObserver';
import { useEffect, useRef } from 'react';
import useLanguageStore from 'store/languages';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import styles from '../PopupTenantList.module.scss';

type Props = {
  onClick: (tenant: TenantWithPointId) => void;
  tenant: TenantWithPointId;
  isActive: boolean;
  isLast: boolean;
  handleShowDim: (state: boolean) => void;
};

const CurrentTenantItem = ({ onClick, tenant, isActive, isLast, handleShowDim }: Props) => {
  const languageStore = useLanguageStore();

  const itemRef = useRef<HTMLDivElement>(null);
  const entry = useIntersectionObserver(itemRef, {});

  // 마지막 아이템일 경우 dim 보여주도록 처리
  useEffect(() => {
    if (isLast) {
      if (entry?.isIntersecting) {
        handleShowDim(false);
        return;
      }
      handleShowDim(true);
    }
  }, [entry?.isIntersecting]);

  return (
    <div
      ref={itemRef}
      onClick={onClick.bind(this, tenant)}
      className={`${styles.tenant} ${isActive && styles.active}`}
      key={tenant.id}
    >
      <span className={`${styles.tenant_name} ${isActive && styles.active}`}>
        {tenant.name?.[languageStore.currentLang]}
      </span>
    </div>
  );
};
export default CurrentTenantItem;
