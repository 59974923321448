import { SvgProps } from '../type/SvgProps';

const CloseSvg = ({ color }: SvgProps) => {
  return (
    <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect
        x='11.3574'
        y='10.8809'
        width='24.4444'
        height='2.22222'
        transform='rotate(45 11.3574 10.8809)'
        fill={color}
      />
      <rect
        x='28.6426'
        y='12.4531'
        width='24.4444'
        height='2.22222'
        transform='rotate(135 28.6426 12.4531)'
        fill={color}
      />
    </svg>
  );
};
export default CloseSvg;
