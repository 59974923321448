import ModalBottomSheetLayout from 'components/popup/layout/bottom-sheet/ModalBottomSheetLayout';
import styles from './PermissionAlert.module.scss';

type Props = {
  isOpenPopup: boolean;
  onClickConfirm: () => void;
  onClose: () => void;
};

const PermissionAlert = (props: Props) => {
  return (
    <ModalBottomSheetLayout
      isOpenPopup={props.isOpenPopup}
      dim
      onClosePopup={props.onClose}
      outsideClose={false}
      closeButton={false}
    >
      <div className={styles.popup}>
        <div className={styles.message_wrapper}>
          <div
            className={styles.message}
          >{`더 나은 DID 모바일 서비스 이용을 위해\n아래 접근 권한의 허용이 필요합니다.`}</div>

          <div className={styles.content_wrapper}>
            <div className={styles.title}>디바이스 모션 데이터</div>

            <div className={styles.permission_for}>사용자의 방향 안내에 필요</div>
          </div>
        </div>

        <button onClick={props.onClickConfirm} className={styles.btn} type='button'>
          확인
        </button>
      </div>
    </ModalBottomSheetLayout>
  );
};
export default PermissionAlert;
