import { SvgProps } from '../../type/SvgProps';

const DownSvg = ({ color }: SvgProps) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='10' height='6' viewBox='0 0 10 6' fill='none'>
      <path d='M1 1.5L5 4.5L9 1.5' stroke={color} strokeWidth='1.5' />
    </svg>
  );
};
export default DownSvg;
