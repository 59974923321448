import { SvgProps } from '../type/SvgProps';

const AllSvg = ({ color }: SvgProps) => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='4' y='5' width='10' height='1' fill={color} />
      <rect x='4' y='9' width='10' height='1' fill={color} />
      <rect x='4' y='13' width='10' height='1' fill={color} />
    </svg>
  );
};
export default AllSvg;
