import { ICONS } from 'constants/icons';
import usePoc from 'hooks/poc/usePoc';
import styles from './PocButtons.module.scss';

const PocButtons = () => {
  const { handleCurrentLocationSaveButtonClick, handleBookmarkButtonClick, handleShareButtonClick, isActiveBookmark } =
    usePoc();

  return (
    <div className={styles.button_wrapper}>
      {/** 현재 위치 지정 */}
      <div className={styles.button} onClick={handleCurrentLocationSaveButtonClick}>
        <img src={ICONS.MAP} alt='현재위치 지정 버튼 이미지' />
        <span>현재위치 지정</span>
      </div>

      <div className={styles.divider} />

      {/** 즐겨찾기 */}
      <div className={styles.button} onClick={handleBookmarkButtonClick}>
        <img src={isActiveBookmark ? ICONS.STAR : ICONS.STAR_EMPTY} alt='즐겨찾기 지정 버튼 이미지' />
        <span>{isActiveBookmark ? '저장 됨' : '즐겨찾기'}</span>
      </div>

      <div className={styles.divider} />

      {/** 공유하기 */}
      <div className={styles.button} onClick={handleShareButtonClick}>
        <img src={ICONS.SHARE} alt='공유하기 지정 버튼 이미지' />
        <span>공유하기</span>
      </div>
    </div>
  );
};
export default PocButtons;
