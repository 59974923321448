import { create } from 'zustand';

type MapStoreProps = {
  loadMapScript: boolean;
  setLoadMapScript: (value: boolean) => void;
};
const useMapStore = create<MapStoreProps>((set, get) => ({
  // loadMapScript: false,
  loadMapScript: true,
  setLoadMapScript(loadMapScript: boolean) {
    set(() => ({ loadMapScript }));
  },
}));

export default useMapStore;
