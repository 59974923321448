import GoUpButton from 'components/common/button/go-up/GoUpButton';
import useGoTopButton from 'hooks/go-to-top/useGoTopButton';
import { Fragment, useRef } from 'react';
import useCustomBoardStore from 'store/customBoard';
import CustomBoardItem from '../item/CustomBoardItem';
import styles from './ListCustomBoardContainer.module.scss';

const ListCustomBoardContainer = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  const { showGoButton, hideGoToTopButton } = useGoTopButton({ listRef });

  const customBoardStore = useCustomBoardStore();
  return (
    <>
      <div className={styles.list} ref={listRef}>
        <div ref={scrollRef} style={{ visibility: 'hidden' }} />
        {customBoardStore.currentCustomBoards?.map((board, boardIndex) => (
          <Fragment key={board.id}>
            <CustomBoardItem key={boardIndex} customBoard={board} closeGoButton={hideGoToTopButton} />
          </Fragment>
        ))}
      </div>
      <GoUpButton scrollRef={scrollRef} isShow={showGoButton} />
    </>
  );
};
export default ListCustomBoardContainer;
