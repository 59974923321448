/* eslint-disable prefer-destructuring */
import { MOBILE_SHARE_DEFAULT } from 'data/defaultImages';
import { useEffect } from 'react';
import useShareModalStore from 'store/shareModal';
import { errorConsole } from 'util/common/console';

const useKakaoSdk = () => {
  const shareModalStore = useShareModalStore();

  /**
   * 피드 공유하기
   * https://developers.kakao.com/tool/demo/message/kakaolink?default_template=location
   */
  const shareKakaoFeed = () => {
    const shareUrl = shareModalStore.shareUrl;
    const kakaoShare = shareModalStore.kakaoShare;

    if (!shareUrl) {
      return errorConsole('url이 없습니다.');
    }

    window.Kakao.Share.createDefaultButton({
      container: '#kakaotalk-sharing-btn',
      objectType: 'feed',
      content: {
        title: kakaoShare.title,
        description: kakaoShare.description,
        imageUrl: kakaoShare.imageUrl || MOBILE_SHARE_DEFAULT,
        link: {
          // [내 애플리케이션] > [플랫폼] 에서 등록한 사이트 도메인과 일치해야 함
          mobileWebUrl: shareUrl,
          webUrl: shareUrl,
        },
      },
      buttons: [
        {
          title: kakaoShare.buttonText,
          link: {
            mobileWebUrl: shareUrl,
            webUrl: shareUrl,
          },
        },
      ],
    });
  };

  useEffect(() => {
    shareKakaoFeed();
  }, []);

  return { shareKakaoFeed };
};
export default useKakaoSdk;
