import { DEFAULT_MARKER_OPTION, PARKING_MARKER_OPTION } from 'data/commons';
import MapDraw from 'map/MapDraw';
import { PositionNum, PositionStr } from 'map/type/position.type';
import { Marker } from 'types/map/marker.type';
import { clearAllMarkers, clearMap } from './common/clear';

export type MarkerType = 'parking' | 'default';

/**
 * markerType 에 맞게 마커를 그려준다
 * @param markerType 주차장 타입인지 일반 타입인지
 * @param floorId 마커를 찍을 층 아이디
 * @param position 마커를 찍을 위치
 */
export const drawMarker = async (markerType: MarkerType, floorId: string, position: PositionStr | PositionNum) => {
  clearMap();

  await MapDraw.map.markers.set({
    marker: [
      {
        x: Math.abs(Number(position.x)),
        y: Math.abs(Number(position.y)),
        iconOption: markerType === 'parking' ? PARKING_MARKER_OPTION : DEFAULT_MARKER_OPTION,
        floorId,
      },
    ],
  });
};

/**
 * 마커를 여러개 그려준다
 * @param markers 마커 리스트
 */
export const drawMarkers = async (markers: Marker[]) => {
  clearAllMarkers();

  await MapDraw.map.markers.set({ marker: markers });
};
