import { MOBILE_SHARE_DEFAULT } from 'data/defaultImages';
import { create } from 'zustand';

export type KakaoShare = {
  title: string;
  description: string;
  imageUrl?: string;
  buttonText: string;
};

type Store = {
  openSharePopup: boolean;
  setOpenSharePopup: (openSharePopup: boolean) => void;

  // 공유할 URL
  shareUrl: string;
  setShareUrl: (shareUrl: string) => void;

  // Kakao 공유 시 필요한 정보
  kakaoShare: KakaoShare;
  setKakaShare: (kakaoShare: KakaoShare) => void;

  // QR 이미지 생성 시 파일명 지정
  qrFileTitle: string;
  setQrFileTitle: (qrFileTitle: string) => void;
};

// 공유하기 팝업
const useShareModalStore = create<Store>((set, get) => ({
  openSharePopup: false,
  setOpenSharePopup(openSharePopup: boolean) {
    set({
      openSharePopup,
    });
  },

  // 공유할 URL
  shareUrl: '',
  setShareUrl(shareUrl: string) {
    set({
      shareUrl,
    });
  },

  // Kakao 공유 시 필요한 정보
  kakaoShare: {
    title: '',
    description: '',
    imageUrl: MOBILE_SHARE_DEFAULT,
    buttonText: '웹으로 보기',
  },
  setKakaShare(kakaoShare: KakaoShare) {
    set({
      kakaoShare,
    });
  },

  // QR 이미지 생성 시 파일명 지정
  qrFileTitle: 'qr-code',
  setQrFileTitle(qrFileTitle: string) {
    set({
      qrFileTitle,
    });
  },
}));

export default useShareModalStore;
