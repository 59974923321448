import InfoTagGroup from 'components/common/info/group/tag/InfoTagGroup';
import { ICONS } from 'constants/icons';
import { COLORS } from 'data/color';
import { DEFAULT_IMAGE } from 'data/defaultImages';
import { LOCAL_STORAGE_BOOKMARK_KEY } from 'data/localStorageKey';
import { useCallback, useEffect, useState } from 'react';
import useColorSetStore from 'store/colorSet';
import useLanguageStore from 'store/languages';
import useValidStore from 'store/valid';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import styles from './TenantItem.module.scss';

type TenantItemProps = {
  handleClickItem: (tenant: TenantWithPointId) => void;
  tenant: TenantWithPointId;
  type: 'location' | 'search';
  clickedTenant?: TenantWithPointId | null;
};

const TenantItem = ({ handleClickItem, tenant, type, clickedTenant }: TenantItemProps) => {
  // state
  const [isShowBookmarkIcon, setIsShowBookmarkIcon] = useState<boolean>(false);

  // store
  const langStore = useLanguageStore();
  const colorSetStore = useColorSetStore();
  const validStore = useValidStore();

  // variable
  const isSelected = type === 'location' && clickedTenant?.id === tenant.id;
  const bookmarkList = localStorage.getItem(LOCAL_STORAGE_BOOKMARK_KEY);

  /**
   * local storage에 저장되어 있는 북마크 리스트와 현재 tenant의 매칭 여부에 따라 북마크 아이콘 표출 처리
   * @function isTenantBookmarkStatusByStorage
   * @returns {void}
   */
  const isTenantBookmarkStatusByStorage = useCallback((): void => {
    if (!tenant) return;
    const bookmarks = JSON.parse(bookmarkList || '[]');
    const isBookmarked = bookmarks.some((item: TenantWithPointId) => item.id === tenant.id);
    setIsShowBookmarkIcon(isBookmarked);
  }, [bookmarkList, tenant]);

  /**
   * useEffect, isTenantBookmarkStatusByStorage 함수를 호출하여 초기 bookmark 아이콘 표출 여부 정의
   */
  useEffect(() => {
    isTenantBookmarkStatusByStorage();
  }, [isTenantBookmarkStatusByStorage]);

  return (
    <div
      className={styles.wrapper}
      style={{
        background: isSelected ? colorSetStore.list.bg : COLORS.WHITE,
      }}
      onClick={handleClickItem.bind(this, tenant)}
    >
      {/** bookmark 아이콘 */}
      {
        // ! 시연용 워크스페이스 분기처리
        validStore.isPocWorkspace && isShowBookmarkIcon && (
          <div className={styles.bookmark_icon_wrapper}>
            <img src={ICONS.BOOKMARK} alt='매장 목록 북마크 이미지' />
          </div>
        )
      }

      {/* 이미지 */}
      <div className={styles.thumbnail}>
        <img
          src={`${tenant.mainLogoUri || DEFAULT_IMAGE}`}
          alt='thumbnail'
          onError={e => {
            const el = e.target as HTMLImageElement;
            el.src = DEFAULT_IMAGE;
          }}
        />
      </div>

      <div className={styles.detail_wrapper}>
        {/* 이름 */}
        <div
          className={styles.title}
          style={{
            color: colorSetStore.text.title,
          }}
        >
          {tenant?.name[langStore.currentLang] ?? '-'}
        </div>

        {/* 층, 포인트 이름, 카테고리 */}
        <InfoTagGroup tenant={tenant} type={type} />
      </div>
    </div>
  );
};
export default TenantItem;
