import { TransitionStatus } from 'types/common/modal.type';
import { create } from 'zustand';

type FacilityStore = {
  currentFacilityId: string;
  setCurrentFacilityId: (currentFacilityId: string) => void;
  resetCurrentFacilityId: () => void;

  facilityTransition: TransitionStatus;
  toggleFacilityTransition: () => void;
  clearFacilityTransition: () => void;
};

const useFacilityStore = create<FacilityStore>((set, get) => ({
  currentFacilityId: '',

  setCurrentFacilityId(currentFacilityId: string) {
    set({
      currentFacilityId,
    });
  },

  resetCurrentFacilityId() {
    set({
      currentFacilityId: '',
    });
  },

  facilityTransition: TransitionStatus.BASE,

  toggleFacilityTransition() {
    if (this.facilityTransition === TransitionStatus.BASE) {
      set({
        facilityTransition: TransitionStatus.OPEN,
      });
      return;
    }

    if (this.facilityTransition === TransitionStatus.OPEN) {
      set({
        facilityTransition: TransitionStatus.CLOSE,
      });
      return;
    }

    set({
      facilityTransition: TransitionStatus.OPEN,
    });
  },

  clearFacilityTransition() {
    set({
      facilityTransition: TransitionStatus.BASE,
    });
  },
}));

export default useFacilityStore;
