import GoUpButton from 'components/common/button/go-up/GoUpButton';
import NoTenant from 'components/common/no-tenant/NoTenant';
import useGoTopButton from 'hooks/go-to-top/useGoTopButton';
import { Fragment, useRef, useState } from 'react';
import useColorSetStore from 'store/colorSet';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import { Transition } from 'react-transition-group';
import DetailTenantPopup from 'components/popup/tenant/detail/DetailTenantPopup';
import useTenantStore from 'store/tenant';
import usePopupStore from 'store/popup';
import styles from './BookmarkList.module.scss';
import BookmarkItem from './list/item/BookmarkItem';

type BookmarkListProps = {
  bookmarks: TenantWithPointId[];
}

const BookmarkList = ({bookmarks}: BookmarkListProps) => {
  // state
  const [openDetailTenantPopup, setOpenDetailTenantPopup] = useState(false);
  // ref
  const scrollRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  // hooks
  const tenantStore = useTenantStore();
  const popupStore = usePopupStore();
  const colorSetStore = useColorSetStore();
  const { showGoButton } = useGoTopButton({ listRef });
  // variable
  
  const hasBookmarks = bookmarks && bookmarks.length > 0;

  /**
   * 즐겨찾기 목록 아이템 클릭 이벤트 핸들러
   * @function handleBookmarkItemClick
   * @param {TenantWithPointId} tenant 목록에서 선택한 tenant 정보
   * @returns {void}
   */
  const handleBookmarkItemClick = (tenant: TenantWithPointId): void => {
    popupStore.setOpenToastPopup(false);
    tenantStore.setCurrentTenant(tenant);
    setOpenDetailTenantPopup(true);
  };

  return (
    <>
      <div className={styles.container}>
        <div
          className={styles.list}
          ref={listRef}
          style={{
            background: colorSetStore.list.bg,
          }}
        >
          <div ref={scrollRef} />
          {bookmarks &&
            bookmarks.map((storageTenant: TenantWithPointId) => (
              <Fragment key={storageTenant.id}>
                <BookmarkItem handleClickItem={handleBookmarkItemClick} tenant={storageTenant} type='search' />
              </Fragment>
            ))}
          {!hasBookmarks && <NoTenant />}
        </div>
        {/* 위로가기 버튼 */}
        <GoUpButton scrollRef={scrollRef} isShow={showGoButton} overButton />
      </div>
      <Transition timeout={500} in={openDetailTenantPopup} unmountOnExit>
        <DetailTenantPopup closeDetailPopup={() => setOpenDetailTenantPopup(false)} isOpenPopup={openDetailTenantPopup} />
      </Transition>
    </>
  );
};

export default BookmarkList;
