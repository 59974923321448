import { LOCAL_STORAGE_ORIGIN_LOCATION_KEY } from 'data/localStorageKey';
import useFloor from 'hooks/api/floor/useFloor';
import useTransition from 'hooks/transition/useTransition';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useColorSetStore from 'store/colorSet';
import useLanguageStore from 'store/languages';
import useTimerStore from 'store/timer';
import { TransitionStatus } from 'types/common/modal.type';
import { StorageLocation } from 'types/origin/originLocation.type';
import styles from './LocationBar.module.scss';
import SettingLocationModal from './modal/SettingCurrentLocationModal';

type Props = {
  changePosition: boolean;
};

const LocationBar = ({ changePosition }: Props) => {
  // store
  const timerStore = useTimerStore();
  const colorSetStore = useColorSetStore();
  const langStore = useLanguageStore();

  // hook
  const { transition, openTransition, closeTransition } = useTransition();
  const { t } = useTranslation();
  const { findFloorName } = useFloor();

  const storageOriginLocation = localStorage.getItem(LOCAL_STORAGE_ORIGIN_LOCATION_KEY);
  const originLocation: StorageLocation = storageOriginLocation ? JSON.parse(storageOriginLocation || '') : '';

  // ref
  const locationToastTimerRef = useRef<NodeJS.Timer | null>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  /**
   * 토스트 팝업과 현재위치 변경 모달용 타이머 생성
   */
  useEffect(() => {
    timerStore.setToastPopupTimerRef(locationToastTimerRef);
  }, []);

  /**
   * 모달 언마운트 시 스크롤 초기화
   */
  useEffect(() => {
    return () => {
      if (scrollRef.current) {
        scrollRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    };
  }, []);

  return (
    <>
      <div className={styles.wrapper} style={{ background: colorSetStore.here.bg }}>
        <div
          className={styles.badge}
          style={{
            background: colorSetStore.sub,
            color: colorSetStore.here.bg,
          }}
        >
          {t(`floor.currentLocation`)}
        </div>

        <div
          className={styles.position}
          style={{
            color: colorSetStore.sub,
          }}
        >
          {`${findFloorName(originLocation.floorId, originLocation.pointId)} ${
            originLocation.name[langStore.currentLang]
          }`}
        </div>

        {changePosition && (
          <button
            onClick={openTransition}
            className={styles.btn}
            type='button'
            style={{
              color: colorSetStore.text.title,
            }}
          >
            {t(`floor.change`)}
          </button>
        )}
      </div>

      {transition === TransitionStatus.OPEN && (
        <SettingLocationModal transition={transition} closeModal={closeTransition} scrollRef={scrollRef} />
      )}
    </>
  );
};
export default LocationBar;
