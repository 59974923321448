import React from 'react';
import { useLocation } from 'react-router-dom';
import styles from './Layout.module.scss';
import MenuBar from './menu-bar/MenuBar';

type LayoutProps = {
  children: React.ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  const location = useLocation();

  return (
    <div className={styles.layout}>
      {children}
      {location.pathname !== '/error' && <MenuBar />}
    </div>
  );
};
export default Layout;
