import React from 'react';
import { SvgProps } from '../../type/SvgProps';

const UpSvg = ({ color }: SvgProps) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='10' height='6' viewBox='0 0 10 6' fill='none'>
      <path d='M9 4.5L5 1.5L1 4.5' stroke={color} strokeWidth='1.5' />
    </svg>
  );
};
export default UpSvg;
