import GoUpButton from 'components/common/button/go-up/GoUpButton';
import NoTenant from 'components/common/no-tenant/NoTenant';
import TenantItem from 'components/tenant/body/list/item/TenantItem';
import { PAGE_SIZE } from 'data/commons';
import { LOCAL_STORAGE_ORIGIN_LOCATION_KEY } from 'data/localStorageKey';
import useGoTopButton from 'hooks/go-to-top/useGoTopButton';
import { Fragment, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import useColorSetStore from 'store/colorSet';
import useTenantStore from 'store/tenant';
import { TransitionStatus } from 'types/common/modal.type';
import { StorageLocation } from 'types/origin/originLocation.type';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import SettingCurrentLocationButton, { LocationButton } from '../button/SettingCurrentLocationButton';
import styles from './SettingLocationTenants.module.scss';

type Props = {
  closeModal: () => void;
  transition: TransitionStatus;
  scrollRef: React.RefObject<HTMLDivElement>;
};

// filteredTenants 는 rawTenants 임 (LocationSearchField 에서 set 함)
const SettingLocationTenants = ({ closeModal, transition, scrollRef }: Props) => {
  const tenantStore = useTenantStore();
  const colorSetStore = useColorSetStore();

  const [isClicked, setClicked] = useState(false);
  const [clickedTenant, setClickedTenant] = useState<TenantWithPointId | null>(null);
  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex = startIndex + PAGE_SIZE;

  const hasTenants = tenantStore.filteredTenants && tenantStore.filteredTenants.length > 0;

  const listRef = useRef<HTMLDivElement>(null);

  const { showGoButton } = useGoTopButton({ listRef });

  // 스크롤 시 실행 함수
  const onLoadMore = () => {
    setCurrentPage(prev => prev + 1);
  };

  // 테넌트 클릭 시
  const handleClickItem = (tenant: TenantWithPointId) => {
    setClicked(true);
    setClickedTenant(tenant);
  };

  // 선택된 tenant css
  const handleClickTenant = () => {
    const storageOriginLocation = localStorage.getItem(LOCAL_STORAGE_ORIGIN_LOCATION_KEY);

    if (storageOriginLocation) {
      const originLocation: StorageLocation = JSON.parse(storageOriginLocation);

      // 만약 로컬스토리지에 저장된 출발지 정보가 있다면 클릭된 테넌트로 넘겨줘라 (선택되었다는 css 변경)
      if (originLocation) {
        const myLocationTenant = tenantStore.rawTenants.filter(tenant => tenant.id === originLocation.id)?.[0];

        setClickedTenant(myLocationTenant);
      }
    }
  };

  useEffect(() => {
    handleClickTenant();
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div
          className={styles.list}
          ref={listRef}
          style={{
            background: colorSetStore.list.bg,
          }}
        >
          <div ref={scrollRef} />

          {hasTenants && (
            <InfiniteScroll
              pageStart={0}
              loadMore={onLoadMore}
              hasMore={currentPage * PAGE_SIZE < tenantStore.filteredTenants.length}
              useWindow={false}
            >
              {/* // TODO: raw tenant 인지 확인 */}
              {tenantStore.filteredTenants.slice(0, endIndex).map(tenant => (
                <Fragment key={tenant.id}>
                  <TenantItem
                    handleClickItem={handleClickItem}
                    tenant={tenant}
                    clickedTenant={clickedTenant}
                    type='location'
                  />
                </Fragment>
              ))}
            </InfiniteScroll>
          )}
          {!hasTenants && <NoTenant />}
        </div>

        {/* 위로가기 버튼 */}
        <GoUpButton scrollRef={scrollRef} isShow={showGoButton} overButton />
      </div>

      {transition === TransitionStatus.OPEN && (
        <SettingCurrentLocationButton
          buttonType={LocationButton.LIST_FULL}
          closeModal={closeModal}
          disabled={!isClicked}
          clickedTenant={clickedTenant}
        />
      )}
    </>
  );
};
export default SettingLocationTenants;
