import NoCurrentFloorFacilityPopup from 'components/popup/toast/no-facility/NoCurrentFloorFacilityPopup';
import useStats from 'hooks/api/stats/useStats';
import { clearAllMarkers } from 'map/control/common/clear';
import { drawFacilityMarkers } from 'map/control/facilityMarker';
import { useEffect, useRef, useState } from 'react';
import useCategoryGroupStore from 'store/categoryGroup';
import useColorSetStore from 'store/colorSet';
import useFacilityStore from 'store/facility';
import useFloorStore from 'store/floor';
import useLanguageStore from 'store/languages';
import useMachineStore from 'store/machine';
import usePopupStore from 'store/popup';
import { CategoryGroup } from 'types/category/category.type';
import { Marker } from 'types/map/marker.type';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import styles from './FacilityBar.module.scss';
import FacilityItem from './facility-item/FacilityItem';

type FacilityBarProps = {
  isOpenNoFacilityToast: boolean;
  removeNoFacilityToast: () => void;
  openNoFacilityToast: () => void;
};

const FacilityBar = ({ isOpenNoFacilityToast, removeNoFacilityToast, openNoFacilityToast }: FacilityBarProps) => {
  const categoryStore = useCategoryGroupStore();
  const floorStore = useFloorStore();
  const langStore = useLanguageStore();
  const facilityStore = useFacilityStore();
  const popupStore = usePopupStore();
  const colorSetStore = useColorSetStore();
  const machineStore = useMachineStore();

  const [facilityName, setFacilityName] = useState<string>('');

  const { postCategoryUsage } = useStats();

  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const closePopupAfterSeconds = () => {
    timerRef.current = setTimeout(() => {
      removeNoFacilityToast();
    }, 3000);
  };

  const clearPopupTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    removeNoFacilityToast();
  };

  const openToastPopup = () => {
    openNoFacilityToast();
    closePopupAfterSeconds();
  };

  // 해당 카테고리에 포함된 테넌트 검색
  const findTenants = (categoryGroupId: string): TenantWithPointId[] | undefined => {
    if (categoryStore.mapFacilityCategories) {
      const origin = [...categoryStore.mapFacilityCategories.tenants];

      const tenants = origin.filter(tenant => {
        return tenant.categoryGroups.some(category => category.id === categoryGroupId);
      });

      return tenants;
    }
  };

  const handleClickItem = async (categoryGroup: CategoryGroup) => {
    if (!machineStore.machine) {
      return;
    }

    // 카테고리 통계 수집
    await postCategoryUsage(categoryGroup.id, machineStore.machine.point.id, machineStore.machine.id);

    clearPopupTimer();
    setFacilityName(categoryGroup.name[langStore.currentLang]);

    popupStore.setOpenToastPopup(false);
    facilityStore.setCurrentFacilityId(categoryGroup.id);

    // TODO: 할때마다 찾지말고 map 으로 저장해놓고 찾도록 변경
    const tenants: TenantWithPointId[] | undefined = findTenants(categoryGroup.id);

    if (tenants) {
      const currentFloorMarkerList: Marker[] | undefined = await drawFacilityMarkers(
        tenants,
        floorStore.currentFloorId,
      );

      // undefined
      if (!currentFloorMarkerList) {
        openToastPopup();
        return;
      }

      // []
      if (currentFloorMarkerList.length < 1) {
        openToastPopup();
        return;
      }

      removeNoFacilityToast();
    }
  };

  useEffect(() => {
    return () => {
      clearPopupTimer();
      clearAllMarkers();
      facilityStore.resetCurrentFacilityId();
    };
  }, []);

  return (
    <div
      className={styles.facility_bar}
      style={{
        background: colorSetStore.header.bg,
        borderTop: `1px solid ${colorSetStore.sub}`,
      }}
    >
      <div className={styles.facilities}>
        {categoryStore.mapFacilityCategories?.categoryGroups
          // .filter(group => group.tenants?.length > 0)
          .map(categoryGroup => (
            <FacilityItem
              key={categoryGroup.id ?? ''}
              categoryGroup={categoryGroup}
              handleClickItem={handleClickItem}
              isActive={categoryGroup.id === facilityStore.currentFacilityId}
            />
          ))}
      </div>

      {/* css animation 으로 display 처리 */}
      <NoCurrentFloorFacilityPopup
        clearPopupTimer={clearPopupTimer}
        facilityName={facilityName}
        isOpen={isOpenNoFacilityToast}
      />
    </div>
  );
};
export default FacilityBar;
