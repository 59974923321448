import { TenantWithPointId } from 'types/tenant/tenant.type';

/**
 *
 * @param tenants 모든 테넌트 목록
 * @param tenantId 테넌트 아이디
 * @returns  { TenantWithPointId | undefined } 찾은 테넌트 반환
 */
export const findTenant = (tenants: TenantWithPointId[], tenantId: string): TenantWithPointId | undefined => {
  return tenants.find(tenant => tenant.id === tenantId);
};
