/* eslint-disable prefer-destructuring */
import ARROW_DOWN from 'assets/icon/arrow-down-gy.svg';
import ARROW_UP from 'assets/icon/arrow-up-gy.svg';
import ICON_INDOOR from 'assets/point/indoor.svg';
import ICON_OUTDOOR from 'assets/point/outdoor.svg';
import useMapDirection from 'hooks/map/trigger/useMapDirection';
import MapDraw from 'map/MapDraw';
import { addBuilding } from 'map/control/building';
import { changeCameraMinMaxZoom, changeCameraZoom, resetCamera } from 'map/control/camera';
import { clearMap, clearMyLocation } from 'map/control/common/clear';
import { useState } from 'react';
import useCategoryGroupStore from 'store/categoryGroup';
import useColorSetStore from 'store/colorSet';
import useFloorStore from 'store/floor';
import useLanguageStore from 'store/languages';
import useLoadingStore from 'store/loading';
import usePointStore from 'store/point';
import useTrackingStore from 'store/tracking';
import { CategoryFeature, CategoryGroupWithTenants } from 'types/category/category.type';
import { Floor } from 'types/floor/floor.type';
import { Point } from 'types/point/point.type';
import styles from './PointSelection.module.scss';

const PointSelection = () => {
  const [openSelection, setOpenSelection] = useState(false);

  const themeStore = useColorSetStore();
  const pointStore = usePointStore();
  const langStore = useLanguageStore();
  const floorStore = useFloorStore();
  const categoryStore = useCategoryGroupStore();
  const loadingStore = useLoadingStore();
  const trackingStore = useTrackingStore();

  const { switchActionByTrackingType } = useMapDirection();

  /**
   * 층 리스트와 현재 층 전역 상태값 변경 및 빌딩의 main 층 반환.
   * @param pointId 포인트 아이디로 층 검색
   * @returns point 의 main 층 반환
   */
  const setFloorRelatedStatesAndFindMainFloor = (pointId: string): string | undefined => {
    if (floorStore.pointFloorsMap) {
      const findFloors: Floor[] | undefined = floorStore.pointFloorsMap.get(pointId);

      if (findFloors) {
        // 층 목록
        floorStore.setFloors(findFloors);

        const findMainFloor = findFloors.find(floor => floor.main);

        if (findMainFloor) {
          // 층
          floorStore.setCurrentFloorId(findMainFloor.id);

          return findMainFloor.id;
        }
      }
    }
  };

  // point 의 category 로 세팅
  const setPointCategoryGroups = (pointId: string) => {
    if (categoryStore.mapFacilityCategoriesMap) {
      const findCategories: CategoryGroupWithTenants | undefined = categoryStore.mapFacilityCategoriesMap.get(pointId);

      if (findCategories) {
        categoryStore.setCategoryList(CategoryFeature.mapFacility, findCategories);
      }
    }
  };

  /**
   * point select 에서 point 클릭 시 일어나는 일
   *
   * 1. selectedPoint 전역 상태 변경
   * 2. 다른 포인트로 이동 (빌딩 추가, 메인층으로 층 이동)
   * 3. 층별안내 category 목록 변경
   * 5. 카메라(지도 센터) 초기화
   * 6. 지도 줌 값 포인트별로 적용
   * 7. point 의 Min, Max zoom 값 설정 변경
   * 8. 기기 층일 경우만 GPS 노출
   *
   * @param point 클릭한 포인트 정보
   */
  const onChangePoint = async (point: Point) => {
    loadingStore.setFloorChanging(true);
    pointStore.setSelectedPoint(point);

    clearMap();
    clearMyLocation();

    setOpenSelection(false);

    const pointMainFloorId = setFloorRelatedStatesAndFindMainFloor(point.id);

    if (pointMainFloorId) {
      await addBuilding(point.buildingId, pointMainFloorId);

      setPointCategoryGroups(point.id);

      if (trackingStore.trackingType === 'OFF') {
        resetCamera();
      }

      const machinePointId = MapDraw.pointId;
      const machineFloorId = MapDraw.machineFloorId;
      const machineDefaultZoom = MapDraw.zoom;

      // 내 포인트일 경우 기기 zoom 값 적용한다.
      if (point.id === machinePointId) {
        changeCameraZoom(machineDefaultZoom);
      } else {
        // 다른 포인트일 경우 포인트 default zoom 값 적용한다.
        changeCameraZoom(Number(point.mapZoom));
      }

      // point 별로 Min, Max zoom 값 설정 변경
      changeCameraMinMaxZoom(Number(point.mapMinZoom), Number(point.mapMaxZoom));

      if (pointMainFloorId === machineFloorId) {
        await switchActionByTrackingType(trackingStore.trackingType);
      }
    }

    loadingStore.setFloorChanging(false);
  };

  return (
    <div className={styles.point}>
      {/* 현재 선택된 Point */}
      <div
        style={{ backgroundColor: themeStore.sub }}
        className={styles.current_point}
        onClick={() => setOpenSelection(!openSelection)}
      >
        <div className={styles.current}>
          <div className={styles.icon}>
            <img src={pointStore.selectedPoint?.buildingType === 'OUTDOOR' ? ICON_OUTDOOR : ICON_INDOOR} alt='' />
          </div>
          <div>{pointStore.selectedPoint?.name[langStore.currentLang]}</div>
        </div>
        <div className={styles.arrow}>
          <img src={openSelection ? ARROW_UP : ARROW_DOWN} alt='arrow' />
        </div>
      </div>

      {openSelection && (
        <div className={`${styles.options} ${openSelection ? styles.open : styles.close}`}>
          {pointStore.points
            .filter(point => point.id !== pointStore.selectedPoint?.id)
            .map(point => (
              <div className={styles.option} key={point.id} onClick={onChangePoint.bind(this, point)}>
                <div className={styles.icon}>
                  <img src={point.buildingType === 'OUTDOOR' ? ICON_OUTDOOR : ICON_INDOOR} alt='' />
                </div>
                <div>{point.name[langStore.currentLang]}</div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default PointSelection;
