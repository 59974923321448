import { create } from 'zustand';

type NaviType = 'INIT' | 'ON_ACTIVE' | 'REPLAY';

type Store = {
  naviRoutes: any;
  setNaviRoutes: (naviRoutes: any) => void;
  clearNaviRoutes: () => void;

  // destination icon url
  naviDestIconUrl: string;
  setNaviDestIconUrl: (naviDestIconUrl: string) => void;

  // destination icon size
  naviDestIconSize: { width: number; height: number };
  setNaviDestIconSize: (naviDestIconUrl: { width: number; height: number }) => void;

  // 길찾기 시작 / 모의보행 중 / 다시보기
  naviType: NaviType;
  setNaviType: (naviType: NaviType) => void;
};

const useNaviStore = create<Store>((set, get) => ({
  naviRoutes: null,
  setNaviRoutes(naviRoutes: any) {
    set(() => ({ naviRoutes }));
  },
  clearNaviRoutes() {
    set(() => ({ naviRoutes: null }));
  },

  // destination icon url
  naviDestIconUrl: '/assets/icon/arrive.png',
  setNaviDestIconUrl(naviDestIconUrl: string) {
    set(() => ({ naviDestIconUrl }));
  },

  // destination icon url
  naviDestIconSize: { width: 14, height: 20 },
  setNaviDestIconSize(naviDestIconSize: { width: number; height: number }) {
    set(() => ({ naviDestIconSize }));
  },

  // navi type
  naviType: 'INIT',
  setNaviType(naviType: NaviType) {
    set({
      naviType,
    });
  },
}));

export default useNaviStore;
