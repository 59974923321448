import { MultiLang } from 'types/language/language.type';
import { TenantWithPointId } from 'types/tenant/tenant.type';

export enum CategoryFeature {
  tenantSearch = 'TENANT_SEARCH',
  mapFacility = 'MAP_FACILITY',
  facility = 'FACILITY',
}

export type CategoryCode = {
  poiCategoryCode: string;
};

export type CategoryGroup = {
  id: string;
  name: MultiLang;
  feature: CategoryFeature | string;
  sortOrder: number;
  categories: CategoryCode[];
};

export type TenantCategoryGroupWithPointId = {
  pointId: string;
  categoryGroups: CategoryGroup[];
};

export type CategoryGroupWithTenants = TenantCategoryGroupWithPointId & {
  tenants: TenantWithPointId[];
};
