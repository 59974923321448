/* eslint-disable prefer-destructuring */
import LOCATION_WHITE from 'assets/icon/location-white.svg';
import useStats from 'hooks/api/stats/useStats';
import { t } from 'i18next';
import { clearMap } from 'map/control/common/clear';
import { useLocation, useNavigate } from 'react-router-dom';
import useMapActionStore from 'store/action';
import useColorSetStore from 'store/colorSet';
import useMachineStore from 'store/machine';
import useModalStore from 'store/modal';
import useTenantStore from 'store/tenant';
import useTransportStore from 'store/transport';
import { PathType } from 'types/common/path.type';
import { errorConsole } from 'util/common/console';
import styles from '../MapActionButton.module.scss';

type Props = {
  closePopup: () => void;
};

const MapLocationButton = ({ closePopup }: Props) => {
  const transportStore = useTransportStore();
  const mapActionStore = useMapActionStore();
  const tenantStore = useTenantStore();
  const themeStore = useColorSetStore();
  const machineStore = useMachineStore();
  const modalStore = useModalStore();

  const navigate = useNavigate();
  const { search: queryParams, pathname } = useLocation();
  const { postTenantUsage } = useStats();

  const floorPathWithQueryParams = `${PathType.floor}${queryParams}`;

  /**
   * 테넌트의 위치를 확인한다
   *
   * 마커 + 카메라 이동
   */
  const onClickLocation = async () => {
    try {
      clearMap();

      closePopup();
      modalStore.setOpenTenantListModal(false);

      if (!tenantStore.currentTenant || !machineStore.machine) {
        return;
      }

      /**
       * 매장 사용량을 수집한다 (통계)
       */
      await postTenantUsage(
        machineStore.machine.point.id,
        tenantStore.currentTenant?.id || '',
        'LOCATION',
        machineStore.machine.id,
      );

      /**
       * 층별안내 페이지로 이동한다
       */
      if (pathname !== PathType.floor) {
        navigate(floorPathWithQueryParams);
      }

      mapActionStore.setMapAction('location', true);
    } catch (error) {
      errorConsole('위치확인에 실패했습니다.', error);
    }
  };

  return (
    <button
      style={{ background: themeStore.sub }}
      type='button'
      disabled={transportStore.isLoadingTransport}
      onClick={onClickLocation}
      className={styles.wrapper}
    >
      <div className={styles.icon}>
        <img src={LOCATION_WHITE} alt='icon' />
      </div>
      <div>{t(`button.location`)}</div>
    </button>
  );
};
export default MapLocationButton;
