const setVh = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

export const handleViewHeight = () => {
  setVh();
  window.addEventListener('resize', () => {
    setVh();
  });
};
