import { MutableRefObject } from 'react';
import { create } from 'zustand';

type Props = {
  // 전역적으로 사용하는 토스트 팝업
  toastPopupTimerRef: MutableRefObject<NodeJS.Timer | null> | null;
  setToastPopupTimerRef: (toastPopupTimerRef: MutableRefObject<NodeJS.Timer | null>) => void;
  closeToastPopupAfterSeconds: (callback: () => void) => void;
  clearToastPopupTimer: () => void;
};

const useTimerStore = create<Props>((set, get) => ({
  // 전역적으로 사용하는 토스트 팝업
  toastPopupTimerRef: null,
  setToastPopupTimerRef(toastPopupTimerRef: MutableRefObject<NodeJS.Timer | null>) {
    set({
      toastPopupTimerRef,
    });
  },
  closeToastPopupAfterSeconds(callback: () => void) {
    if (this.toastPopupTimerRef) {
      this.toastPopupTimerRef.current = setTimeout(() => {
        callback();
        this.clearToastPopupTimer();
      }, 1500);
    }
  },
  clearToastPopupTimer() {
    if (this.toastPopupTimerRef && this.toastPopupTimerRef.current) {
      clearTimeout(this.toastPopupTimerRef.current);
    }
  },
}));

export default useTimerStore;
