import AllSvg from 'components/common/icon/all/AllSvg';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import useColorSetStore from 'store/colorSet';
import { PathType } from 'types/common/path.type';
import { joinParams } from 'util/params/join/joinParams';
import styles from './AllButton.module.scss';

const AllButton = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const colorSetStore = useColorSetStore();

  const isActive = location.pathname === PathType.more;

  const background = isActive ? colorSetStore.gnb.bg.active : colorSetStore.gnb.bg.inactive;
  const color = isActive ? colorSetStore.gnb.text.active : colorSetStore.text.title;

  return (
    <Link
      style={{
        background,
      }}
      className={styles.container}
      to={`${PathType.more}?${joinParams(location.search)}`}
    >
      <div className={styles.wrapper}>
        <AllSvg color={color} />

        <div
          style={{
            color,
          }}
          className={styles.text}
        >
          {t('menu.more')}
        </div>
      </div>
    </Link>
  );
};

export default AllButton;
