import DownSvg from 'components/common/icon/arrow/down/DownSvg';
import { t } from 'i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { joinParams } from 'util/params/join/joinParams';
import styles from './CloseButton.module.scss';

type Props = {
  closePopup: () => void;
};

const CloseButton = ({ closePopup }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleClickCloseBtn = () => {
    closePopup();
    navigate(`${location.pathname}?${joinParams(location.search)}`);
  };

  return (
    <div className={styles.close_btn} onClick={handleClickCloseBtn}>
      <div className={styles.close_text}>{t(`button.close`)}</div>

      <DownSvg color='#222' />
    </div>
  );
};
export default CloseButton;
