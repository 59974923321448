import ButtonLoader from 'components/common/loader/button/ButtonLoader';
import ModalBottomSheetLayout from 'components/popup/layout/bottom-sheet/ModalBottomSheetLayout';
import MapNavigationButton from 'components/popup/tenant/button/map-action/MapNavigationButton';
import { LOCAL_STORAGE_PARKING_LOCATION_KEY } from 'data/localStorageKey';
import useCheckValidRoute from 'hooks/map/check/useCheckValidRoute';
import { t } from 'i18next';
import { MutableRefObject, useLayoutEffect, useState } from 'react';
import useModalStore from 'store/modal';
import useNaviStore from 'store/navi';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import SetMyParkingLocationButton from '../common/my-parking-location/SetMyParkingLocationButton';
import ParkingLocationInfo from '../common/parking-location-info/ParkingLocationInfo';
import ShareMyParkingLocationButton from '../common/share-button/ShareMyParkingLocationButton';
import styles from './MyParkingLocationPopup.module.scss';

type Props = {
  isOpenPopup: boolean;
  onClosePopup: () => void;
  parkingPopupCloseTimer: MutableRefObject<NodeJS.Timeout | null>;
};

/**
 * @param {Props} props
 * @returns 내 주차위치 확인 팝업
 */
const MyParkingLocationPopup = (props: Props) => {
  // store
  const modalStore = useModalStore();
  const naviStore = useNaviStore();

  // state
  const [ableToNavi, setAbleToNavi] = useState(false);

  // 내 주차위치
  const storageParkingLocation = localStorage.getItem(LOCAL_STORAGE_PARKING_LOCATION_KEY);
  const myParkingLocation: TenantWithPointId = storageParkingLocation ? JSON.parse(storageParkingLocation || '') : '';

  // hook
  const { checkValidRoute, routeLoading } = useCheckValidRoute();

  const validNavi = async () => {
    if (myParkingLocation) {
      const isAbleToNavi = await checkValidRoute(myParkingLocation);
      setAbleToNavi(isAbleToNavi);
    }
  };

  /**
   * 길찾기 버튼 유효성 검사
   */
  useLayoutEffect(() => {
    validNavi();
  }, []);

  /**
   * 주차 관련 기능일 경우 아이콘을 변경해준다
   */
  useLayoutEffect(() => {
    naviStore.setNaviDestIconUrl('/assets/icon/arrive-green.png');
    naviStore.setNaviDestIconSize({ width: 37, height: 52 });
  }, []);

  if (!myParkingLocation) {
    return <> </>;
  }

  return (
    <ModalBottomSheetLayout outsideClose {...props} dim={false}>
      <div>
        <div
          className={`${styles.popup} ${modalStore.openMyParkingModal && styles.padding} ${
            modalStore.openMyParkingModal && styles.gap
          }`}
        >
          <div className={`${styles.message_wrapper} ${!modalStore.openMyParkingModal && styles.padding}`}>
            <pre className={styles.message}>{t('myParkingLocation')}</pre>

            <ParkingLocationInfo parkingLocation={myParkingLocation} />
          </div>

          <div className={styles.sub_btn_wrapper}>
            {/* 공유하기 버튼 */}
            <ShareMyParkingLocationButton />

            <div className={styles.btn_divider} />

            {/* 내 주차위치 지정 토글 */}
            <SetMyParkingLocationButton type='star' {...props} />
          </div>
        </div>

        {/* 길찾기 가능하다면 버튼 노출 */}
        {ableToNavi && (
          <div className={styles.main_btn_wrapper}>
            {/* 길찾기 버튼 */}
            {routeLoading ? (
              <ButtonLoader />
            ) : (
              <MapNavigationButton buttonText={t(`button.navigation`)} closePopup={props.onClosePopup} />
            )}
          </div>
        )}
      </div>
    </ModalBottomSheetLayout>
  );
};
export default MyParkingLocationPopup;
