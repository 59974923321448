import { useAxios } from 'api/axios';
import { EndPoint } from 'data/endpoint';
import useLanguageStore from 'store/languages';
import { ApiResponse } from 'types/api/response';
import { LangCode, Language } from 'types/language/language.type';

const useLanguage = () => {
  const languageStore = useLanguageStore();

  const { api } = useAxios();

  const getLanguages = async (workspaceId: string) => {
    const response = await api.get<ApiResponse<Language[]>>(EndPoint.languages, {
      params: {
        workspaceId,
      },
    });

    if (response.data) {
      const langs: Language[] = response.data.map(lang => {
        return {
          ...lang,
          code: lang.code.toLowerCase() as LangCode,
        };
      });

      languageStore.setLanguages(langs);

      const mainLanguage = (langs.find(lang => lang.main)?.code as LangCode) ?? LangCode.ko;

      return {
        mainLanguage,
        langs,
      };
    }

    // 없을 경우
    return {
      mainLanguage: LangCode.ko,
      langs: [],
    };
  };

  return { getLanguages };
};
export default useLanguage;
