import { TransitionStatus } from 'types/common/modal.type';
import styles from './SettingCurrentLocationModal.module.scss';
import SettingLocationModalHeader from './header/SettingCurrentLocationModalHeader';
import SettingLocationTenants from './list/SettingLocationTenants';
import LocationSearchField from './search-field/LocationSearchField';

type Props = {
  closeModal: () => void;
  transition: TransitionStatus;
  scrollRef: React.RefObject<HTMLDivElement>;
};

const SettingLocationModal = ({ closeModal, transition, scrollRef }: Props) => {
  return (
    <div
      className={`${styles.wrapper}    
      ${transition === TransitionStatus.OPEN && styles.visible} 
      ${transition === TransitionStatus.CLOSE && styles.hidden}
      `}
    >
      <SettingLocationModalHeader closeModal={closeModal} />

      <LocationSearchField />

      <SettingLocationTenants closeModal={closeModal} transition={transition} scrollRef={scrollRef} />
    </div>
  );
};
export default SettingLocationModal;
