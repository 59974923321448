import { IMAGES } from 'constants/images';
import useMapDirection from 'hooks/map/trigger/useMapDirection';
import useTrackingStore from 'store/tracking';
import styles from './TrackingModeButton.module.scss';

const TrackingModeButton = () => {
  const trackingStore = useTrackingStore();

  // hook
  const { onDirectionTracking, fixCurrentRotation, offDirectionTracking } = useMapDirection();

  /**
   * 버튼을 클릭한다
   */
  const onClickDirectionTracking = async () => {
    /**
     * ON -> FIXED
     * tracking 이 켜져있다면 회전을 고정한다.
     */
    if (trackingStore.trackingType === 'ON') {
      await fixCurrentRotation();
      return;
    }

    /**
     * FIXED -> OFF
     * 방향 고정 중이라면 tracking 을 종료한다.
     */
    if (trackingStore.trackingType === 'FIXED') {
      await offDirectionTracking();
      return;
    }

    /**
     * OFF -> ON
     * tracking 이 꺼져있다면 켠다.
     */
    if (trackingStore.trackingType === 'OFF') {
      await onDirectionTracking();
    }
  };

  return (
    <div
      style={{
        transform: `rotate(${trackingStore.trackingType === 'OFF' ? 0 : trackingStore.mapRotation}deg)`,
      }}
      onClick={onClickDirectionTracking}
      className={`${styles.wrapper}`}
    >
      {trackingStore.trackingType === 'ON' && <img src={IMAGES.ROTATION_ON} alt='on' />}
      {trackingStore.trackingType === 'FIXED' && <img src={IMAGES.ROTATION_FIXED} alt='fixed' />}
      {trackingStore.trackingType === 'OFF' && <img src={IMAGES.ROTATION_OFF} alt='off' />}
    </div>
  );
};
export default TrackingModeButton;
