import MapLoader from 'components/common/loader/map-loader/MapLoader';
import MapDraw from 'map/MapDraw';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCategoryGroupStore from 'store/categoryGroup';
import useFacilityStore from 'store/facility';
import useFloorStore from 'store/floor';
import useLanguageStore from 'store/languages';
import useLoadingStore from 'store/loading';
import useMachineStore from 'store/machine';
import usePointStore from 'store/point';
import { CategoryFeature } from 'types/category/category.type';
import { Floor } from 'types/floor/floor.type';
import { Point } from 'types/point/point.type';
import styles from './FloorContainer.module.scss';
import FacilityIsland from './facility/facility-island/FacilityIsland';
import FloorList from './floor-list/FloorList';
import LocationBar from './location-bar/LocationBar';
import MapContainer from './map/MapContainer';
import PointSelection from './point-select/PointSelection';

const FloorContainer = () => {
  const [isOpenNoFacilityToast, setOpenNoFacilityToast] = useState<boolean>(false);

  const loadingStore = useLoadingStore();
  const facilityStore = useFacilityStore();
  const pointStore = usePointStore();
  const categoryStore = useCategoryGroupStore();
  const machineStore = useMachineStore();
  const floorStore = useFloorStore();
  const langStore = useLanguageStore();

  const { t } = useTranslation();

  // no facility open
  const openNoFacilityToast = () => {
    setOpenNoFacilityToast(true);
  };

  // no facility close
  const removeNoFacilityToast = () => {
    setOpenNoFacilityToast(false);
  };

  // 카테고리 설정
  const initCategoryGroups = () => {
    if (pointStore.point && categoryStore.mapFacilityCategoriesMap) {
      const pointCategory = categoryStore.mapFacilityCategoriesMap.get(pointStore.point.id);

      if (pointCategory) {
        categoryStore.setCategoryList(CategoryFeature.mapFacility, pointCategory);
      }
    }
  };

  // 층 설정
  const initFloor = () => {
    if (floorStore.pointFloorsMap) {
      const findFloors: Floor[] | undefined = floorStore.pointFloorsMap.get(MapDraw.pointId);

      if (findFloors) {
        // 층 목록
        floorStore.setFloors(findFloors);

        const findMainFloor = findFloors.find(floor => floor.main);

        if (findMainFloor) {
          // 층
          // useMachine 에서 setCurrentFloorId 지정해주고 있음.
          // floorStore.setCurrentFloorId(findMainFloor.id);

          // 층 이름
          floorStore.setFloorName(findMainFloor.name[langStore.currentLang]);
        }
      }
    }
  };

  // 선택된 point 변경
  const initPoint = (pointId: string) => {
    const findPoint: Point | undefined = pointStore.pointsMap?.get(pointId);

    if (findPoint) {
      pointStore.setSelectedPoint(findPoint);
    }
  };

  // 페이지 마운트 시 층별안내 상태 값 초기 설정
  const initFloorPage = () => {
    if (machineStore.machine) {
      initPoint(MapDraw.pointId);

      initCategoryGroups();

      initFloor();
    }
  };

  useEffect(() => {
    initFloorPage();
  }, []);

  useEffect(() => {
    return () => {
      facilityStore.clearFacilityTransition();
    };
  }, []);

  // 처음 지도 로딩 시, 지도 층 변경 시, 길찾기 경로 찾을 경우 사용
  const isLoadingMap = loadingStore.isLoadingMap || loadingStore.isFloorChanging || loadingStore.isActionLoading;

  return (
    <div className={styles.container}>
      {/* 현재위치 바 */}
      <LocationBar changePosition />

      {/* 포인트 선택 */}
      {pointStore.points.length > 1 && <PointSelection />}

      {/* 지도 컴포넌트 */}
      <MapContainer />

      {/* 편의시설 바 */}
      <FacilityIsland
        isOpenNoFacilityToast={isOpenNoFacilityToast}
        removeNoFacilityToast={removeNoFacilityToast}
        openNoFacilityToast={openNoFacilityToast}
      />

      {/* 층 목록 */}
      <FloorList removeNoFacilityToast={removeNoFacilityToast} />

      {/* 지도 로딩 */}
      {isLoadingMap && (
        <MapLoader message={loadingStore.isActionLoading ? `${t(`loading.action`)}` : `${t(`loading.showMap`)}`} />
      )}
    </div>
  );
};
export default FloorContainer;
