import MapDraw from 'map/MapDraw';
import { clearAllMarkers, clearMap, clearMyLocation } from 'map/control/common/clear';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import useMapActionStore from 'store/action';
import useColorSetStore from 'store/colorSet';
import useFloorStore from 'store/floor';
import useModalStore from 'store/modal';
import useTrackingStore from 'store/tracking';
import useTransportStore from 'store/transport';
import { drawMyLocationByTrackingType } from 'util/common/mapCommon';
import styles from './TransportPopup.module.scss';

type TransportPopupProps = {
  closeTransportPopup: () => void;
};

const TransportPopup = ({ closeTransportPopup }: TransportPopupProps) => {
  const transportStore = useTransportStore();
  const actionStore = useMapActionStore();
  const floorStore = useFloorStore();
  const themeStore = useColorSetStore();
  const modalStore = useModalStore();
  const trackingStore = useTrackingStore();

  const { t } = useTranslation();

  const onClick = (transport: string) => {
    clearAllMarkers();
    closeTransportPopup();

    transportStore.setCurrentTransport(transport);
    floorStore.setCurrentFloorId(MapDraw.machineFloorId);
    actionStore.setMapAction('indoorTracking', true);
    modalStore.setOpenNaviModal(true); // tracking 안내 팝업을 연다
  };

  useEffect(() => {
    return () => {
      actionStore.resetMapActions();
    };
  }, []);
  return (
    <div className={styles.background}>
      <OutsideClickHandler
        onOutsideClick={async () => {
          clearMap();
          closeTransportPopup();
          clearMyLocation();
          await drawMyLocationByTrackingType(trackingStore.trackingType, MapDraw.machineCoordinate);
        }}
      >
        <div className={styles.popup}>
          <div className={styles.text}>{t(`popup.transport.desc`)}</div>

          <div className={styles.transports_wrapper}>
            {transportStore.transports?.map(transport => (
              <div
                style={{ background: themeStore.sub }}
                className={styles.transport}
                onClick={onClick.bind(this, transport)}
                key={transport}
              >
                <img className={styles.icon} src={`assets/icon/transport/${transport}.svg`} alt={transport} />
                <div className={styles.transport_text}>{t(`popup.transport.method.${transport}`)}</div>
              </div>
            ))}
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
};
export default TransportPopup;
