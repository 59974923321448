import { useEffect } from 'react';

type ScreensaverImageProps = {
  imageUrl: string;
  isActive: boolean;
  goNextSlide: () => void;
};

const ScreensaverImage = ({ imageUrl, isActive, goNextSlide }: ScreensaverImageProps) => {
  useEffect(() => {
    if (isActive) {
      goNextSlide();
    }
  }, [isActive]);

  return <img src={imageUrl} alt='screen' />;
};

export default ScreensaverImage;
