import styles from './AlertPopup.module.scss';

type Props = {
  message: string;
  closePopup: () => void;
};

const AlertPopup = ({ message, closePopup }: Props) => {
  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <div className={styles.text}>{message}</div>
        <button onClick={closePopup} className={styles.close_btn} type='button'>
          확인
        </button>
      </div>
    </div>
  );
};
export default AlertPopup;
