import SearchField from 'components/tenant/header/search-field/SearchField';
import useColorSetStore from 'store/colorSet';
import useLanguageStore from 'store/languages';
import useTenantStore from 'store/tenant';
import styles from './LocationSearchField.module.scss';

const LocationSearchField = () => {
  const tenantStore = useTenantStore();
  const langStore = useLanguageStore();
  const colorSetStore = useColorSetStore();

  const handleTenantsByKeyword = (searchWord: string) => {
    if (!tenantStore.rawTenants) return;

    // 매장 검색 뿐만 아니라 모든 테넌트들을 검색할 수 있도록 세팅
    tenantStore.setFilteredTenants(tenantStore.rawTenants, langStore.currentLang, searchWord);
  };

  return (
    <div
      className={styles.field_wrapper}
      style={{
        background: colorSetStore.header.bg,
      }}
    >
      <SearchField handleTenantsByKeyword={handleTenantsByKeyword} />
    </div>
  );
};
export default LocationSearchField;
