import InitialLoadingPage from 'components/common/loader/page/InitialLoadingPage';
import AlertPopup from 'components/popup/alert/AlertPopup';
import SharePopup from 'components/popup/share/SharePopup';
import CommonToastPopup from 'components/popup/toast/CommonToastPopup';
import { LOCAL_STORAGE_MOTION_PERMISSION_KEY } from 'data/localStorageKey';
import useFetchApis from 'hooks/api/fetch/useFetchApis';
import useStats from 'hooks/api/stats/useStats';
import useUrl from 'hooks/params/useUrl';
import AllViewPage from 'pages/AllViewPage';
import BookmarkPage from 'pages/BookmarkPage';
import CustomBoardPage from 'pages/CustomBoardPage';
import ErrorPage from 'pages/ErrorPage';
import FloorPage from 'pages/FloorPage';
import MainPage from 'pages/MainPage';
import TenantPage from 'pages/TenantPage';
import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import useCommonStore from 'store/common';
import useLoadingStore from 'store/loading';
import usePopupStore from 'store/popup';
import useShareModalStore from 'store/shareModal';
import useTimerStore from 'store/timer';
import { PathType } from 'types/common/path.type';
import { extractParams } from 'util/params/join/extract/extractParams';
import { handleViewHeight } from 'util/vh/handleViewHeight';
import { v4 as uuidv4 } from 'uuid';
import Layout from './components/layout/Layout';

// Kakao 타입 전역으로 설정
declare global {
  interface Window {
    Kakao: any;
  }
}

function App() {
  // store
  const loadingStore = useLoadingStore();
  const popupStore = usePopupStore();
  const timerStore = useTimerStore();
  const shareModalStore = useShareModalStore();
  const commonStore = useCommonStore();

  // hook
  const { postUsages } = useStats();
  const location = useLocation();

  // var
  const pointIdParam: string | undefined = extractParams(location.search, 'pointId');
  const machineIdParam: string | undefined = extractParams(location.search, 'machineId');

  // url 에서 mobile 정보 set
  useUrl();
  // map sdk script load
  // useScript();
  // backend api fetch start
  useFetchApis();

  /**
   * 고유한 uuid를 생성한다.
   */
  const generateUuid = () => {
    const uuid = uuidv4();
    commonStore.setAppUuid(uuid.replace(/-/g, '').toUpperCase());
  };

  /**
   * 기기 사용량을 수집한다.
   */
  const postUsageStats = async () => {
    if (!pointIdParam) {
      return;
    }

    if (!commonStore.appUuid) {
      return;
    }

    // 기기 사용량 수집
    await postUsages(pointIdParam, machineIdParam);
  };

  useEffect(() => {
    generateUuid();
  }, []);

  useEffect(() => {
    postUsageStats();
  }, [pointIdParam, commonStore.appUuid]);

  // ! pointId 없이 들어왔을 경우 alert 및 다비오 홈페이지로 이동
  const redirectPage = () => {
    window.location.href = 'https://www.dabeeo.com/';
    popupStore.setOpenErrorPopup(false);
  };

  // 카카오 SDK 초기화
  const initKakaoSdk = () => {
    window.Kakao.init(process.env.REACT_APP_KAKAO_JS_KEY);
  };

  useEffect(() => {
    initKakaoSdk();
  }, []);

  useEffect(() => {
    handleViewHeight();
  }, []);

  useEffect(() => {
    /**
     * 처음 진입 시 그냥 무조건 상태값 초기화한다
     */
    localStorage.removeItem(LOCAL_STORAGE_MOTION_PERMISSION_KEY);
  }, []);

  return (
    <>
      {loadingStore.isInitialLoading ? (
        <InitialLoadingPage />
      ) : (
        <Layout>
          <Routes>
            <Route path={PathType.root} element={<MainPage />} />
            <Route path={PathType.main} element={<MainPage />} />
            <Route path={PathType.floor} element={<FloorPage />} />
            <Route path={PathType.tenant} element={<TenantPage />} />
            <Route path={PathType.error} element={<ErrorPage />} />
            <Route path={PathType.customBoard} element={<CustomBoardPage />} />
            <Route path={PathType.bookmark} element={<BookmarkPage />} />
            <Route path={PathType.more} element={<AllViewPage />} />
          </Routes>
        </Layout>
      )}
      {popupStore.openErrorPopup && <AlertPopup closePopup={redirectPage} message='올바르지 않은 접근입니다.' />}

      {/* toast 팝업 */}
      {popupStore.openToastPopup && (
        <CommonToastPopup
          closePopupAfterSeconds={() => {
            timerStore.closeToastPopupAfterSeconds(() => {
              popupStore.setOpenToastPopup(false);
              timerStore.clearToastPopupTimer();
            });
          }}
          text={popupStore.toastMessage}
          isOpen={popupStore.openToastPopup}
        />
      )}

      {/* 공유하기 팝업 */}
      {
        // ! 시연용 워크스페이스 분기처리
        shareModalStore.openSharePopup && (
          <SharePopup
            onClosePopup={() => {
              shareModalStore.setOpenSharePopup(false);
            }}
          />
        )
      }
    </>
  );
}

export default App;
