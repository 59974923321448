import { ICONS } from 'constants/icons';
import { LOCAL_STORAGE_PARKING_LOCATION_KEY } from 'data/localStorageKey';
import { t } from 'i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import useLanguageStore from 'store/languages';
import useModalStore from 'store/modal';
import { PathType } from 'types/common/path.type';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import { extractParams } from 'util/params/join/extract/extractParams';
import styles from './MenuMoreInfo.module.scss';

const MyParkingLocation = () => {
  // store
  const langStore = useLanguageStore();
  const modalStore = useModalStore();

  // hook
  const navigate = useNavigate();
  const location = useLocation();

  // var
  const storageParkingLocation = localStorage.getItem(LOCAL_STORAGE_PARKING_LOCATION_KEY);
  const myParkingLocation: TenantWithPointId = storageParkingLocation ? JSON.parse(storageParkingLocation || '') : '';
  const pointId: string | undefined = extractParams(location.search, 'pointId');
  const machineId: string | undefined = extractParams(location.search, 'machineId');

  /**
   * 내 주차위치 클릭하면 지도로 가서 팝업을 보여준다
   */
  const onClick = () => {
    modalStore.setOpenMyParkingModal(true);

    navigate(
      `${PathType.floor}?type=parkingDetail&pointId=${pointId}&machineId=${machineId}&destTenantId=${myParkingLocation.id}`,
    );
  };

  return (
    <div onClick={onClick} className={styles.wrapper}>
      <div className={styles.title}>{t('myParkingLocation')}</div>

      <div className={styles.info_wrapper}>
        <div className={styles.content}>{myParkingLocation?.name[langStore.currentLang]}</div>

        <div>
          <img src={ICONS.ARROW_RIGHT} alt='더보기 아이콘' />
        </div>
      </div>
    </div>
  );
};
export default MyParkingLocation;
