import MapDraw from 'map/MapDraw';
import { offTracking, stopIndoor } from '../tracking';

/**
 * 모든 마커를 제거한다
 */
export function clearAllMarkers() {
  if (MapDraw.map) {
    MapDraw.map.markers.clearAll();
  }
}

/**
 * 길찾기 내비게이션을 제거한다
 */
export function clearNavigation() {
  if (MapDraw.map) {
    MapDraw.map.routeSimulation.stop();
    MapDraw.map.routeSimulation.clear();
  }
}

/**
 * 내위치 gps를 제거한다
 */
export function clearMyLocation() {
  if (MapDraw.map) {
    MapDraw.map.mylocation.clear();
  }
}

/**
 * indoor tracking 서비스를 종료한다
 */
export async function clearIndoorTracking() {
  clearMyLocation();

  await offTracking();
  await stopIndoor();
}

/**
 * 지도 clear 한다
 *
 * 1. 마커 제거
 * 2. 길찾기 경로 제거
 */
export async function clearMap() {
  clearAllMarkers();
  clearNavigation();
}
