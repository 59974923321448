import { useState } from 'react';
import usePopupStore from 'store/popup';
import { PopupType } from 'types/common/popup.type';

const useModal = () => {
  const popStore = usePopupStore();
  // const [layeredModal, setLayeredModal] = useState<PopupType[]>([]);
  const [visibleModal, setVisibleModal] = useState(false);

  let timer: NodeJS.Timer;

  const openModal = (currentModalType: PopupType) => {
    setVisibleModal(true);
    // setLayeredModal([...layeredModal, currentModalType]);
    popStore.setCurrentPopupType(currentModalType);
  };

  const closeModal = () => {
    setVisibleModal(false);
    // timer = setTimeout(() => {
    //   const backOneLayerModal = [...layeredModal].slice(0, layeredModal.length - 1);
    //   setLayeredModal(backOneLayerModal);
    //   clearTimeout(timer);
    // }, 500);
  };

  const resetModal = () => {
    setVisibleModal(false);
    // setLayeredModal([]);
  };

  return {
    openModal,
    closeModal,
    resetModal,
    // layeredModal,
    visibleModal,
  };
};

export default useModal;
