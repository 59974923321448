import { MOBILE_SHARE_DEFAULT } from 'data/defaultImages';
import { LOCAL_STORAGE_BOOKMARK_KEY, LOCAL_STORAGE_ORIGIN_LOCATION_KEY } from 'data/localStorageKey';
import useMapRoute from 'hooks/map/event/useMapRoute';
import { t } from 'i18next';
import { addBuilding } from 'map/control/building';
import { changeFloor } from 'map/control/floor';
import { PositionNum } from 'map/type/position.type';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useBookmarkStore from 'store/bookmark';
import useCategoryGroupStore from 'store/categoryGroup';
import useFloorStore from 'store/floor';
import useLanguageStore from 'store/languages';
import useModalStore from 'store/modal';
import usePointStore from 'store/point';
import usePopupStore from 'store/popup';
import useShareModalStore from 'store/shareModal';
import useTenantStore from 'store/tenant';
import useTimerStore from 'store/timer';
import { CategoryFeature, CategoryGroupWithTenants } from 'types/category/category.type';
import { Floor } from 'types/floor/floor.type';
import { StorageLocation } from 'types/origin/originLocation.type';
import { Point } from 'types/point/point.type';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import { errorConsole } from 'util/common/console';
import { changeMapDrawValue } from 'util/map-draw/changeMapDrawValue';
import { extractParams } from 'util/params/join/extract/extractParams';
import { returnObjectPosition } from 'util/position/returnCurrentPosition';

const usePoc = () => {
  // store
  const { currentTenant: tenant } = useTenantStore();
  const floorStore = useFloorStore();
  const shareModalStore = useShareModalStore();
  const bookmarkStore = useBookmarkStore();
  const popupStore = usePopupStore();
  const modalStore = useModalStore();
  const categoryStore = useCategoryGroupStore();
  const pointStore = usePointStore();
  const langStore = useLanguageStore();
  const timerStore = useTimerStore();

  const { changeOriginLocation } = useMapRoute();

  // variable
  const storageBookmarkList = localStorage.getItem(LOCAL_STORAGE_BOOKMARK_KEY);

  // state
  const [isActiveBookmark, setIsActiveBookmark] = useState<boolean>(false);

  // hook
  const location = useLocation();

  /**
   * local storage에 저장되어 있는 북마크 리스트와 현재 선택된 tenant의 매칭 여부에 따라 즐겨찾기 버튼 active 상태 전환
   * @function isTenantBookmarkStatusByStorage
   * @returns {void}
   */
  const isTenantBookmarkStatusByStorage = useCallback((): void => {
    if (!tenant) return;
    const bookmarks = JSON.parse(storageBookmarkList || '[]');
    const isBookmarked = bookmarks.some((item: TenantWithPointId) => item.id === tenant.id);
    setIsActiveBookmark(isBookmarked);
  }, [storageBookmarkList, tenant]);

  // 층 목록 변경
  const changeFloors = (pointId: string) => {
    const findFloors: Floor[] | undefined = floorStore.pointFloorsMap?.get(pointId);

    if (findFloors) {
      floorStore.setFloors(findFloors);
    }
  };

  // 선택된 point 변경
  const changePoint = (pointId: string) => {
    const findPoint: Point | undefined = pointStore.pointsMap?.get(pointId);

    if (findPoint) {
      pointStore.setSelectedPoint(findPoint);
    }
  };

  // 카테고리 리스트 변경
  const changeCategories = (pointId: string) => {
    if (categoryStore.mapFacilityCategoriesMap) {
      const findCategories: CategoryGroupWithTenants | undefined = categoryStore.mapFacilityCategoriesMap.get(pointId);

      if (findCategories) {
        categoryStore.setCategoryList(CategoryFeature.mapFacility, findCategories);
      }
    }
  };

  /**
   * 즐겨찾기 추가 또는 해제 시 toast 팝업 show/hide (3초 후 닫기 실행)
   * @function handleBookmarkButtonClick
   * @returns {void}
   */
  const closeToastAfterSeconds = (message: string): void => {
    popupStore.setOpenToastPopup(true);
    popupStore.setToastPopupMessage(message);
    timerStore.closeToastPopupAfterSeconds(() => popupStore.setOpenToastPopup(false));
  };

  /**
   * 현재위치저장 버튼 클릭 이벤트 핸들러
   * @function handleBookmarkButtonClick
   * @returns {Promise<void>}
   */
  const handleCurrentLocationSaveButtonClick = async (): Promise<void> => {
    // 현재 tenant
    if (tenant) {
      modalStore.setOpenTenantListModal(false);

      await changeOriginLocation(tenant.id);

      const currentPosition: PositionNum | undefined = returnObjectPosition(tenant.content.objectId);
      const currentFloorId: string = tenant.content.floorId;

      if (!currentPosition) {
        return;
      }

      const originLocation: StorageLocation = {
        id: tenant.id,
        name: tenant.name,
        position: currentPosition,
        floorId: currentFloorId,
        objectId: tenant.content.objectId,
        pointId: tenant.pointId,
        buildingId: tenant.content.buildingId,
      };

      // 로컬스토리지에 선택한 테넌트 정보 일부 저장
      localStorage.setItem(LOCAL_STORAGE_ORIGIN_LOCATION_KEY, JSON.stringify(originLocation));

      // 현재 위치 변경
      changeMapDrawValue(originLocation);

      /**
       * 지도의 빌딩 이동 및 층 이동
       *
       * 같은 빌딩이라면 changeFloor 해야 하고 다른 빌딩이라면 addBuilding 해야함.
       * 이유 : 2024 0404 기준 addBuilding 시 이미 context 에 같은 빌딩 있으면 api 내부적으로 return 하고 있음.
       * 추후 changeFloor 메서드로 다른 빌딩까지 이동할 수 있도록 api 업데이트 예정이라고 들음.
       */
      if (pointStore.selectedPoint?.id === originLocation.pointId) {
        await changeFloor(originLocation.floorId);
      } else {
        await addBuilding(originLocation.buildingId, originLocation.floorId);
      }
      // 층
      floorStore.setCurrentFloorId(currentFloorId);
      // 층 목록
      changeFloors(originLocation.pointId);
      // 포인트
      changePoint(originLocation.pointId);
      // 카테고리
      changeCategories(originLocation.pointId);

      closeToastAfterSeconds(`${t(`popup.locationChange`)}`);
    }
  };

  /**
   * 공유하기 버튼 클릭 이벤트 핸들러
   * @function handleBookmarkButtonClick
   * @returns {void}
   */
  const handleShareButtonClick = (): void => {
    if (!tenant) {
      return errorConsole('tenant가 없습니다');
    }

    // 공유하기 팝업 열기
    shareModalStore.setOpenSharePopup(true);

    const pointId: string | undefined = extractParams(location.search, 'pointId');
    const machineId: string | undefined = extractParams(location.search, 'machineId');
    const url = `${process.env.REACT_APP_URL}/?type=location&pointId=${pointId}&machineId=${machineId}&destTenantId=${tenant.id}`;
    const boothName = tenant.name[langStore.currentLang];

    // 공유할 URL 설정
    shareModalStore.setShareUrl(url);

    // 카카오 공유 템플릿 생성 시 필요한 정보 저장
    shareModalStore.setKakaShare({
      title: boothName,
      description: `WIS 2024 ${boothName} 부스에 초대합니다.`,
      imageUrl: tenant.subLogoUri || MOBILE_SHARE_DEFAULT,
      buttonText: '부스 정보 보기',
    });

    // 이미지 다운로드 시 파일명 지정
    shareModalStore.setQrFileTitle('부스정보');
  };

  /**
   * 즐겨찾기 버튼 클릭 이벤트 핸들러
   * @function handleBookmarkButtonClick
   * @returns {void}
   */
  const handleBookmarkButtonClick = (): void => {
    if (!tenant) return;

    const bookmarks = JSON.parse(storageBookmarkList || '[]');
    const index = bookmarks.findIndex((item: TenantWithPointId) => item.id === tenant.id);

    if (isActiveBookmark) {
      if (index > -1) {
        bookmarks.splice(index, 1); // Remove the tenant from bookmarks
        setIsActiveBookmark(false);
        closeToastAfterSeconds(`${t(`bookmark.clearMessage`)}`);
      }
    } else if (index === -1) {
      bookmarks.push(tenant); // Add the tenant to bookmarks
      setIsActiveBookmark(true);
      closeToastAfterSeconds(`${t(`bookmark.saveMessage`)}`);
    }
    localStorage.setItem(LOCAL_STORAGE_BOOKMARK_KEY, JSON.stringify(bookmarks));
    // 북마크 목록 새로고침
    bookmarkStore.setBookmarkRefresh(true);
  };

  /**
   * useEffect, isTenantBookmarkStatusByStorage 함수를 호출하여 초기 bookmark active 상태 정의
   */
  useEffect(() => {
    isTenantBookmarkStatusByStorage();
  }, [isTenantBookmarkStatusByStorage]);

  return { handleCurrentLocationSaveButtonClick, handleShareButtonClick, handleBookmarkButtonClick, isActiveBookmark };
};
export default usePoc;
