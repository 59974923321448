import MapDraw from 'map/MapDraw';
import { DataObject } from 'map/type/dataObject.type';
import { validMapData } from './common/valid';

/**
 * object 검색
 * @param floorId
 * @param objectId
 * @return 찾은 object, object 배열. 존재하지 않는 object일 경우 undefined 반환.
 */
export const findObject = (objectId: string, floorId?: string): DataObject | DataObject[] | undefined => {
  validMapData();

  return MapDraw.mapData?.dataObject.find({ id: objectId, floorId });
};
