import useColorSetStore from 'store/colorSet';
import useLanguageStore from 'store/languages';
import { MultiLang } from 'types/language/language.type';
import styles from './CategoryTag.module.scss';

type Props = {
  name: MultiLang;
};

const CategoryTag = ({ name }: Props) => {
  const langStore = useLanguageStore();
  const colorSetStore = useColorSetStore();

  return (
    <div className={styles.tag} style={{ color: colorSetStore.text.category }}>
      <div className={styles.name}>{name[langStore.currentLang]}</div>
    </div>
  );
};
export default CategoryTag;
