import { Axios } from 'axios';
import useMachine from 'hooks/api/machine/useMachine';
import useStats from 'hooks/api/stats/useStats';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useLoadingStore from 'store/loading';
import usePopupStore from 'store/popup';
import { PathType } from 'types/common/path.type';
import { PathQueryParam, TypeQueryParam } from 'types/common/queryParam.type';
import { errorConsole } from 'util/common/console';
import { extractParams } from 'util/params/join/extract/extractParams';

const useUrl = () => {
  // store
  const popupStore = usePopupStore();
  const loadingStore = useLoadingStore();

  // hook
  const location = useLocation();
  const navigate = useNavigate();
  const { getPointIdByMachineId } = useMachine();
  // 통계
  const { postTenantUsage } = useStats();

  // var
  const pointIdParam: string | undefined = extractParams(location.search, 'pointId');
  const machineIdParam: string | undefined = extractParams(location.search, 'machineId');
  const typeParam: TypeQueryParam | undefined = extractParams(location.search, 'type');
  const destTenantIdParam: string | undefined = extractParams(location.search, 'destTenantId');

  // TODO: 기존 URL 정리 후 제거
  const pathParam: PathQueryParam | undefined = extractParams(location.search, 'path');
  const tenantIdParam: string | undefined = extractParams(location.search, 'tenantId');

  const redirectWithPointId = async () => {
    if (!machineIdParam) {
      return;
    }

    popupStore.setOpenErrorPopup(false);

    try {
      // machineId만 있는 경우 api 호출해서 point를 찾는다
      const findPointId = await getPointIdByMachineId(machineIdParam);

      if (findPointId) {
        // 페이지 이동
        navigate(`/?pointId=${findPointId}&machineId=${machineIdParam}`);
      }
    } catch (error) {
      if (error instanceof Axios) {
        errorConsole('mvp getMachine error!');
      }
    }
  };

  /**
   * 1. pointId도 없고 machineId만 있는 경우
   * 2. pointId가 없을 경우
   * 위의 잘못된 URL로 들어왔을 경우 에러 팝업을 띄운다
   */
  const validUrl = async () => {
    if (!pointIdParam && machineIdParam) {
      await redirectWithPointId();
      return;
    }

    // pointId 가 없을 경우 에러 발생시킨다
    if (!pointIdParam) {
      loadingStore.setInitialLoading(false);
      loadingStore.setLoadingMap(false);
      popupStore.setOpenErrorPopup(true);
      // return;
    }
  };

  /**
   * URL type 이 location, parkingPositioning, parkingNavi, parkingDetail 일 경우 층별안내 페이지로 이동한다
   */
  const moveToFloorPage = () => {
    navigate(`${PathType.floor}${location.search}`);
  };

  /**
   * URL type location 일 경우 매장 사용량을 수집한다
   */
  const postStats = async () => {
    if (!pointIdParam || !destTenantIdParam) {
      return;
    }

    if (typeParam === 'location') {
      await postTenantUsage(pointIdParam, destTenantIdParam, 'LOCATION', machineIdParam);
    }
  };

  /**
   * 기존 로직 유지
   * TODO: 기존 URL 정리 후 제거
   */
  const handleRouterByPath = async () => {
    if (!pathParam || !pointIdParam || !tenantIdParam) {
      return;
    }

    const searchParams = location.search;

    // 테넌트 QR
    if (pathParam === 'tenant') {
      // 매장 사용량 수집
      await postTenantUsage(pointIdParam, tenantIdParam, 'LOCATION', machineIdParam);

      navigate(`${PathType.floor}${searchParams}`);
      return;
    }

    // 주차위치 저장, 주차위치 길찾기, 내 주차위치 확인 QR
    if (pathParam === 'parking' || pathParam === 'parkingNavi' || pathParam === 'myParking') {
      navigate(`${PathType.floor}${searchParams}`);
    }
  };

  useEffect(() => {
    validUrl();
  }, [pointIdParam, machineIdParam]);

  useEffect(() => {
    // 층별안내 페이지로 라우팅
    if (typeParam) {
      moveToFloorPage();
    }
  }, [typeParam]);

  useEffect(() => {
    // 매장 사용량 수집
    postStats();
  }, [typeParam, pointIdParam, destTenantIdParam]);

  // TODO: 기존 URL 정리 후 제거
  useEffect(() => {
    handleRouterByPath();
  }, [pathParam, pointIdParam, tenantIdParam]);
};
export default useUrl;
