import { useAxios } from 'api/axios';
import { EndPoint } from 'data/endpoint';
import useCategoryGroupStore from 'store/categoryGroup';
import useTenantStore from 'store/tenant';
import { ApiResponse } from 'types/api/response';
import { CategoryFeature, CategoryGroupWithTenants } from 'types/category/category.type';
import { LangCode } from 'types/language/language.type';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import { convertCategoryListToMap } from 'util/category/convert/list-to-map/convertCategoryListToMap';
import { errorConsole } from 'util/common/console';
import useTenant from '../tenant/useTenant';

const useCategoryGroup = () => {
  const categoryGroupStore = useCategoryGroupStore();
  const tenantStore = useTenantStore();

  const { getRawTenants } = useTenant();
  const { api } = useAxios();

  // 카테고리 목록 조회
  const getCategoryGroups = async (workspaceId: string, feature?: CategoryFeature) => {
    try {
      const response = await api.get<ApiResponse<CategoryGroupWithTenants[]>>(EndPoint.tenantCategoryGroups, {
        params: {
          feature,
          workspaceId,
        },
      });

      if (response.data) {
        return response.data;
      }
    } catch {
      errorConsole('CATEGORY_GROUPS 를 불러올 수 없습니다.');
    }
  };

  /**
   * 테넌트를 해당 카테고리에 맞게 넣어주는 함수
   * ! 모바일은 처음 테넌트 sort 할 때 브라우저 언어로 init 해줘야 하기 때문에 initLang 을 따로 받아야 함
   */
  const getCategoryGroupsWithTenant = async (
    mainLang: LangCode,
    initLang: LangCode,
    workspaceId: string,
    pointId: string,
  ) => {
    const allTenants: TenantWithPointId[] | undefined = await getRawTenants(mainLang, workspaceId);

    const tenantSearchCategories: CategoryGroupWithTenants[] | undefined = await getCategoryGroups(
      workspaceId,
      CategoryFeature.tenantSearch,
    );
    const mapFacilityCategories: CategoryGroupWithTenants[] | undefined = await getCategoryGroups(
      workspaceId,
      CategoryFeature.mapFacility,
    );

    // category List를 Map으로 변환 (pointId 별로 다 때려넣음)
    const tenantSearchGroupsMap: Map<string, CategoryGroupWithTenants> = convertCategoryListToMap(
      tenantSearchCategories,
      mainLang,
    );
    const mapFacilityGroupsMap: Map<string, CategoryGroupWithTenants> = convertCategoryListToMap(
      mapFacilityCategories,
      mainLang,
    );

    /**
     * tenant list를 loop 돌면서 tenant 가 포함된 category Map에 해당 tenant 를 넣어준다.
     * 하나의 tenant 는 여러 category group 에 포함될 수 있다.
     */
    allTenants?.forEach(tenant => {
      // 매장검색용 전체 tenants
      const findSearchGroup: CategoryGroupWithTenants | undefined = tenantSearchGroupsMap.get(tenant.pointId);

      if (findSearchGroup) {
        tenant.categoryGroups.forEach(category => {
          tenant.categoryGroups.forEach(tenantCategory => {
            if (category.id === tenantCategory.id) {
              findSearchGroup.tenants.push(tenant);
            }
          });
        });
      }

      // 층별안내용 전체 tenants
      const findFacilityGroup: CategoryGroupWithTenants | undefined = mapFacilityGroupsMap.get(tenant.pointId);

      if (findFacilityGroup) {
        findFacilityGroup.categoryGroups.forEach(category => {
          tenant.categoryGroups.forEach(tenantCategory => {
            if (category.id === tenantCategory.id) {
              findFacilityGroup.tenants.push(tenant);
            }
          });
        });
      }
    });

    // ! 전체 point 의 category map
    categoryGroupStore.setCategoriesMap(CategoryFeature.tenantSearch, tenantSearchGroupsMap);
    categoryGroupStore.setCategoriesMap(CategoryFeature.mapFacility, mapFacilityGroupsMap);

    // 모든 points 의 매장 검색용 tenants
    const searchTenants: TenantWithPointId[] = [];

    // 모든 points 의 층별안내 편의시설용 tenants
    const mapFacilityTenants: TenantWithPointId[] = [];

    allTenants?.forEach(tenant => {
      tenant.categoryGroups.forEach(category => {
        if (category.feature === CategoryFeature.tenantSearch) {
          searchTenants.push(tenant);
        }

        if (category.feature === CategoryFeature.mapFacility) {
          mapFacilityTenants.push(tenant);
        }
      });
    });

    tenantStore.setSearchTenants(searchTenants, initLang);
    tenantStore.setMapFacilityTenants(mapFacilityTenants);

    // ! 내 point 의 category tenant list
    const findTenantSearchTenants: CategoryGroupWithTenants | undefined = tenantSearchGroupsMap.get(pointId);
    const findMapFacilityTenants: CategoryGroupWithTenants | undefined = mapFacilityGroupsMap.get(pointId);

    // 내 point 의 매장검색용 tenant list
    if (findTenantSearchTenants) {
      categoryGroupStore.setCategoryList(CategoryFeature.tenantSearch, findTenantSearchTenants);
    }

    // 내 point 의 층별안내 카테고리용 tenant list
    if (findMapFacilityTenants) {
      categoryGroupStore.setCategoryList(CategoryFeature.mapFacility, findMapFacilityTenants);
    }
  };

  return { getCategoryGroups, getCategoryGroupsWithTenant };
};
export default useCategoryGroup;
