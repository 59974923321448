import React, { useEffect, useRef } from 'react';
import { errorConsole } from 'util/common/console';

type VideoProps = {
  videoUrl: string;
  isActive: boolean;
  goNextSlide: () => void;
};

const ScreensaverVideo = ({ videoUrl, isActive, goNextSlide }: VideoProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const userAgents = window.navigator.userAgent;
  const isIphone = userAgents.includes('iPhone');
  const isIPad = userAgents.includes('iPad');
  const isIOS = isIphone || isIPad;

  const playVideo = async () => {
    if (!videoRef.current) return;

    await videoRef.current.play();
  };

  const pauseVideo = () => {
    if (!videoRef.current) return;

    videoRef.current.pause();
    videoRef.current.currentTime = 0;
  };

  const handleVideoPlaying = async () => {
    try {
      if (!videoRef.current) return;

      if (isActive) {
        await playVideo();
      } else {
        pauseVideo();
      }
    } catch (e) {
      pauseVideo();

      errorConsole(`SCREENSAVER WARNING! ${e}`);
    }
  };

  useEffect(() => {
    handleVideoPlaying();
  }, [isActive]);
  return isIOS ? (
    <img src={videoUrl} alt='video' />
  ) : (
    <video muted ref={videoRef} onEnded={goNextSlide} playsInline>
      <source src={videoUrl} />
    </video>
  );
};

export default ScreensaverVideo;
