/* eslint-disable no-nested-ternary */
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useBuildingStore from 'store/building';
import useFloorStore from 'store/floor';
import useLanguageStore from 'store/languages';
import usePointStore from 'store/point';
import styles from './FloorChangedPopup.module.scss';

const FloorChangedPopup = () => {
  const langStore = useLanguageStore();
  const floorStore = useFloorStore();
  const buildingStore = useBuildingStore();
  const pointStore = usePointStore();

  const { t } = useTranslation();

  const floor = floorStore.floors.find(item => item.id === floorStore.currentFloorId)?.name[langStore.currentLang];

  useEffect(() => {
    return () => {
      buildingStore.resetBuildingName();
    };
  }, []);

  return (
    <div className={styles.container}>
      {/* 이동수단 아이콘 잠시 제외 (2024 0404) */}
      {/* <div className={styles.icon}>
        <img src={`assets/icon/transport/${transport}.svg`} alt={transport} />
      </div> */}

      {buildingStore.buildingType === 'OUTDOOR' ? (
        // OUTDOOR 일 경우 층 이름 제외한 팝업 노출
        <span>{t(`popup.outdoorFloorChange`, { building: buildingStore.buildingName[langStore.currentLang] })}</span>
      ) : // 빌딩이 1개일 경우 빌딩명 비노출
      pointStore.points.length < 2 ? (
        <span>{t(`popup.singlePointFloorChange`, { floor })}</span>
      ) : (
        <span>{t(`popup.floorChange`, { building: buildingStore.buildingName[langStore.currentLang], floor })}</span>
      )}
    </div>
  );
};
export default FloorChangedPopup;
