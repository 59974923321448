/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/destructuring-assignment */
import { ICONS } from 'constants/icons';
import { MOBILE_PARKING_DEFAULT } from 'data/defaultImages';
import { LOCAL_STORAGE_PARKING_LOCATION_KEY } from 'data/localStorageKey';
import { t } from 'i18next';
import { useLocation } from 'react-router-dom';
import useShareModalStore from 'store/shareModal';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import { extractParams } from 'util/params/join/extract/extractParams';
import styles from './ShareMyParkingLocationButton.module.scss';

const ShareMyParkingLocationButton = () => {
  // hooks
  const location = useLocation();

  // store
  const shareModalStore = useShareModalStore();

  // var
  const storageParkingLocation = localStorage.getItem(LOCAL_STORAGE_PARKING_LOCATION_KEY);
  const myParkingLocation: TenantWithPointId = storageParkingLocation ? JSON.parse(storageParkingLocation || '') : '';

  // 공유하기
  const onClickShare = () => {
    // 공유 팝업 열기
    shareModalStore.setOpenSharePopup(true);

    const pointId: string | undefined = extractParams(location.search, 'pointId');
    const machineId: string | undefined = extractParams(location.search, 'machineId');
    const url = `${process.env.REACT_APP_URL}/?type=parkingDetail&pointId=${pointId}&machineId=${machineId}&destTenantId=${myParkingLocation.id}`;

    // 공유할 URL 설정
    shareModalStore.setShareUrl(url);

    // 카카오 공유 템플릿 생성 시 필요한 정보 저장
    shareModalStore.setKakaShare({
      title: '내 주차위치',
      description: `내 주차위치를 확인하세요.`,
      imageUrl: MOBILE_PARKING_DEFAULT,
      buttonText: '내 주차위치 보기',
    });

    // 이미지 다운로드 시 파일명 지정
    shareModalStore.setQrFileTitle('주차위치');
  };

  return (
    <button className={styles.btn} onClick={onClickShare} type='button'>
      <img src={ICONS.SHARE} alt='공유' />

      <div className={styles.button_text}>{t('button.share')}</div>
    </button>
  );
};
export default ShareMyParkingLocationButton;
