/* eslint-disable @typescript-eslint/no-unused-vars */
import InfoTagGroup from 'components/common/info/group/tag/InfoTagGroup';
import { ICONS } from 'constants/icons';
import { COLORS } from 'data/color';
import { DEFAULT_IMAGE } from 'data/defaultImages';
import React from 'react';
import useColorSetStore from 'store/colorSet';
import useLanguageStore from 'store/languages';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import styles from './BookmarkItem.module.scss';

type BookmarkItemProps = {
  tenant: TenantWithPointId;
  type: 'location' | 'search';
  clickedTenant?: TenantWithPointId | null;
  handleClickItem: (tenant: TenantWithPointId) => void;
};

const BookmarkItem = ({ tenant, type, clickedTenant, handleClickItem }: BookmarkItemProps) => {
  // store
  const langStore = useLanguageStore();
  const colorSetStore = useColorSetStore();
  // variable
  const isSelected = type === 'location' && clickedTenant?.id === tenant.id;

  return (
    <div
      className={styles.wrapper}
      style={{
        background: isSelected ? colorSetStore.list.bg : COLORS.WHITE,
      }}
      onClick={handleClickItem.bind(this, tenant)}
    >
      <div className={styles.bookmark_icon_wrapper}>
        <img src={ICONS.BOOKMARK} alt='즐겨찾기 목록 북마크 이미지' />
      </div>

      {/* 이미지 */}
      <div className={styles.thumbnail}>
        <img
          src={`${tenant.mainLogoUri || DEFAULT_IMAGE}`}
          alt='thumbnail'
          onError={e => {
            const el = e.target as HTMLImageElement;
            el.src = DEFAULT_IMAGE;
          }}
        />
      </div>

      <div className={styles.detail_wrapper}>
        {/* 이름 */}
        <div
          className={styles.title}
          style={{
            color: colorSetStore.text.title,
          }}
        >
          {tenant?.name[langStore.currentLang] ?? '-'}
        </div>

        {/* 층, 포인트 이름, 카테고리 */}
        <InfoTagGroup tenant={tenant} type={type} />
      </div>
    </div>
  );
};

export default BookmarkItem;
