/* eslint-disable react/jsx-no-useless-fragment */
import { ICONS } from 'constants/icons';
import { LOCAL_STORAGE_ORIGIN_LOCATION_KEY, LOCAL_STORAGE_PARKING_LOCATION_KEY } from 'data/localStorageKey';
import { t } from 'i18next';
import MapDraw from 'map/MapDraw';
import { MutableRefObject, useLayoutEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import usePopupStore from 'store/popup';
import useTenantStore from 'store/tenant';
import useTimerStore from 'store/timer';
import { PathType } from 'types/common/path.type';
import { StorageLocation } from 'types/origin/originLocation.type';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import { extractParams } from 'util/params/join/extract/extractParams';
import { returnObjectPosition } from 'util/position/returnCurrentPosition';
import { findTenant } from 'util/tenant/findTenant';
import styles from './SetMyParkingLocationButton.module.scss';

type Props = {
  type: 'default' | 'star';
  onClosePopup: () => void;
  parkingPopupCloseTimer: MutableRefObject<NodeJS.Timeout | null>;
};

const SetMyParkingLocationButton = (props: Props) => {
  // hook
  const location = useLocation();
  const navigate = useNavigate();

  // variable
  const storageParkingLocation = localStorage.getItem(LOCAL_STORAGE_PARKING_LOCATION_KEY);
  const myParkingLocation = storageParkingLocation ? JSON.parse(storageParkingLocation || '') : '';
  const pointId: string | undefined = extractParams(location.search, 'pointId');
  const machineId: string | undefined = extractParams(location.search, 'machineId');

  // 출발지
  const originTenantIdParam: string | undefined = extractParams(location.search, 'originTenantId');
  const typeParam: string | undefined = extractParams(location.search, 'type');

  // TODO: 기존 URL 정리 후 제거
  const tenantIdParam: string | undefined = extractParams(location.search, 'tenantId');
  const pathParam: string | undefined = extractParams(location.search, 'path');

  // state
  const [currentParkingLot, setCurrentParkingLot] = useState<TenantWithPointId>();
  const [isSaveParkingLot, setIsSaveParkingLot] = useState(Boolean(myParkingLocation));

  // store
  const popupStore = usePopupStore();
  const timerStore = useTimerStore();
  const tenantStore = useTenantStore();

  // ref
  const parkingToastTimerRef = useRef<NodeJS.Timer | null>(null);

  // 기기 상태값 변경
  const changeMapDrawValue = (originLocation: StorageLocation) => {
    // 기기 위치
    MapDraw.machineCoordinate = {
      x: Number(originLocation.position.x),
      y: Number(originLocation.position.y),
    };

    // 기기 층
    MapDraw.machineFloorId = originLocation.floorId;

    // 포인트
    MapDraw.pointId = originLocation.pointId;

    // 빌딩
    MapDraw.buildingId = originLocation.buildingId;
  };

  // 주차위치를 저장한다.
  const onSaveParkingLocation = () => {
    if (!currentParkingLot) return;

    // toast open
    popupStore.setOpenToastPopup(true);
    popupStore.setToastPopupMessage(`${t(`popup.saveMyParkingLocation`)}`);

    // toast 용 타이머 ref 설정
    timerStore.setToastPopupTimerRef(parkingToastTimerRef);

    setIsSaveParkingLot(true);

    // localStorage 에 내 주차위치 저장
    localStorage.setItem(LOCAL_STORAGE_PARKING_LOCATION_KEY, JSON.stringify(currentParkingLot));

    const myLocation: StorageLocation = {
      id: currentParkingLot.id,
      position: returnObjectPosition(currentParkingLot.content.objectId) || { x: 0, y: 0 },
      name: currentParkingLot.name,
      floorId: currentParkingLot.content.floorId,
      objectId: currentParkingLot.content.objectId,
      pointId: currentParkingLot.pointId,
      buildingId: currentParkingLot.content.buildingId,
    };

    // localStorage 에 현재위치 저장
    localStorage.setItem(LOCAL_STORAGE_ORIGIN_LOCATION_KEY, JSON.stringify(myLocation));

    // 현재 위치 변경
    changeMapDrawValue(myLocation);

    // 팝업 close
    props.onClosePopup();

    props.parkingPopupCloseTimer.current = setTimeout(() => {
      navigate(`${PathType.more}?pointId=${pointId}&machineId=${machineId}`);
    }, 300);
  };

  // 내 주차 위치를 제거한다.
  const onRemoveParkingLocation = () => {
    // localStorage 에서 내 주차위치 제거
    localStorage.removeItem(LOCAL_STORAGE_PARKING_LOCATION_KEY);

    // state 변경
    setIsSaveParkingLot(false);

    // toast open
    popupStore.setOpenToastPopup(true);
    popupStore.setToastPopupMessage(`${t(`내 주차위치가 해제되었습니다.`)}`);

    // toast 용 타이머 ref 설정
    timerStore.setToastPopupTimerRef(parkingToastTimerRef);

    // 팝업 close
    props.onClosePopup();
  };

  // 주차위치가 지정되어있지 않는 경우, tenantId 로 tenant 찾기
  const findCurrentParkingLocation = (tenantId: string) => {
    if (tenantId) {
      const tenant = findTenant(tenantStore.rawTenants, tenantId);

      if (tenant) {
        setCurrentParkingLot(tenant);
      }
    }
  };

  /**
   * 내 주차위치 지정 팝업일 경우
   */
  useLayoutEffect(() => {
    if (typeParam === 'parkingPositioning') {
      if (!originTenantIdParam) {
        return;
      }
      findCurrentParkingLocation(originTenantIdParam);
    }
  }, [tenantStore.rawTenants, originTenantIdParam, typeParam]);

  /**
   * 내 주차위치 지정 팝업일 경우
   */
  // TODO: 기존 URL 정리 후 제거
  useLayoutEffect(() => {
    if (pathParam === 'parking') {
      if (!tenantIdParam) {
        return;
      }
      findCurrentParkingLocation(tenantIdParam);
    }
  }, [tenantStore.rawTenants, tenantIdParam, pathParam]);

  return (
    <>
      {props.type === 'star' ? (
        <button
          className={styles.start_btn}
          onClick={isSaveParkingLot ? onRemoveParkingLocation : onSaveParkingLocation}
          type='button'
        >
          {isSaveParkingLot ? <img src={ICONS.STAR} alt='star' /> : <img src={ICONS.STAR_EMPTY} alt='star' />}
          <div className={styles.button_text}>{t('myParkingLocation')}</div>
        </button>
      ) : (
        <button className={styles.default_btn} onClick={onSaveParkingLocation} type='button'>
          <div className={styles.button_text}>{t('button.myParkingLocation')}</div>
        </button>
      )}
    </>
  );
};
export default SetMyParkingLocationButton;
