import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useColorSetStore from 'store/colorSet';
import useCustomBoardStore from 'store/customBoard';
import useLanguageStore from 'store/languages';
import useMenuStore from 'store/menu';
import './CustomBoardContainer.scss';
import CardCustomBoardContainer from './container/card/CardCustomBoardContainer';
import ListCustomBoardContainer from './container/list/ListCustomBoardContainer';
import CustomBoardHeader from './header/CustomBoardHeader';

const CustomBoardContainer = () => {
  const {
    state: { pageId },
  } = useLocation();

  const { mainMenus } = useMenuStore();
  const { currentLang } = useLanguageStore();
  const customBoardStore = useCustomBoardStore();
  const colorSetStore = useColorSetStore();

  const menuTitle = mainMenus.find(menu => menu.customPageId === pageId)?.name[currentLang] || '-';

  // current page 세팅
  const handleCurrentPage = () => {
    if (!pageId) return;
    const findCustomPage = customBoardStore.customPages?.get(pageId);

    if (findCustomPage) {
      customBoardStore.setCurrentCustomPage(findCustomPage);
    }
  };

  useEffect(() => {
    handleCurrentPage();
  }, [pageId]);

  return (
    <div>
      <CustomBoardHeader title={menuTitle} />

      <div
        style={{
          background: colorSetStore.list.bg,
        }}
      >
        {customBoardStore.currentCustomPageType === 'LIST' ? (
          <ListCustomBoardContainer />
        ) : (
          <CardCustomBoardContainer />
        )}
      </div>
    </div>
  );
};

export default CustomBoardContainer;
