import React from 'react';
import { SvgProps } from '../type/SvgProps';

const SearchSvg = ({ color }: SvgProps) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
      <path
        d='M3.92856 10.8873C2.69923 8.68333 3.48935 5.90007 5.69333 4.67075C7.89732 3.44142 10.6806 4.23153 11.9099 6.43552C12.4955 7.48536 12.6228 8.66664 12.3575 9.7488L13.2297 10.621C13.781 9.12482 13.7003 7.4059 12.8626 5.90411C11.3398 3.17394 7.89209 2.19519 5.16192 3.71801C2.43175 5.24083 1.453 8.68856 2.97582 11.4187C4.49864 14.1489 7.94637 15.1277 10.6765 13.6048C10.8849 13.4886 11.0831 13.3611 11.2708 13.2236L10.488 12.4408C10.3779 12.5155 10.2635 12.586 10.1451 12.6521C7.94114 13.8814 5.15789 13.0913 3.92856 10.8873Z'
        fill={color}
      />
      <rect
        width='4.45609'
        height='1.09092'
        transform='matrix(0.707107 0.707107 0.707107 -0.707107 11.8193 12.2627)'
        fill={color}
      />
    </svg>
  );
};
export default SearchSvg;
