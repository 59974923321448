import { logConsole } from 'util/common/console';
import { getTimeStamp } from 'util/date-time/timeStamp';
import { create } from 'zustand';

type Props = {
  // 앱 초기 로딩, 새로고침 시
  isInitialLoading: boolean;
  setInitialLoading: (isInitialLoading: boolean) => void;

  // 지도 getMapData
  hasMapData: boolean;
  setHasMapData: (hasMapData: boolean) => void;

  // 지도 showMap
  isLoadingMap: boolean;
  setLoadingMap: (isLoadingMap: boolean) => void;

  // 지도 층 변경 로딩
  isFloorChanging: boolean;
  setFloorChanging: (isFloorChanging: boolean) => void;

  // 길안내 액션 로딩
  isActionLoading: boolean;
  setActionLoading: (value: boolean) => void;
};

const useLoadingStore = create<Props>((set, get) => ({
  isInitialLoading: true,
  setInitialLoading(isInitialLoading: boolean) {
    set(() => ({ isInitialLoading }));
  },

  hasMapData: false,
  setHasMapData(hasMapData: boolean) {
    if (hasMapData) {
      logConsole(`> map data 가져오기 end : ${getTimeStamp('mm:ss:SSS')}`);
    }

    set(() => ({ hasMapData }));
  },

  isLoadingMap: true,
  setLoadingMap(isLoadingMap: boolean) {
    set(() => ({ isLoadingMap }));
  },

  isFloorChanging: false,
  setFloorChanging(isFloorChanging: boolean) {
    set(() => ({ isFloorChanging }));
  },

  isActionLoading: false,

  setActionLoading(isActionLoading: boolean) {
    set(() => ({ isActionLoading }));
  },
}));

export default useLoadingStore;
