import useColorSetStore from 'store/colorSet';
import useLanguageStore from 'store/languages';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import { getTenantSearchCategoryName } from 'util/category/name/getCategoryName';
import styles from './CategoryName.module.scss';

type Props = {
  tenant: TenantWithPointId;
};

const CategoryName = ({ tenant }: Props) => {
  const langStore = useLanguageStore();
  const colorSetStore = useColorSetStore();

  return (
    <div className={styles.category} style={{ color: colorSetStore.text.category }}>
      {getTenantSearchCategoryName(tenant, langStore.currentLang)}
    </div>
  );
};
export default CategoryName;
