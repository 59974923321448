export const EndPoint = {
  machines: '/mobile/machines',
  points: '/mobile/points',

  languages: '/mobile/languages',
  floors: '/mobile/floors',
  tenants: '/mobile/tenants',
  tenantCategoryGroups: '/mobile/tenant-category-groups',
  screensavers: '/mobile/v2/screensavers',
  mainMenuV2: '/v2/mobile/main-menus',
  workspace: '/mobile/workspaces/:workspaceId',
  customPage: '/mobile/custom-pages/:customPageId',

  // 통계
  usages: '/mobile/metrics/usages', // 기기 사용량
  usagesV2: '/v2/mobile/metrics/usages', // 기기 사용량 v2 (uuid)
  tenantsUsage: '/mobile/metrics/tenants', // 매장 사용량
  categoryGroupsUsage: '/mobile/metrics/tenant-category-groups', // 카테고리 사용량
  deviceMotionAttached: '/v1/mobile/metrics/usages/attach', // 기기 모션 접근 허용 여부
  deviceMotionAttachedV2: '/v2/mobile/metrics/usages/attach', // 기기 모션 접근 허용 여부 v2 (uuid)
};
