/* eslint-disable react/jsx-no-useless-fragment */
import { useTranslation } from 'react-i18next';
import useColorSetStore from 'store/colorSet';
import usePointStore from 'store/point';
import styles from './BusinessHours.module.scss';

const BusinessHours = () => {
  const pointStore = usePointStore();
  const colorSetStore = useColorSetStore();

  const { t } = useTranslation();

  return (
    <>
      <div
        className={`${styles.time}`}
        style={{
          background: colorSetStore.footer.bg,
        }}
      >
        <div
          className={styles.time_title}
          style={{
            color: colorSetStore.footer.text,
          }}
        >
          {t(`info.businessHours`)}
        </div>
        <div
          className={styles.time_content}
          style={{
            color: colorSetStore.footer.text,
          }}
        >
          {pointStore.point?.hoursUsed ? (
            // 운영시간 사용일 경우
            <>
              {pointStore.point.hoursStart}-{pointStore.point.hoursEnd}
            </>
          ) : (
            // 운영시간 미사용인데 desc 있을 경우 desc 노출
            pointStore.point?.hoursDescription && <>{pointStore.point?.hoursDescription}</>
          )}
        </div>
      </div>
    </>
  );
};
export default BusinessHours;
