import { Screensaver } from 'types/screensaver/screensaver.type';
import { create } from 'zustand';

type InitialStateProps = {
  media: Screensaver[];
  setScreensaver: (media: Screensaver) => void;
  succeededMedia: Screensaver[];
  failedMedia: Screensaver[];
  screensaverStatus: boolean;
  setScreensaverStatus: (value: boolean) => void;
  // setSucceededMedia: (media: any) => void;
  setOneSucceedMedia: (media: Screensaver) => void;
  setSucceededMedia: (media: Screensaver[]) => void;
  setFailedMedia: (failedMedia: any) => void;
  resetFailedMedia: () => void;
  deleteFailedMedia: (url: string) => void;
};

const useScreensaverStore = create<InitialStateProps>((set, get) => ({
  media: [],
  screensaverStatus: false,
  succeededMedia: [],
  failedMedia: [],
  setScreensaverStatus(value) {
    set(() => ({ screensaverStatus: value }));
  },
  setScreensaver(media) {
    set(state => ({ media: [...state.media, media] }));
  },
  setSucceededMedia(succeededMedia) {
    set(() => ({ succeededMedia }));
    // set(state => ({ succeededMedia: [...state.succeededMedia, ...succeededMedia] }));
  },
  setOneSucceedMedia(succeededMedia) {
    set(state => ({ succeededMedia: [...state.succeededMedia, succeededMedia] }));
  },
  setFailedMedia(failedMedia) {
    set(state => ({ failedMedia: [...state.failedMedia, failedMedia] }));
  },
  resetFailedMedia() {
    set(() => ({ failedMedia: [] }));
  },
  deleteFailedMedia(url: string) {
    const deleteArr = get().failedMedia.filter(item => item.fileUri !== url);
    set(() => ({ failedMedia: deleteArr }));
  },
}));

export default useScreensaverStore;
