import Calendar from 'assets/icon/calendar.svg';
import CLOSE from 'assets/icon/menu/close.svg';
import GoUpButton from 'components/common/button/go-up/GoUpButton';
import useGoTopButton from 'hooks/go-to-top/useGoTopButton';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useLanguageStore from 'store/languages';
import { CustomBoardContent } from 'types/custom-board/customboard.type';
import DisplayBadge from '../common/badge/DisplayBadge';
import styles from './CustomBoardPopup.module.scss';

type Props = {
  customBoard: CustomBoardContent;
  openPopup: boolean;
  closePopup: () => void;
};

const CustomBoardPopup = ({ closePopup, customBoard, openPopup }: Props) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();
  const { showGoButton } = useGoTopButton({ listRef });

  const langStore = useLanguageStore();

  return (
    <div className={`${styles.container} ${openPopup ? styles.open : styles.close}`}>
      <div className={styles.header}>
        {/* 배지 */}
        <DisplayBadge displayStatus={customBoard.displayStatus} />

        <div className={styles.title_wrapper}>
          {/* 타이틀 */}
          <div className={styles.title}>{customBoard.title[langStore.currentLang]}</div>

          {/* 날짜 */}
          <div className={styles.date_wrapper}>
            <div className={styles.calendar_icon}>
              <img src={Calendar} alt='calendar' />
            </div>
            <div className={styles.date}>
              {/* 상시, 기간일 경우 처리 */}
              {customBoard.displayType === 'ALWAYS_ON' && t('customBoard.always')}
              {customBoard.displayType === 'DURATION' && `${customBoard.startDate} ~ ${customBoard.endDate}`}
            </div>
          </div>
        </div>

        {/* 닫기 버튼 */}
        <div className={styles.close_btn} onClick={closePopup}>
          <img src={CLOSE} alt='' />
        </div>
      </div>

      <div className={styles.content} ref={listRef}>
        <div ref={scrollRef} />
        <img src={customBoard.contentImageUri} alt='content' />
      </div>

      <GoUpButton scrollRef={scrollRef} isShow={showGoButton} />
    </div>
  );
};

export default CustomBoardPopup;
