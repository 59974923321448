import { LOCAL_STORAGE_ORIGIN_LOCATION_KEY } from 'data/localStorageKey';
import useFloor from 'hooks/api/floor/useFloor';
import usePoint from 'hooks/api/point/usePoint';
import MapDraw from 'map/MapDraw';
import { clearIndoorTracking, clearMap, clearMyLocation } from 'map/control/common/clear';
import { useEffect, useRef } from 'react';
import useMapActionStore from 'store/action';
import useBuildingStore from 'store/building';
import useCategoryGroupStore from 'store/categoryGroup';
import useFloorStore from 'store/floor';
import useLoadingStore from 'store/loading';
import useMachineStore from 'store/machine';
import useNaviStore from 'store/navi';
import usePointStore from 'store/point';
import usePopupStore from 'store/popup';
import useTenantStore from 'store/tenant';
import { CategoryFeature, CategoryGroupWithTenants } from 'types/category/category.type';
import { Floor } from 'types/floor/floor.type';
import { StorageLocation } from 'types/origin/originLocation.type';
import { errorConsole } from 'util/common/console';
import useMapRoute from '../event/useMapRoute';
import useMapFloor from '../floor/useMapFloor';

// 경로안내
const useMapNavigation = () => {
  const mapActionStore = useMapActionStore();
  const tenantStore = useTenantStore();
  const machineStore = useMachineStore();
  const floorStore = useFloorStore();
  const popupStore = usePopupStore();
  const pointStore = usePointStore();
  const categoryStore = useCategoryGroupStore();
  const buildingStore = useBuildingStore();
  const naviStore = useNaviStore();
  const loadingStore = useLoadingStore();

  const { findFloorNameByBuildingId } = useFloor();
  const { changeMapStates } = useMapFloor();
  const { setPointNameByBuildingId } = usePoint();
  const { drawRoute, startRoute } = useMapRoute();

  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  // 3초 후 팝업 닫기
  const closePopupAfterSeconds = () => {
    timerRef.current = setTimeout(() => {
      popupStore.setOpenFloorChangedPopup(false);
    }, 3000);
  };

  // 팝업 타이머 제거
  const clearPopupTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    popupStore.setOpenFloorChangedPopup(false);
  };

  // 층 변경 팝업 열기
  const openFloorChangedPopup = () => {
    popupStore.setOpenFloorChangedPopup(true);
    closePopupAfterSeconds();
  };

  // 층 목록 변경
  const changeFloors = (buildingId: string) => {
    const findFloors: Floor[] | undefined = floorStore.buildingFloorsMap?.get(buildingId);

    if (findFloors) {
      floorStore.setFloors(findFloors);
    }
  };

  // 층 이름 변경
  const changeFloorName = (floorId: string, buildingId: string) => {
    const floorName = findFloorNameByBuildingId(floorId, buildingId);

    if (floorName) {
      floorStore.setFloorName(floorName);
    }
  };

  // 카테고리
  const changeCategory = (pointId: string) => {
    if (categoryStore.mapFacilityCategoriesMap) {
      const findCategories: CategoryGroupWithTenants | undefined = categoryStore.mapFacilityCategoriesMap.get(pointId);

      if (findCategories) {
        categoryStore.setCategoryList(CategoryFeature.mapFacility, findCategories);
      }
    }
  };

  // 포인트
  const changePoint = (buildingId: string) => {
    const findPoint = pointStore.buildingPointsMap?.get(buildingId);

    if (findPoint) {
      pointStore.setSelectedPoint(findPoint);
      changeCategory(findPoint.id);
    }
  };

  // 층 이동 시 관련된 전역 상태값들 변경
  const handleNextGlobalStates = (buildingId: string, floorId: string) => {
    // 빌딩 이름
    setPointNameByBuildingId(buildingId);

    // 층
    floorStore.setCurrentFloorId(floorId);
    // 층 목록
    changeFloors(buildingId);
    // 층 이름
    changeFloorName(floorId, buildingId);

    // 포인트
    changePoint(buildingId);
  };

  // 길찾기 시작
  const startNavi = async () => {
    /**
     * 모의주행 경로를 그린다
     */
    await drawRoute();

    /**
     * 모의주행을 시작한다
     */
    await startRoute();

    MapDraw.mapContainer.addEventListener('floor-changing', (e: any) => {
      const nextFloorId = e.detail.floor.next.id;
      const nextBuildingId = e.detail.floor.next.buildingId;

      const findPoint = pointStore.buildingPointsMap?.get(nextBuildingId);

      if (findPoint) {
        // 빌딩 타입
        buildingStore.setBuildingType(findPoint.buildingType === 'INDOOR' ? 'INDOOR' : 'OUTDOOR');

        // 전역 상태 변경
        handleNextGlobalStates(nextBuildingId, nextFloorId);

        // 팝업 관리
        openFloorChangedPopup();
      }
    });

    MapDraw.mapContainer.addEventListener('navi-complete', async () => {
      clearMyLocation();
      clearPopupTimer();

      mapActionStore.resetMapActions();

      naviStore.setNaviDestIconUrl('/assets/icon/arrive.png');
      naviStore.setNaviDestIconSize({ width: 14, height: 20 });

      naviStore.setNaviType('REPLAY');
    });
  };

  // 길찾기
  const triggerNavigation = async () => {
    try {
      clearMap();
      await clearIndoorTracking();
      // resetCamera();

      if (!machineStore.machine || !tenantStore.currentTenant) {
        return;
      }

      loadingStore.setActionLoading(true);

      // 길찾기 아이콘 옵션 설정
      naviStore.setNaviDestIconUrl('/assets/icon/arrive.png');
      naviStore.setNaviDestIconSize({ width: 14, height: 20 });

      // 길찾기 시 기본 floorId 는 machine floor
      let startFloorId: string = machineStore.machine.floor.id;
      let startBuildingId = machineStore.machine.point.buildingId;

      const storageOriginLocation: string | null = localStorage.getItem(LOCAL_STORAGE_ORIGIN_LOCATION_KEY);

      // 저장된 현재위치 있을 경우 현재위치 floorId 로 변경
      if (storageOriginLocation) {
        const origin: StorageLocation = JSON.parse(storageOriginLocation);

        startFloorId = origin.floorId;
        startBuildingId = origin.buildingId;
      }

      // 길찾기 시작 시 상태값 변경
      changeMapStates(startFloorId, startBuildingId);

      // 길찾기 시작
      await startNavi();

      loadingStore.setActionLoading(false);
    } catch {
      errorConsole(`navigation error!`);
    } finally {
      loadingStore.setActionLoading(false);
    }
  };

  useEffect(() => {
    if (!loadingStore.isLoadingMap) {
      if (mapActionStore.actions.navigation) {
        triggerNavigation();
      }
    }
  }, [mapActionStore.actions.navigation, loadingStore.isLoadingMap]);

  return {
    triggerNavigation,
  };
};
export default useMapNavigation;
