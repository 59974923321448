import BookmarkContainer from 'components/bookmark/BookmarkContainer';
import styles from 'pages/Pages.module.scss';

const BookmarkPage = () => {
  return (
    <div className={`${styles.customMenu} ${styles.open}`}>
      <BookmarkContainer />
    </div>
  );
};
export default BookmarkPage;
